import React, { useEffect, useState } from "react";
import { courGet } from "../../service/courAPI";
import { API_ENDPOINT } from "../literals";

const ClientItem = ({ client, onClick }) => {
    const [logo, setLogo] = useState("");

    useEffect(() => {
        let fileName = client.thumbnail;
        if (fileName) {
            courGet(API_ENDPOINT.getPreSignedGetUrl(fileName))
                .then((res) => {
                    setLogo(res.data);
                }).catch((error) => {
                    console.log(error);
                })
        }
    }, [client]);

    return (
        <div className="client-item" >
            <div className="client-image">
                <img src={logo} alt="client" />

            </div>
            <div className="client-details">
                <div className="client-name">{client.name}</div>
            </div>
            <div className="client-edit" onClick={onClick}>
                {/* <i className="fa fa-pencil" /> */}
            </div>
        </div>
    );
};

export default ClientItem;