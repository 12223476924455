import { useCallback, useContext, useEffect, useState } from "react";
import UserContext from "../../UserContext";
import { Constant } from "../Constant/constant";
import "./analytics.css";
import { Months } from '../Utils/month';
import { Pie } from 'react-chartjs-2';
import "chartjs-plugin-datalabels";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { courGet, courPost } from "../../service/courAPI";
import classnames from 'classnames';
import Select, { components } from "react-select";
import ReactTooltip from 'react-tooltip'
import { Toast, ToastContainer } from "react-bootstrap";
import danger from '../../images/Vector.png';
import AR from "../../images/ar.svg";
import VR from "../../images/vr.svg";
import {
   Chart as ChartJS,
   CategoryScale,
   LinearScale,
   BarElement,
   Title,
   Tooltip,
   Legend,
   ArcElement,
} from 'chart.js';
import { API_ENDPOINT } from "../literals";
import moment from "moment";
import BarChart from "./BarChart";
ChartJS.register(
   CategoryScale,
   LinearScale,
   BarElement,
   Title,
   Tooltip,
   Legend,
   ArcElement,
   ChartDataLabels
);

const pieToolTipStyle = {
   backgroundColor: '#FFFFFF',
   titleColor: '#415464',
   titleFont: { weight: 'bold' },
   bodyColor: '#415464',
   bodyFont: { weight: 'bold' },
   borderRadius: 8,
   borderColor: ['#D3D3D3'],
   caretSize: 8,
   caretPadding: 6,
   cornerRadius: 10,
   padding: 6,
   borderWidth: 2,
   titleSpacing: 2,
}

const pieDataLabels = {
   display: true,
   align: "center",
   font: {
      weight: "bold",
      size: "9"
   },
   color: "#415464",
   labels: {
      padding: { top: 10 },
      title: {
         font: {
            weight: "bold",
            size: "9"
         }
      },
      value: {
         color: "#415464"
      }
   },
}
function AnalyticsDetails() {
   const userContext = useContext(UserContext);
   const [selectedData, setSelectedData] = useState([]);
   const [barLoader, setBarLoader] = useState(false);
   const [activeYear, setActiveYear] = useState((new Date()).getFullYear());
   const [year, setYear] = useState((new Date()).getFullYear());
   const [month, setMonth] = useState(0);
   const [level, setLevel] = useState('');
   const [clickedId, setClickedId] = useState(1);
   const [avgTimeSpend, setAvgTimeSpend] = useState(0);
   const [selectedPlatform, setSelectedPlatform] = useState(Constant.SELECTED.All);
   const [activeCount, setActiveCount] = useState(0);
   const [firstTryFinish, setFirstTryFinish] = useState({ percentage: 0, total: 0 });
   const [revisitScenes, setRevisitScenes] = useState({ percentage: 0, total: 0 });
   const [returnOnceCompleted, setReturnOnceCompleted] = useState({ percentage: 0, total: 0 });
   const [totalLearnerCount, setTotalLearnerCount] = useState(0);
   const [experienceList, setExperienceList] = useState([]);
   const [experienceId, setExperienceId] = useState();
   const [selectedExperience, setSelectedExperience] = useState()
   const [pieData, setPieData] = useState({
      labels: ["STARTED", "COMPLETED"],
      datasets: [
         {
            data: [0],
            backgroundColor: ['#EBEFF3'],
            borderColor: '#FFFFFF',
            borderWidth: 1,
         },
      ],
   });
   const [years, setYears] = useState(0)
   const [levelArray, setLevels] = useState([]);
   const [showToastMessage, setShowToastMessage] = useState(false);
   const [toastMessage, setToastMessage] = useState("");

   useEffect(() => {

      if (userContext.role === Constant.ROLE.admin) {
         userContext.setBackUrl([
            {
               label: "All Organizations",
               url: '/clients'
            },
            {
               label: userContext.selectedClientName,
               url: '/client/' + userContext.selectedClient + '/courses'
            },
            {
               label: 'Course',
               url: '/analytics/' + userContext.selectedCourse
            }
         ]);

         userContext.setUserMenu([
            {
               label: 'Course Analytics',
               to: 'analytics/' + userContext.selectedCourse,
            },
            {
               label: 'Course Details',
               to: '/course/' + userContext.selectedCourse,
            },
            {
               label: 'LTI Configuration',
               to: '/course/lti/' + userContext.selectedCourse,

            },
            {
               label: 'Export',
               to: '/export/' + userContext.selectedCourse,
            }
         ]);
      } else if (userContext.role === Constant.ROLE.organization) {
         userContext.setUserMenu([
            {
               label: 'Course Analytics',
               to: 'analytics/' + userContext.selectedCourse,
            },
            {
               label: 'Course Details',
               to: '/course/' + userContext.selectedCourse,
            },
            {
               label: 'Export',
               to: '/export/' + userContext.selectedCourse,
            }
         ]);
         userContext.setBackUrl([
            {
               label: userContext.selectedClientName,
               url: '/dashboard'
            },
            {
               label: 'Course',
               url: '/analytics/' + userContext.selectedCourse
            }
         ]);
      } else {
         userContext.setUserMenu([
            {
               label: 'Course Analytics',
               to: 'analytics/' + userContext.selectedCourse,
            },
            {
               label: 'Course Details',
               to: '/course/' + userContext.selectedCourse,
            }
         ]);
         userContext.setBackUrl([
            {
               label: userContext.selectedClientName,
               url: '/dashboard'
            },
            {
               label: 'Course',
               url: '/analytics/' + userContext.selectedCourse
            }
         ]);
      }
      getCourseById();
   }, [userContext.selectedClient]);


   useEffect(() => {
      getLevelsListByCourse();
   }, [experienceId]);

   useEffect(() => {
      getFirstTryFinishCount(selectedPlatform, experienceId);
      GetRevisiteScenes(selectedPlatform, experienceId);
      GetReturnOnceCompletedCount(selectedPlatform, experienceId);
   }, [selectedPlatform, experienceId]);

   useEffect(() => {
      if (userContext.selectedCourse) {
         getAnalyticsDataByFilter();
         GetAverageTime();
      }
   }, [year, month, level, selectedPlatform, userContext.selectedCourse, experienceId]);


   const getCourseById = () => {
      if (userContext.selectedCourse) {
         courGet(API_ENDPOINT.GetCourseById(userContext.selectedCourse))
            .then((res) => {
               getPresignedURL(res.data.thumbnail, res.data.name);
               getExperienceListByCourseId();
            }).catch((error) => {
               console.log(error);
            })
      }
   }

   const getPresignedURL = (fileName, courseName) => {
      courGet(API_ENDPOINT.getPreSignedGetUrl(fileName))
         .then((res) => {
            userContext.setThumbnail({ path: res.data, name: courseName })
         }).catch((error) => {
            console.log(error);
         })
   }

   const getExperienceListByCourseId = () => {

      courGet(API_ENDPOINT.GetAllModules(userContext.selectedCourse))
         .then((res) => {
            if (res.data?.length > 0) {
               let firstExpId = res.data[0]._id;
               setExperienceId(res.data && firstExpId);

               if (res.data && firstExpId) {
                  setSelectedExperience(res.data[0])
               }
            }
            else {
               setToastMessage("No experience available.");
               setShowToastMessage(true);
            }
            setExperienceList(res.data);
         }).catch((error) => {
            console.log(error);
         })
   }

   const getLevelsListByCourse = () => {
      if (userContext.selectedCourse && experienceId) {
         courGet(API_ENDPOINT.GetLevelListByCourse(userContext.selectedCourse, experienceId))
            .then((res) => {
               if ((new Date()).getFullYear() == res.data.startingYear) {
                  setYears(1);
               } else if ((new Date()).getFullYear() > res.data.startingYear) {
                  var count = 1 + parseInt((new Date()).getFullYear()) - parseInt(res.data.startingYear);
                  if (count > res.data.yearCount) {
                     setYears(res.data.yearCount);
                  } else {
                     setYears(count);
                  }
               }

               //setYears(res.data.yearCount);
               let levelList = [{ label: "All " + "Levels", value: "" }]
               const level = res.data.levels;
               if (level && level.length > 0) {
                  for (let i = 0; i < level.length; i++) {
                     levelList.push({ label: level[i], value: level[i] })
                  };
                  setLevels(levelList);
               } else {
                  setLevels([]);
               }
            }).catch((error) => {
               console.log(error);
            })
      }
   }
   const onClickSelect = (e, id) => {
      if (selectedExperience?.mode !== "AR" || id === 1) {
         setClickedId(id);
         const platform = id === 1 ? Constant.SELECTED.All :
            (id === 2 ? Constant.SELECTED.Desktop : (id === 3 ? Constant.SELECTED.Headset : ''));
         setSelectedPlatform(platform);
      }

   }

   //get analytic bar chart data on device change and 
   useEffect(() => {
      // check if device is headset set data from local
      const allDeviceLocal = JSON.parse(localStorage.getItem('allDevice'));
      if (selectedPlatform === 'All' && allDeviceLocal?.payload && allDeviceLocal.payload.year == activeYear && allDeviceLocal.payload.experienceId == experienceId) {
         setBarLoader(false);
         setSelectedData(allDeviceLocal.selectedData);
         setActiveCount(allDeviceLocal.activeCount);
      } else if (selectedPlatform === 'All') {
         getUserData(selectedPlatform, activeYear, experienceId, levelArray);
      }

      // check if device is headset set data from local
      const headsetLocal = JSON.parse(localStorage.getItem('headsetDevice'));
      if (selectedPlatform === 'Headset' && headsetLocal) {
         setBarLoader(false);
         setSelectedData(headsetLocal.selectedData);
         setActiveCount(headsetLocal.activeCount);
      }

      // check if device is desktop set data from local
      const desktopLocal = JSON.parse(localStorage.getItem('desktopDevice'));
      if (selectedPlatform === 'Desktop' && desktopLocal) {
         setBarLoader(false);
         setSelectedData(desktopLocal.selectedData);
         setActiveCount(desktopLocal.activeCount);
      }

   }, [selectedPlatform, levelArray]);

   const handleActiveYearChange = e => {
      setActiveYear(e.target.value);
      getUserData(selectedPlatform, e.target.value, experienceId, levelArray)
   };

   const handleExperienceChange = (e) => {
      setSelectedData([]);
      setExperienceId(e.value)
      setSelectedExperience(experienceList.find(x => x._id === e.value));

   }

   const getUserData = useCallback((platform, year, expId, levelList) => {
      setBarLoader(true);
      if (userContext.selectedCourse && platform && year && expId !== undefined) {
         let data = {
            id: userContext.selectedCourse,
            platform: platform,
            year: Number(year),
            experienceId: expId,
            levelList: levelList
         }
         courPost(API_ENDPOINT.GetAnalyticsDataByDevice, data)
            .then((res) => {
               setBarLoader(false);
               if (res.data && platform == 'All') {
                  setSelectedData(res.data.selectedData);
                  setActiveCount(res.data.activeCount);
               }
               if (selectedPlatform == 'Headset') {
                  setSelectedData(res.data.selectedData);
                  setActiveCount(res.data.activeCount);
               }
               if (selectedPlatform == 'Desktop') {
                  setSelectedData(res.data.selectedData);
                  setActiveCount(res.data.activeCount);
               }

               // calling api call for both headset and desktop
               if (platform == 'All') {
                  let allDeviceLocal = res.data;
                  allDeviceLocal = { ...allDeviceLocal, payload: data }
                  localStorage.setItem('allDevice', JSON.stringify(allDeviceLocal));
                  getUserData('Headset', year, expId, levelList)
               }

               if (platform == 'Headset') {
                  let headsetLocal = res.data;
                  headsetLocal = { ...headsetLocal, payload: data }
                  localStorage.setItem('headsetDevice', JSON.stringify(headsetLocal));
                  getUserData('Desktop', year, expId, levelList)
               }

               if (platform == 'Desktop') {
                  let desktopLocal = res.data;
                  desktopLocal = { ...desktopLocal, payload: data }
                  localStorage.setItem('desktopDevice', JSON.stringify(desktopLocal));
               }
            }).catch((error) => {
               setBarLoader(false);
               console.log(error);
            })
      } else {
         setBarLoader(false);
      }
   }, [userContext.selectedCourse, year])

   const getFirstTryFinishCount = (platform, experienceId) => {
      if (platform && experienceId) {
         courGet(API_ENDPOINT.GetFirstTryFinishCount(userContext.selectedCourse, platform, experienceId))
            .then((res) => {
               setFirstTryFinish({ percentage: res.data.percentage, total: res.data.total })
            }).catch((error) => {
               console.log(error);
            })
      }
   }

   const GetAverageTime = (expId) => {
      let data = {
         id: userContext.selectedCourse,
         platform: selectedPlatform,
         year: Number(year),
         month: Number(month),
         level: level,
         experienceId: expId || experienceId
      }
      courPost(API_ENDPOINT.GetAverageTime, data)
         .then((res) => {
            setAvgTimeSpend({
               hours: res.data.totalTime / 3600,
               minutes: moment.duration(res.data.totalTime, 'seconds')
            });

         }).catch((error) => {
            console.log(error);
         })

   }

   const GetRevisiteScenes = (platform, experienceId) => {
      if (platform && experienceId) {
         courGet(API_ENDPOINT.GetRevisiteScenes(userContext.selectedCourse, platform, experienceId))
            .then((res) => {
               setRevisitScenes({ percentage: res.data.percentage, total: res.data.total })
            }).catch((error) => {
               console.log(error);
            })
      }
   }

   const GetReturnOnceCompletedCount = (platform, experienceId) => {
      if (platform && experienceId) {
         courGet(API_ENDPOINT.GetReturnOnceCompletedCount(userContext.selectedCourse, platform, experienceId))
            .then((res) => {
               setReturnOnceCompleted({ percentage: res.data.percentage, total: res.data.total })
            }).catch((error) => {
               console.log(error);
            })
      }
   }

   const getYearDropList = (yearsCount) => {
      const year = (new Date()).getFullYear();
      const years = Array.from(new Array(yearsCount), (val, index) => year - index);

      return (
         years.map((year, index) => {
            return <option className="chart-option" key={`year${index}`} value={year}>{year}</option>
         })
      );
   };


   const getMonthDropList = () => {
      return (
         Months.map((month, index) => {
            return <option className="chart-option" selected={month.value} key={`month${index}`} value={month.value}>{month.label}</option>
         })
      );
   };

   const handleYearChange = e => {
      setYear(e.target.value);

   };

   const handleMonthChange = e => {
      setMonth(e.target.value);
   };

   const handleLevelChange = e => {
      setLevel(e.target.value);
   }

   const ProgressBar = ({ value }) => {
      return <div className="vertical-bar">
         <div className={classnames("pgb-value", { "pgb-border": value === '100%' })} style={{ height: value }}></div>
      </div>
   }

   const Label = ({ percentage, value, margin }) => {
      let learnerTxt = value > 1 ? 'LEARNERS' : 'LEARNER';
      return <div className="per-content" style={{ marginTop: `${margin}px` }}>
         <div className="per-label">{percentage}</div>
         <div className="per-text">{`${value} ${learnerTxt}`}</div>
      </div>
   }

   const getAnalyticsDataByFilter = (expId) => {
      let data = {
         id: userContext.selectedCourse,
         platform: selectedPlatform,
         year: Number(year),
         month: Number(month),
         level: level,
         experienceId: expId || experienceId
      }
      courPost(API_ENDPOINT.GetAnalyticsDataByFilter, data)
         .then((res) => {
            setTotalLearnerCount(res.data.totalLearnerCount);

            setPieData({
               labels: ["STARTED", "COMPLETED"],
               datasets: [
                  {
                     data: res.data.totalLearnerCount > 0 ? [res.data.startedLearnerCount / res.data.totalLearnerCount * 100, res.data.completedLearnerCount / res.data.totalLearnerCount * 100] : [100],
                     backgroundColor: res.data.totalLearnerCount > 0 ? ['#D7EBFF', '#FFCCBE'] : ['#EBEFF3'],
                     borderColor: res.data.totalLearnerCount > 0 ? '#FFFFFF' : '#EBEFF3',
                     borderWidth: 1,
                  },
               ],
            });
         }).catch((error) => {
            console.log(error);
         })
   }

   const pieOption = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
         legend: {
            display: false
         },
         datalabels: {
            ...pieDataLabels,
            formatter: function (value) {
               if (totalLearnerCount === 0) {
                  return "\n" + 0 + " LEARNERS";
               }
               if (value === 0) {
                  return ""
               }

               return "\n" + Math.round(value / 100 * totalLearnerCount) + " LEARNERS";
            }
         },
         tooltip: {
            ...pieToolTipStyle,
            callbacks: {
               label: (Item) => {
                  if (totalLearnerCount === 0) {
                     return "\n" + 0 + " LEARNERS";
                  }
                  return Math.round(Item.formattedValue / 100 * totalLearnerCount) + ' LEARNERS'
               }
            }
         }
      },
      layout: { padding: { left: 0, right: 0, top: 0, bottom: 0 } }
   }

   const firstTryFinishMargin = 100 - firstTryFinish.percentage;
   const revisiteCountMargin = 100 - revisitScenes.percentage;
   const returnOnceCompleteMargin = 100 - returnOnceCompleted.percentage;

   const { Option } = components;
   const customOption = props => {
      return (
         <><ReactTooltip />
            <Option {...props} data-tip={props.label} />
         </>
      );
   };

   const expLabel = (name) => {
      return name && name.length > 34 ? name.substring(0, 34) + '...' : name;
   }

   const options = () => {
      const optionList = [];
      if (experienceList && experienceList.length > 0) {
         experienceList.map((exp, index) => {
            const option = {
               value: exp._id, tabValue: exp.name, label: (
                  <><ReactTooltip /><span data-tip={exp.name && exp.name.length > 34 ? exp.name : ''}>{expLabel(exp.name)}</span></>
               )
            }
            optionList.push(option);
         })
      }
      return optionList;
   }


   const customFilter = (option, searchText) => {
      if (
         option.data.tabValue.toLowerCase().includes(searchText.toLowerCase())
      ) {
         return true;
      } else {
         return false;
      }
   }
   return (<>
      <div className="dashboard">
         <div className="chart-header">
            <h1 className='analytics-name'>Course Analytics</h1>
            <div className="exp-buttons">
               {experienceList && experienceList.length > 0 &&
                  <div className="exp-dropdown-div">
                     <div className="exp-buttons" >
                        {selectedExperience?.mode === "AR" ? <img src={AR} className="analytic-mode" /> : <img src={VR} className="analytic-mode" />}
                        <span className="learner-text">Experience</span>
                        <Select
                           components={{
                              Option: customOption
                           }}
                           className="exp-dropDown"
                           classNamePrefix="select"
                           onChange={handleExperienceChange}
                           options={options()}
                           styles={{
                              control: (provided, state) => ({
                                 ...provided,
                                 minHeight: '32px',
                                 height: '32px',
                                 boxShadow: state.isFocused ? null : null,
                              }),
                              valueContainer: (provided, state) => ({
                                 ...provided,
                                 height: '32px',
                                 padding: '0 6px'
                              }),
                              input: (provided, state) => ({
                                 ...provided,
                                 margin: '0px',
                              }),
                              indicatorSeparator: state => ({
                                 display: 'none',
                              }),
                              indicatorsContainer: (provided, state) => ({
                                 ...provided,
                                 height: '32px',
                              }),
                           }}
                           isSearchable={true}
                           value={
                              options().filter(option =>
                                 option.value === experienceId)
                           }
                           filterOption={customFilter}
                        />
                     </div>
                  </div>
               }
               {['All', 'Desktop', 'Headset'].map((buttonLabel, i) => (
                  <button
                     key={i}
                     name={buttonLabel}
                     onClick={(event) => onClickSelect(event, i + 1)}
                     className={`${i + 1 === clickedId ? 'analytics-btn btn-All btn-active button-radius-${i}' : 'analytics-btn btn-All button-radius-${i} '} ${buttonLabel !== "All" && selectedExperience?.mode === "AR" ? 'ar-disabled' : ''}`} >
                     {buttonLabel}

                  </button>
               ))}
            </div>
         </div>

         <div className="bar-chart">
            <div className="exp-chart-contents">
               <div className="learner-text">Learners Per Experience</div>
               <div className="learner-exp-filter">
                  <div className="exp-sutTitle"><span className="point-circle" style={{ backgroundColor: "#D7EBFF" }}></span><span className="exp-sutText">Started</span></div>
                  <div className="exp-sutTitle"><span className="point-circle" style={{ backgroundColor: "#FFCCBE" }}></span><span className="exp-sutText">Completed</span></div>
                  <select className="chart-select" value={activeYear} onChange={handleActiveYearChange}>
                     <option className="chart-option" value={0}>All Years</option>
                     {getYearDropList(years)}
                  </select>
               </div>
            </div>
            <div className="learner-div">
               <span className="active-learner-number">{activeCount}</span>
               <span className="active-learner-text"> Learners</span>
            </div>
            {barLoader && <div className="bar-chart-loader" />}
            <BarChart
               levels={levelArray}
               selectedData={selectedData} />
         </div>

         <div className="chart-content">
            <div className="filter-content">
               <span className="filter">Filter</span>
               <select className="chart-select" value={year} onChange={handleYearChange}>
                  <option className="chart-option" value={0}>All Years</option>
                  {getYearDropList(years)}
               </select>

               <select className="chart-select" value={month} onChange={handleMonthChange}>
                  {getMonthDropList()}
               </select>

               {levelArray && levelArray.length > 0 &&
                  <select className="chart-select" value={level} onChange={handleLevelChange}>
                     {levelArray.map((level) => {
                        return <option className="chart-option" key={level.value} value={level.value}>{level.label}</option>
                     })
                     }
                  </select>
               }
            </div>
            <div className="filter-charts">
               <div className="activity-chart circleChart">
                  <div className="activity-text">Activity</div>
                  <span className="activity-subText">How many learners have interacted with this experience?</span>
                  <div className="pie-chart-contents">
                     <div><Pie data={pieData}
                        options={pieOption} height={200} />
                     </div>
                     <div className="learner-pie-legend">
                        <div className="exp-sutTitle pie-legand"><span className="point-circle" style={{ backgroundColor: "#D7EBFF" }}></span><span className="exp-sutText">Started</span></div>
                        <div className="exp-sutTitle pie-legand"><span className="point-circle" style={{ backgroundColor: "#FFCCBE" }}></span><span className="exp-sutText">Completed</span></div>
                     </div>
                  </div>
               </div>
               <div className="activity-chart timeChart">
                  <div className="activity-text">Average Time Per Learner</div>
                  <span className="activity-subText">How much time are learners spending?</span>
                  <div className="clock-contents">
                     <div className="clock" />
                     <div>
                        <span className="per-label">{avgTimeSpend.hours > 0 ? avgTimeSpend.hours.toString().split(".")[0] : "00"}’&nbsp;</span><span className="per-text">HOURS</span>
                        <div className="sec-block">
                           <span className="sec-text">{avgTimeSpend.minutes > 0 ? moment.utc(avgTimeSpend.minutes?.asMilliseconds()).format("mm") : "00"} ’’ &nbsp;</span>
                           <span className="per-text"> MINUTES </span>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div className="learner-charts">
               <div className="activity-chart bar-box">
                  <div className="activity-text">First Attempt Completion</div>
                  <span className="activity-subText">Percentage of learners that finished all experiences on the first attempt</span>
                  <div className="finish-bar-1">
                     <ProgressBar value={`${firstTryFinish.percentage}%`} />
                     <Label margin={firstTryFinishMargin} percentage={`${firstTryFinish.percentage}%`} value={`${firstTryFinish.total}`} />
                  </div>
               </div>

               <div className="activity-chart bar-box">
                  <div className="activity-text">Revisit Scenes</div>
                  <span className="activity-subText">Percentage of learners that revisited completed experiences</span>
                  <div className="finish-bar-2">
                     <ProgressBar value={`${revisitScenes.percentage}%`} />
                     <Label margin={revisiteCountMargin} percentage={`${revisitScenes.percentage}%`} value={`${revisitScenes.total}`} />
                  </div>
               </div>

               <div className="activity-chart bar-box">
                  <div className="activity-text">Returned Once Completed</div>
                  <span className="activity-subText">Percentage of learners that return to the experience after 100% completion</span>
                  <div className="finish-bar-3">
                     <ProgressBar value={`${returnOnceCompleted.percentage}%`} />
                     <Label margin={returnOnceCompleteMargin} percentage={`${returnOnceCompleted.percentage}%`} value={`${returnOnceCompleted.total}`} />
                  </div>
               </div>
            </div>
         </div>
      </div>
      {showToastMessage === true &&
         <ToastContainer className="p-3" position={"top-center"} >
            <Toast
               className="toast-message"
               bg="danger"
               key="2">
               <Toast.Body className="toast-text"> <img src={danger} className="me-2" />
                  {toastMessage}
               </Toast.Body>
            </Toast>
         </ToastContainer>
      }</>
   );
}

export default AnalyticsDetails;