import { useContext, useEffect, useState } from "react";
import InviteOwner from "../Dashboard/InviteOwner";
import MemberItem from "../shared/MemberItem";
import { API_ENDPOINT } from "../literals";
import { courGet } from "../../service/courAPI";
import { Constant } from "../Constant/constant";
import UserContext from "../../UserContext";
import { Dropdown } from "react-bootstrap";
import CustomDropdown from "../Inputs/CustomDropdown";
import CustomDropdownMenu from "../Inputs/customDropdownMenu";
import { RiDeleteBin6Line } from "react-icons/ri";
import { MdOutlineClose } from "react-icons/md";

const PlatformAdmins = (props) => {

    const [platformAdminList, setPlatformAdminList] = useState([]);
    const userContext = useContext(UserContext);
    const [isRemove, setIsRemove] = useState(false);

    useEffect(() => {
        getPlatformAdmin();
    }, []);

    const getPlatformAdmin = () => {
        //get role wise data
        courGet(API_ENDPOINT.GetUser(Constant.ROLE.admin))
            .then((res) => {
                setPlatformAdminList(res.data);
            })
            .catch((err) => {
                console.log(err);
            });

    }


    return <>
        <div className="organization-owners">
            <div className="d-flex justify-content-between">
                <h1 className='title header-color'>Platform Admins</h1>
                {userContext.role !== Constant.ROLE.partner &&
                    <Dropdown align="end">
                        <Dropdown.Toggle as={CustomDropdown} id="dropdown-custom-components">
                        </Dropdown.Toggle>

                        <Dropdown.Menu as={CustomDropdownMenu}>
                            {!isRemove &&
                                <Dropdown.Item eventKey="1" className="dropDown-menu-text" onClick={() => { setIsRemove(true) }}><RiDeleteBin6Line className="icon-dropdown" />Remove Admins</Dropdown.Item>}
                            {isRemove &&
                                <Dropdown.Item eventKey="1" className="dropDown-menu-text" onClick={() => { setIsRemove(false) }}><MdOutlineClose className="icon-dropdown" />Cancel</Dropdown.Item>}
                        </Dropdown.Menu>
                    </Dropdown>
                }
            </div>
            <div className="member-list">
                {platformAdminList.map((client) => {
                    return (<MemberItem member={client} key={"member" + client._id} refreshMembers={getPlatformAdmin} isRemove={isRemove} />);
                })}
                <InviteOwner refreshMembers={getPlatformAdmin} role="superAdmin" title={"Add New Platform Admin"} />
            </div>
        </div>
    </>
}

export default PlatformAdmins;