import { useContext, useState } from "react";
import { Col, Row } from "react-bootstrap";
import UserContext from "../../UserContext";
import { toBytes, bytesToSize } from '../Utils/commonFunctions'
import { FileUploader } from "react-drag-drop-files";
import { BiImageAdd } from "react-icons/bi";
import { MdCloudUpload } from "react-icons/md";
import { inputError } from "../Constant/errors";
import "./FileUpload.css"
import ImageCropDialog from "./ImageCropDialog";

//file size should be in mb. here by default set 1mb
const FileUpload = ({ text = '', subText = '', type = 'image', fileSize = '1', aspect = 1, ...props }) => {
    const userContext = useContext(UserContext);
    const acceptZip = ['zip'];
    const acceptImages = ['jpeg', 'png', 'jpg']
    const acceptFormat = type === 'zip' ? acceptZip : acceptImages;
    const [showImageCrop, setShowImageCrop] = useState(false);
    const [imageFile, setImageFile] = useState([]);
    const [fileName, setFileName] = useState("");

    const handleFileUpload = ((file) => {
        if (type === "image") {
            file.name = fileName;
        }
        props.uploadFile(file, type);
        setShowImageCrop(false);
    });

    const handleBackButton = () => {
        setShowImageCrop(false);
        setFileName('');
        setImageFile('');
    }

    const handleImageCrop = (fileData) => {
        if (fileData) {
            setImageFile(URL.createObjectURL(fileData));
            setFileName(fileData.name);
            setShowImageCrop(true);
        }
    }
     const handleTypeError = () => {
        userContext.createToast({ title: "file type error", message: inputError.fileFormatError(acceptFormat.join("/")), variant: "danger" });
     }
     
    const handleSizeError = () => {
        userContext.createToast({ title: "file size error", message: inputError.fileSizeError(bytesToSize(toBytes(fileSize, 'MB'))), variant: "danger" });
     }
    return (
        <>
            {text && <div className="upload-heading subText-color mb-2">{text}</div>}
            {subText && <div className="upload-subheading subText-color mb-4">{subText}</div>}
            <div className="upload-container">
                {showImageCrop === false &&
                    <FileUploader
                        classes='file-container background-color subText-color mb-2'
                        handleChange={type === "image" ? async (e) => { handleImageCrop(e); } : handleFileUpload}
                        //onDrop={handleFileUpload}
                        types={acceptFormat}
                        maxSize={fileSize}
                        onTypeError={e => { handleTypeError(e) }}
                        onSizeError={e => { handleSizeError(e) }}>
                        <Row>
                            <Col md={12} className="text-center">
                                {type === "image" ? <BiImageAdd className="cloud-upload " /> :
                                    <MdCloudUpload className=" cloud-upload" />
                                }
                            </Col>
                            <Col md={12} className="text-center">
                                <div className="text subText-color">
                                    {type === "image" ? 'Upload Image' : 'Upload file'}</div>
                            </Col>
                        </Row>
                    </FileUploader>
                }
                <span className="file-size-text">Max. file size: {bytesToSize(toBytes(fileSize, 'MB'))}</span>
            </div>

            <ImageCropDialog show={showImageCrop}
                cancel={handleBackButton}
                file={imageFile}
                handleYes={handleFileUpload}
                aspect={aspect} />
        </>
    )
}

export default FileUpload