import React from "react";

const InviteExpred = () => {
    return (
        <div className="invite-owner h-100vh">
            <h1>Invite Expired</h1>
        </div>
    );
}

export default InviteExpred;