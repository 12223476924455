import React, { useEffect, useState } from "react";
import UserContext from "../../UserContext";
import './ProfileAppearence.css';

const ProfileAppearence = (props) => {
    const contextUser = React.useContext(UserContext);
    const [customColor, setCustomColor] = React.useState(false);
    const [selectedColorType, setColorType] = React.useState("Primary");
    const [selectedColor, setSelectedColor] = React.useState("#415464");
    const [selectedPrimaryColor, setSelectedPrimaryColor] = React.useState("");
    const [selectedSecondaryColor, setSelectedSecondaryColor] = React.useState("");
    const [colorPalette3, setcolorPalette3] = React.useState({
        "color1": "#415464",
        "color2": "#71797f",
        "color3": "#a79d95",
        "color4": "#696969",
        "color5": "#787878",
        "color6": "#9e9e9e"
    });

    const colorPalette = {
        "color1": "#415464",
        "color2": "#2F70C3",
        "color3": "#53B6AD",
        "color4": "#53B674",
        "color5": "#EFC922",
        "color6": "#F1742E"
    }

    const colorPalette2 = {
        "color1": "#C12316",
        "color2": "#AE2989",
        "color3": "#7645F6",
        "color4": "#4746B0",
        "color5": "#607180",
        "color6": "#545454"
    }
    useEffect(() => {
        setSelectedPrimaryColor(props.primaryColor);
        setSelectedSecondaryColor(props.secondaryColor);

        if (selectedColorType === "Primary") {
            setSelectedColor(props.primaryColor);
        }

        if (selectedColorType === "Secondary") {
            setSelectedColor(props.secondaryColor);
        }

    }, [props.primaryColor, props.secondaryColor]);

    const selectColor = (color) => {
        if (selectedColorType === "Primary") {
            setSelectedPrimaryColor(color);
            createSecondaryColorsArray(color);
            props.sendColorCode(selectedColorType, color);
        }

        if (selectedColorType === "Secondary") {
            setSelectedSecondaryColor(color);
            props.sendColorCode(selectedColorType, color);
        }
    }

    function HSLToHex(h, s, l) {
        s /= 100;
        l /= 100;

        let c = (1 - Math.abs(2 * l - 1)) * s,
            x = c * (1 - Math.abs((h / 60) % 2 - 1)),
            m = l - c / 2,
            r = 0,
            g = 0,
            b = 0;

        if (0 <= h && h < 60) {
            r = c; g = x; b = 0;
        } else if (60 <= h && h < 120) {
            r = x; g = c; b = 0;
        } else if (120 <= h && h < 180) {
            r = 0; g = c; b = x;
        } else if (180 <= h && h < 240) {
            r = 0; g = x; b = c;
        } else if (240 <= h && h < 300) {
            r = x; g = 0; b = c;
        } else if (300 <= h && h < 360) {
            r = c; g = 0; b = x;
        }
        // Having obtained RGB, convert channels to hex
        r = Math.round((r + m) * 255).toString(16);
        g = Math.round((g + m) * 255).toString(16);
        b = Math.round((b + m) * 255).toString(16);

        // Prepend 0s, if necessary
        if (r.length == 1)
            r = "0" + r;
        if (g.length == 1)
            g = "0" + g;
        if (b.length == 1)
            b = "0" + b;

        return "#" + r + g + b;
    }

    function hexToHSL(H) {
        // Convert hex to RGB first
        let r = 0, g = 0, b = 0;
        if (H.length == 4) {
            r = "0x" + H[1] + H[1];
            g = "0x" + H[2] + H[2];
            b = "0x" + H[3] + H[3];
        } else if (H.length == 7) {
            r = "0x" + H[1] + H[2];
            g = "0x" + H[3] + H[4];
            b = "0x" + H[5] + H[6];
        }
        // Then to HSL
        r /= 255;
        g /= 255;
        b /= 255;
        let cmin = Math.min(r, g, b),
            cmax = Math.max(r, g, b),
            delta = cmax - cmin,
            h = 0,
            s = 0,
            l = 0;

        if (delta == 0)
            h = 0;
        else if (cmax == r)
            h = ((g - b) / delta) % 6;
        else if (cmax == g)
            h = (b - r) / delta + 2;
        else
            h = (r - g) / delta + 4;

        h = Math.round(h * 60);

        if (h < 0)
            h += 360;

        l = (cmax + cmin) / 2;
        s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
        s = +(s * 100).toFixed(1);
        l = +(l * 100).toFixed(1);

        return "hsl(" + h + "," + s + "%," + l + "%)";
    }

    const createSecondaryColorsArray = (hex) => {
        let hsl = hexToHSL(hex);
        let sep = hsl.indexOf(",") > -1 ? "," : " ";
        hsl = hsl.substr(4).split(")")[0].split(sep);

        let h = hsl[0],
            s = hsl[1].substr(0, hsl[1].length - 1),
            l = hsl[2].substr(0, hsl[2].length - 1);

        var color = {
            "color1": HSLToHex(h, s, l),
            "color2": HSLToHex(h, (parseInt(s) - parseInt(15)), (parseInt(l) + parseInt(15))),
            "color3": HSLToHex(h, (parseInt(s) - parseInt(30)), (parseInt(l) + parseInt(30))),
            "color4": HSLToHex(h, 0, 41),
            "color5": HSLToHex(h, 0, (parseInt(l) + parseInt(15))),
            "color6": HSLToHex(h, 0, (parseInt(l) + parseInt(30))),
        }
        setcolorPalette3(color)
    }

    return (
        <>
            <div className="profileAppearenceContainer">
                <div className="profileAppearenceHeader">Profile Appearence</div>
                <div className="colorTypeBlock">
                    <div className="colorTypeText">
                        <div className="selectAccentBlock">Select accent colors:</div>
                        <div className="primaryBlock">
                            <div>Primary</div>
                            <div className="color-filled-square" onClick={() => { setColorType("Primary"); setSelectedColor(selectedPrimaryColor); }}>
                                <div style={{ "backgroundColor": selectedPrimaryColor }}></div>
                                {selectedColorType == "Primary" && <div></div>}
                            </div>
                        </div>
                        <div className="secondaryBlock">
                            <div>Secondary</div>
                            <div className="color-filled-square" onClick={() => { setColorType("Secondary"); setSelectedColor(selectedSecondaryColor); }}>
                                <div style={{ "backgroundColor": selectedSecondaryColor }}></div>
                                {selectedColorType == "Secondary" && <div></div>}
                            </div>
                        </div>
                    </div>
                    <div className="colorPicker">
                        <div className="headerRow">Color Picker</div>
                        <div className="colorPickerBlock">
                            {selectedColorType === "Primary" && <> <div className="firstRow">
                                {
                                    [...Array(6)].map((e, i) => {
                                        return (
                                            <div className="color-filled" style={{ "border": selectedColor == colorPalette['color' + (i + 1)] ? "1px solid #A8A8A8" : "1px solid transparent" }}>
                                                <div onClick={() => { setSelectedColor(colorPalette['color' + (i + 1)]); selectColor(colorPalette['color' + (i + 1)]) }} style={{ "backgroundColor": colorPalette['color' + (i + 1)] }}></div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                                <div className="secondRow">
                                    {
                                        [...Array(6)].map((e, i) => {
                                            return (
                                                <div className="color-filled" style={{ "border": selectedColor == colorPalette2['color' + (i + 1)] ? "1px solid #A8A8A8" : "1px solid transparent" }}>
                                                    <div onClick={() => { setSelectedColor(colorPalette2['color' + (i + 1)]); selectColor(colorPalette2['color' + (i + 1)]) }} style={{ "backgroundColor": colorPalette2['color' + (i + 1)] }}></div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </>
                            }
                            {selectedColorType === "Secondary" &&
                                <div className="firstRow">
                                    {
                                        [...Array(6)].map((e, i) => {
                                            return (
                                                <div className="color-filled" style={{ "border": selectedColor == colorPalette3['color' + (i + 1)] ? "1px solid #A8A8A8" : "1px solid transparent" }}>
                                                    <div onClick={() => { selectColor(colorPalette3['color' + (i + 1)]); setSelectedColor(colorPalette3['color' + (i + 1)]); }} style={{ "backgroundColor": colorPalette3['color' + (i + 1)] }}></div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            }
                            <div className="thirdRow">
                                <div>Custom color</div>
                                <div style={{ "backgroundColor": selectedColor }}></div>
                                <div><input value={selectedColor} type="text" onChange={(e) => { setSelectedColor(e.target.value); setCustomColor(true); selectColor(e.target.value); }} /></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );


}

export default ProfileAppearence;