import { useEffect, useContext, useState, useRef, useCallback } from "react";
import UserContext from "../../UserContext";
import { useLocation, useNavigate } from "react-router-dom";
import { Text } from "../Constant/string";
import { Dropdown } from "react-bootstrap";
import { Constant } from "../Constant/constant";
import CustomDropdown from "../Inputs/CustomDropdown";
import CustomDropdownMenu from "../Inputs/customDropdownMenu";
import { BiCopy } from "react-icons/bi";
import VersionList from "./VersionList";
import { API_ENDPOINT } from "../literals";
import { v4 as uuidv4 } from 'uuid';
import axios, { CancelToken } from 'axios';
import { courGet, courPut, courDelete, courPost } from "../../service/courAPI";
import DeleteItem from "./DeleteItem";
import CourseDetails from "./CourseDetails";
import { inputError } from "../Constant/errors";
import CryptoJS from "crypto-js";
import { HiPencil } from "react-icons/hi";
import { IoIosClose } from "react-icons/io";
import { validateTextInput } from "../Utils/validation";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { RiDeleteBin6Line } from "react-icons/ri";
import AR from "../../images/ar.svg";
import VR from "../../images/vr.svg"

function useOnClickOutside(ref, handler) {
    useEffect(() => {
        const listener = event => {
            if (!ref.current || ref.current.contains(event.target)) {
                return;
            }

            handler(event);
        };

        document.addEventListener("mousedown", listener);
        document.addEventListener("touchstart", listener);

        return () => {
            document.removeEventListener("mousedown", listener);
            document.removeEventListener("touchstart", listener);
        };
    }, [ref, handler]);
}

function ModuleDetails(props) {
    const userContext = useContext(UserContext);
    const navigate = useNavigate();
    const location = useLocation();
    const modules = location.state && location.state.modules;
    const course = location.state && location.state.course;
    const [moduleDetails, setModuleDetails] = useState(modules || []);
    const [showDeleteModel, setShowDeleteModel] = useState(false);
    const [showConfirmationVersionDialog, setShowConfirmationVersionDialog] = useState(false);
    const [showUploadVersionDialog, setShowUploadVersionDialog] = useState(false);
    const [selectedVersion, setSelectedVersion] = useState('')
    const [show, setShow] = useState(false);
    const [error, setError] = useState('');
    const [file, setFile] = useState('');
    const [disable, setDisable] = useState(false);
    const [step, setStep] = useState(0);
    const [launchUrl, setLaunchUrl] = useState('');
    const [levels, setLevels] = useState({});
    const [deleteModuleName, setDeleteModuleName] = useState('');
    const [fileLoading, setFileLoading] = useState({ "percent": 0, "total": 0, "loaded": 0 });
    const [openTextField, setOpenTextField] = useState(false);
    const cancelFileUpload = useRef(null);
    const [shareUrl, setShareUrl] = useState("");
    const [moduleName, setModuleName] = useState('');
    const [moduleNameError, setModuleNameError] = useState('');
    const moduleRef = useRef(null);
    const moduleInputRef = useRef(null);
    const courseNameRef = useRef(null);
    const [fileData, setFileData] = useState('');
    const [randomValue, setRandomValue] = useState(0);
    const [levelsList, setLevelsList] = useState([]);
    const [selectedLevel, setLevel] = useState('');
    const handler = useCallback(() => {
        handleModuleNameChanged();
    }, [moduleDetails, moduleName]);
    useOnClickOutside(moduleRef, handler);

    const handleModuleNameChanged = () => {
        const nameError = validateTextInput(moduleName, Text.nameExperience, true, 1, 100);
        setModuleNameError(nameError);
        if (!nameError) {
            editModuleName(moduleName);
        }
    }
    const handleKeyDown = (e) => {
        if (e.keyCode === 13) {
            handleModuleNameChanged();
        }
    }

    useEffect(() => {
        localStorage.setItem('fileLoading', JSON.stringify(fileLoading));
    }, [fileLoading])

    useEffect(() => {
        if (userContext.role === Constant.ROLE.admin) {
            userContext.setBackUrl([
                {
                    label: "All Organizations",
                    url: '/clients'
                },
                {
                    label: userContext.selectedClientName,
                    url: '/client/' + userContext.selectedClient + '/courses'
                },
                {
                    label: 'Course',
                    url: '/course/' + userContext.selectedCourse
                },
                {
                    label: 'Course Experience ',
                    url: ''
                }
            ]);
        } else {
            userContext.setBackUrl([
                {
                    label: userContext.selectedClientName,
                    url: '/dashboard'
                },
                {
                    label: 'Course',
                    url: '/course/' + userContext.selectedCourse
                },
                {
                    label: 'Course Experience ',
                    url: ''
                }
            ]);
        }
        if (userContext.selectedClient && userContext.selectedCourse) {
            getModuleById(moduleDetails._id);
            getLevelsListByCourse();
            createShareLink();
        }
    }, [userContext.selectedClient]);

    useEffect(() => {
        if (moduleDetails._id) {
            const intervalId = setInterval(() => {
                courGet(API_ENDPOINT.GetModuleById(moduleDetails._id))
                    .then((res) => {
                        setModuleDetails(res.data);
                        const isProgress = res.data.version.filter(x => x.fileStatus == "0");
                        if (isProgress.length > 0) {
                            //console.log('Status is false, calling API again in 2 seconds...');
                        } else {
                            //console.log('Status is true, stopping API calls.');
                            clearInterval(intervalId);
                        }
                    }).catch((error) => {
                        console.log(error);
                    })
            }, 2000);

            return () => clearInterval(intervalId);
        }
    }, [randomValue]);

    const editModuleName = (name) => {
        if (moduleDetails.name === name) {
            setOpenTextField(false);
            return;
        }
        courPut(API_ENDPOINT.EditExperienceById(moduleDetails._id, name))
            .then(() => {
                getModuleById(moduleDetails._id);
                setOpenTextField(false);
                userContext.createToast({ title: "Edit experience name", message: "Experience name updated successfully.", variant: "Success" });
            }).catch(error => {
                handleError(error, 'Experience name not updated');

            });
    };

    const createShareLink = () => {
        let data = {
            experience: moduleDetails._id,
            mode: moduleDetails.mode
        }
        if (selectedLevel) {
            data = { ...data, level: selectedLevel }
        }
        let encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), 'sweet-rush-key@123').toString();
        let encryptToBase64 = CryptoJS.enc.Base64.parse(encryptedData);
        var encryptToHex = encryptToBase64.toString(CryptoJS.enc.Hex);
        if (moduleDetails.mode === "AR") {
            setShareUrl(window.location.origin + "/launchAR/" + encryptToHex)
        }
        else {
            setShareUrl(window.location.origin + "/launch/" + encryptToHex);
        }
    }

    const getModuleById = (id) => {
        courGet(API_ENDPOINT.GetModuleById(id))
            .then((res) => {
                setModuleDetails(res.data);
                setRandomValue(Math.random());
            }).catch((error) => {
                // console.log(error);
            })
    }


    const handleBackButton = () => {
        cleanStorage();
        abortFileUpload();
        file && removeFile(file);
    }

    const cleanStorage = () => {
        setShow(false);
        setDisable(false);
        setStep(0);
        setShowUploadVersionDialog(false);
        setShowDeleteModel(false);
        setError('');
        setFile('');
        setFileData('');
        setLaunchUrl('');
        setLevels({});
        setFileLoading({ "percent": 0, "total": 0, "loaded": 0 });
        localStorage.removeItem('fileLoading');
        setShowConfirmationVersionDialog(false);
        setSelectedVersion('');
        setDeleteModuleName('');
        setModuleName('');
        setOpenTextField(false);
        setModuleNameError('');
    }
    const handleNewVersionModel = () => {
        setShow(!show)
    }

    const cancelUpload = (filename, type) => {
        removeFile(filename, type);
        abortFileUpload();
    };

    const abortFileUpload = () => {
        if (cancelFileUpload.current) {
            cancelFileUpload.current("User has canceled the file upload.");
        }
    }

    const removeFile = (fileName) => {
        courDelete(API_ENDPOINT.FileRemove(fileName))
            .then(res => {
                setFile('');
                setFileLoading({ "percent": 0, "total": 0, "loaded": 0 })
                localStorage.removeItem('fileLoading');
            }).catch(error => {
                handleError('Not able to delete file', error);
            })
        abortFileUpload();
    }

    const uploadFile = (fileData, type) => {
        setError('')
        if (fileData) {
            const fileName = uuidv4() + '-' + fileData.name.replace(/[^a-zA-Z0-9. ]/g, "").toLowerCase().split(' ').join('');
            setFile(fileName);
            setDisable(true);
            setFileData(fileData);
            handleFileUpload(type, fileName, fileData);
        }
    }

    const resumeFileUpload = () => {
        handleFileUpload('zip', file, fileData);
    }

    const handleFileUpload = (type, fileName, fileData) => {
        const formData = new FormData();
        formData.append('file', fileData, fileName);
        const apiRequest = type === "zip" ? API_ENDPOINT.ZipUpload(type) : API_ENDPOINT.uploadFile(type);
        axios.post(apiRequest, formData,
            {
                onUploadProgress: (event) => {
                    const prevLoaded = JSON.parse(localStorage.getItem('fileLoading'));
                    if (prevLoaded.loaded <= event.loaded) {
                        let percent = Math.floor((100 * event.loaded) / event.total);
                        setFileLoading({ "percent": percent, "total": event.total, "loaded": event.loaded });
                    }
                },
                cancelToken: new CancelToken(
                    cancel => (cancelFileUpload.current = cancel)
                ),
            }
        ).then((response) => {
            if (response) {
                setLaunchUrl(fileName.split('.')[0] + "/" + response.data.fileExtractFields.launchURL);
                setLevels(response.data.fileExtractFields.levelList);
                setDisable(false);
            }
        }).catch(error => {
            if (error.code === 'ERR_NETWORK') {
                handleFileUpload(type, fileName, fileData)
            } else if (error.code === 'ERR_CANCELED') {
                // console.log(error);
            } else {
                setFile('');
                setDisable(false);
                setFileLoading({ "percent": 0, "total": 0, "loaded": 0 });
                localStorage.removeItem('fileLoading');
                handleError(error, 'Upload version');
            }
        });
    }


    const handleFileError = () => {
        setFile('');
        setDisable(false);
        setFileLoading({ "percent": 0, "total": 0, "loaded": 0 });
        localStorage.removeItem('fileLoading');
        setFileData('');
    }

    const addModuleVersion = () => {
        setShowUploadVersionDialog(false);
        let data = { "id": uuidv4(), "fileName": file, launchURL: launchUrl, levels: levels, active: false, fileStatus: '0' }
        if (file) {
            courPut(API_ENDPOINT.AddModuleVersion(moduleDetails._id), data)
                .then((res) => {
                    cleanStorage();
                    getModuleById(moduleDetails._id);
                    userContext.createToast({ title: "Add version", message: "New experience version created.", variant: "Success" });

                    data = { _id: moduleDetails._id, versionId: data.id }
                    courPost(API_ENDPOINT.FolderUpload(file), data).then((res) => {
                        getModuleById(moduleDetails._id);
                    }).catch((err) => {
                        if (err.code === Constant.ERROR.network) {
                            //Console.log("network error")
                        } else if (err.response.status != 524) {
                            updateFileStatus(moduleDetails._id, data.versionId);
                        }
                        getModuleById(moduleDetails._id);
                    })
                })
                .catch((err) => {
                    handleError(err, 'Add module');
                });
        }
    }

    const getLevelsListByCourse = () => {
        if (userContext.selectedCourse && moduleDetails._id) {
            courGet(API_ENDPOINT.GetLevelListByCourse(userContext.selectedCourse, moduleDetails._id))
                .then((res) => {


                    //setYears(res.data.yearCount);
                    let levelList = [{ label: "All " + "Levels", value: "" }]
                    const level = res.data.levels;
                    if (level && level.length > 0) {
                        for (let i = 0; i < level.length; i++) {
                            levelList.push({ label: level[i], value: i + 1 })
                        };
                        setLevelsList(levelList);
                    } else {
                        setLevelsList([]);
                    }
                }).catch((error) => {
                    console.log(error);
                })
        }
    }

    const handleLevelChange = (e) => {
        setLevel(e.target.value);
    }

    useEffect(() => {
        createShareLink();
    }, [selectedLevel])
    const updateFileStatus = (id, versionId) => {
        courPut(API_ENDPOINT.updateFileStatus(id, versionId)).then((res) => {
            getModuleById(moduleDetails._id);
        })
    }

    const handleError = (err, title) => {
        if (err.code === Constant.ERROR.network) {
            userContext.createToast({ title: "internal error", message: inputError.internalError, variant: "danger" });
        }
        else {
            userContext.createToast({ title: title, message: err.response.data.message, variant: "danger" });
        }
    }

    const updateModule = () => {
        if (moduleDetails && moduleDetails.version.length >= 3) {
            setShowUploadVersionDialog(true);
        } else {
            addModuleVersion();
        }
    }

    const handleInputField = (e) => {
        setDeleteModuleName(e.target.value);
    }

    const handleVersionConfirmationYes = (e) => {
        if (selectedVersion) {
            courPut(API_ENDPOINT.UpdateActiveVersionId(moduleDetails._id, selectedVersion))
                .then((res) => {
                    getModuleById(moduleDetails._id);
                    createShareLink();
                })
                .catch((err) => {
                    handleError(err, 'Active version');
                }).finally(() => {
                    cleanStorage();
                })
        }
    }

    const handleRadioButton = (e) => {
        setSelectedVersion(e.target.value);
        setShowConfirmationVersionDialog(true);
    }

    const handleDeleteStep = () => {
        if (step === 0) {
            setStep(1)
        }
        if (step === 1) {
            courDelete(API_ENDPOINT.DeleteModule(moduleDetails._id))
                .then(() => {
                    userContext.createToast({ title: "Experience deleted", message: "Experience deleted.", variant: "Success" });
                    cleanStorage();
                    navigate('/course/' + CourseDetails.name);
                })
                .catch((err) => {
                    handleError(err, 'Experience deleted');
                });
        }
    }

    const handleEditModuleName = (e) => {
        setModuleName(moduleDetails.name)
        setOpenTextField(!openTextField);
    }
    useEffect(() => {
        if (openTextField) {
            const { innerWidth } = window;
            let width = courseNameRef.current.offsetLeft - 175;
            let currentOffset = innerWidth - 362 - width;
            if (currentOffset >= 350) {
                moduleInputRef.current.style.width = "calc(100% - " + width + "px)";
            }
            else {
                moduleInputRef.current.style.width = "100%";
            }

        }
    }, [openTextField]);

    const handleModuleName = (e) => {
        setModuleNameError('');
        setModuleName(e.target.value);
    }

    const handleClearName = () => {
        setModuleName('');
        setOpenTextField(false);
        setModuleNameError('');
        getModuleById(moduleDetails._id);
    }


    return (
        <><div className="dashboard content-margin">
            <div className="d-flex justify-content-between">
                <span className="module-title width-100">
                    {course && course.name && <span className=' courseName' >{`${course.name} |`}&nbsp;<span ref={courseNameRef}></span></span>}
                    {openTextField ?
                        <span className="module-box" ref={moduleInputRef}>
                            <span className="module-name-box width-100" ref={moduleRef}>
                                <input
                                    id='moduleName'
                                    label="Experience Name"
                                    name="name"
                                    type="text"
                                    value={moduleName}
                                    maxlength="100"
                                    onChange={(e) => handleModuleName(e)}
                                    onKeyDown={handleKeyDown}
                                    className='edit-module-name courseName width-100'
                                    required />
                                <div class="edit-close">
                                    <IoIosClose style={{ fontSize: '24px' }} onClick={handleClearName} />
                                </div>
                            </span>
                            {
                                moduleNameError && <div className="error-message">{moduleNameError}</div>
                            }
                        </span> :
                        moduleDetails &&
                        // <span className="module-name">
                        <span className='courseName'>{moduleDetails.name}
                            {userContext.role !== Constant.ROLE.partner && <HiPencil className="edit-module-icon" onClick={handleEditModuleName} />}
                        </span>
                        // </span>
                    }
                </span>
                {userContext.role !== Constant.ROLE.partner &&
                    <Dropdown align="end">
                        <Dropdown.Toggle as={CustomDropdown} id="dropdown-custom-components">
                        </Dropdown.Toggle>

                        <Dropdown.Menu as={CustomDropdownMenu}>
                            <Dropdown.Item className="dropDown-menu-text" eventKey="1" onClick={() => setShowDeleteModel(true)}><RiDeleteBin6Line className="icon-dropdown" />Delete</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>}
            </div>

            {moduleDetails?.mode === "AR" ? <img src={AR} className="module-mode" /> : <img src={VR} className="module-mode" />}

            <div className="subContent-margin">
                <div className="d-flex align-items-center gap-3">
                    <h1 className='subTitle m-0'>{Text.activeLink}</h1>
                    <h1 className='subTitle m-0'>
                        |
                    </h1>
                    <h2 className="module-level m-0">Level</h2>
                    {levelsList && levelsList.length > 0 &&
                        <select className="chart-select m-0" value={selectedLevel} onChange={handleLevelChange}>
                            {levelsList.map((level) => {
                                return <option className="chart-option" key={level.value} value={level.value}>{level.label}</option>
                            })
                            }
                        </select>
                    }
                </div>
                <div className="module-active-link">
                    <div class="active-Link-div content-width">{shareUrl}</div>
                    <CopyToClipboard text={shareUrl}
                        onCopy={() => userContext.createToast({ title: "Link copied", message: "Link copied.", variant: "Success" })}>
                        <div class="copy-icon-div"><BiCopy className="download-icon" /></div>
                    </CopyToClipboard>

                    <span className="copy-link">Copy Link</span>
                </div>
            </div>
            <hr className="hr-line" />
            <div className="subContent-margin ">
                <VersionList show={show}
                    error={error}
                    loading={fileLoading}
                    file={file}
                    cancelFileUpload={cancelFileUpload}
                    handleNewVersionModel={handleNewVersionModel}
                    onHide={() => setShow(false)}
                    uploadFile={uploadFile}
                    cancelUpload={cancelUpload}
                    handleBackButton={handleBackButton}
                    handleRadioButton={handleRadioButton}
                    showConfirmationVersionDialog={showConfirmationVersionDialog}
                    showUploadVersionDialog={showUploadVersionDialog}
                    handleYes={handleVersionConfirmationYes}
                    removeFile={removeFile}
                    addExperienceVersion={updateModule}
                    versionList={moduleDetails.version}
                    addNewModuleVersion={addModuleVersion}
                    disable={disable}
                    abortFileUpload={abortFileUpload}
                    resumeFileUpload={resumeFileUpload}
                    handleFileError={handleFileError} />
            </div>
        </div>
            <DeleteItem show={showDeleteModel}
                handleBackButton={handleBackButton}
                step={step}
                type="experience"
                name={moduleDetails.name}
                handleDeleteStep={handleDeleteStep}
                handleOnChange={handleInputField}
                typeName={deleteModuleName} />
        </>
    );
}

export default ModuleDetails;