import React from "react";
import { Modal } from "react-bootstrap";
import CustomButton from "../Inputs/Button";
import Checkbox from "../Inputs/Checkbox";

const TermsAndConditions = (props) => {
    const [show, setShow] = React.useState(false);

    const handleAccept = () => {
        props.setChecked(true);
        setShow(false);
    };

    const handleDecline = () => {
        props.setChecked(false);
        setShow(false);
    };

    const handleCheck = () => {
        if (props.checked) {
            props.setChecked(false);
        } else {
            props.setChecked(true);
        }
    };


    return (
        <>
            <div className="terms-conditions form-group d-flex">
                <Checkbox
                    type="checkbox"
                    value={props.checked}
                    checked={props.checked}
                    onChange={handleCheck}
                    label={
                        <label className="d-flex">
                            I agree to the&nbsp;<div className="u-line pointer" onClick={() => setShow(true)} >Terms and Conditions</div>
                        </label>
                    }
                />

            </div>

            <Modal
                show={show}
                fullscreen={true}
                animation={false}
            >
                <Modal.Body className="d-flex justify-content-center align-items-center">
                    <div className="term-cond-container">
                        <h1 className="title header-color">Terms and Conditions</h1>
                        <div className="term-body">
                            <p ></p>
                            <p ><strong><span >1. Introduction</span></strong></p>

                            <p ><strong><span>a.</span></strong><span >&nbsp;The SweetRush<sup>&reg;</sup> VR Experience Platform<sup>TM</sup> is a proprietary suite of APIs, tools, plugins, code, technologies, graphical user interfaces (GUI), content, analytics, and services (the &ldquo;<strong>Platform</strong>&rdquo;) that enables our customers to upload, test, manage, distribute, analyze, and retrieve data from virtual reality experiences.</span></p>

                            <p ><strong><span >b.</span></strong><span >&nbsp;To use Platform (including to process any Platform data), you agree to these Terms and Conditions (collectively, the &ldquo;<strong>Terms</strong>&rdquo;).</span></p>

                            <p ><strong><span >c.</span></strong><span >&nbsp;You are subject to these Terms as soon as you start accessing or using our Platform and you will continue to be subject to these Terms as long as you have access to this Platform, unless ended earlier as described below in <span >Section 7.</span>&nbsp;</span></p>

                            <p ><strong><span >d.</span></strong><span >&nbsp;If you are accepting these Terms or accessing or using Platform on behalf of an entity, you represent and warrant that you have the authority to bind such entity to these Terms and you agree on behalf of such entity to be bound by these Terms (and for clarity, all other references to &ldquo;you&rdquo; in these Terms refer to such entity).</span></p>

                            <p ><strong><span >e.</span></strong><span >&nbsp;If you fail to comply with these Terms or any other applicable terms or policies, we may suspend or terminate your account as described below in <span >Section 7</span>.</span></p>
                            <p ><strong><span >&nbsp;</span></strong></p>
                            <p ><strong><span >2. Intellectual Property Rights</span></strong></p>
                            <p ><strong><span >&nbsp;</span></strong></p>
                            <p ><strong><span >a. Our License to You. &nbsp;</span></strong><span >Subject to your compliance with these Terms and all other applicable terms and policies, we grant you a limited, non-exclusive, non-sublicensable (except to Third Party Providers as described below), non-transferable, non-assignable license to use and access the Platform, but only to the extent permitted in these Terms and all other applicable terms and policies. You will not sell, transfer, or sublicense Platform to anyone. Except as expressly licensed herein, you will not use, access, integrate to or with, modify, translate, create derivative works of, reverse engineer, or otherwise exploit the Platform or any aspect thereof. SweetRush reserves all right, title, and interest in and to the Platform (including the right to enforce any such rights) not expressly granted in these Terms.</span></p>
                            <p ><strong><span >&nbsp;</span></strong></p>
                            <p ><strong><span >b. Your License to Us</span></strong><span >. &nbsp;You grant us a non-exclusive, transferable, sublicensable, royalty-free, worldwide license to: host, use, distribute, modify, run, copy, publicly perform or display, translate, and create derivative works of any virtual reality experiences, information, data, and other content made available by you or on your behalf (including by your Third Party Providers or your VR App) in connection with Platform (collectively, &ldquo;<strong>Your Content</strong>&rdquo;) for any business purpose in connection with operating, providing, or improving the Platform. This license remains in effect even if you stop using Platform.</span></p>

                            <p><strong><span >c. Protecting the Rights of Others</span></strong><span >. &nbsp;You will not provide or promote any part of VR App or Your Content that infringes upon or otherwise violates the rights of any person or third party and hereby agree to obtain (and represent and warrant that you own or have secured) all rights necessary from all applicable rights holders to (1) grant the licenses, rights, and permissions in these Terms (including those in <span >Section 2.b</span> (&ldquo;Your License to Us&rdquo;)) to display, distribute, and deliver all information, data, and other content in Your Content. This includes satisfying all licensing, reporting, and payout obligations to third parties.</span></p>

                            <p ><span >If your VR App contains content submitted or provided by your Users or other third parties, you must have an appropriate notice and takedown process and otherwise comply with all applicable laws and regulations to respond to notices of claimed infringement. Without limiting that compliance, in the United States, you must comply with all requirements of the Digital Millennium Copyright Act.</span></p>
                            <p ><strong><span >&nbsp;</span></strong></p>
                            <p ><strong><span >3. Data Use</span></strong></p>
                            <p ><span ><br /> <strong>a. Prohibited Practices.</strong> You will not and must make reasonable efforts to ensure your Third Party Providers do not:</span></p>

                            <ul >
                                <li><span >Perform, or facilitate or support others in performing in using any of Your Content to discriminate or encourage discrimination against people based on personal attributes including race, ethnicity, color, national origin, religion, age, sex, sexual orientation, gender identity, family status, disability, medical or genetic condition, or any other categories prohibited by applicable law, or regulation;</span></li>
                                <li><span >Sell, license, or otherwise provide Platform Data or place Platform Data on a publicly accessible search engine or directory without our prior express written consent; or</span></li>
                                <li><span >Attempt to decode, circumvent, re-identify, de-anonymize, unscramble, unencrypt, or reverse hash, or reverse-engineer the Platform or any of the Platform Data provided to you.</span></li>
                            </ul>
                            <p ><span ><br /> <strong>b. Retention of Platform Data. &nbsp;</strong>If you are required to keep Platform Data under applicable law or regulation, you must retain proof of the applicable legal or regulatory requirement or request and provide it if we ask for it. &nbsp;If you have received Platform Data in error, you must immediately report this to us, delete that Platform Data, and provide proof of deletion if we ask for it.</span></p>

                            <p ><strong><span >4. Privacy Policy</span></strong></p>
                            <p ><span ><br /> <strong>a.</strong> If you use Platform to Process Platform Data, you will provide and comply with a publicly available and easily accessible privacy policy in your VR App. This policy must comply with applicable law and regulations and must accurately and clearly explain what data you are Processing, how you are Processing it, the purposes for which you are Processing it, and how Users may request deletion of that data.</span></p>

                            <p ><strong><span >b.</span></strong><span >&nbsp;Your privacy policy will not supersede, modify, or be inconsistent with these Terms or any other applicable terms or policies and you must retain all of your privacy policies in effect while using Platform and provide them to us if we ask for them.</span></p>

                            <p ><strong><span >5. Third Party Providers and Tech Providers</span></strong></p>
                            <p ><span ><br /> <strong>a. Third Party Providers</strong></span></p>
                            <ul >
                                <li><span >You will not use a Third Party Provider in connection with your use of Platform or Processing of Platform Data unless such Third Party Provider first agrees in writing to use Platform and Process Platform Data solely for you and at your direction in order to provide services you requested in a manner that is consistent with these Terms, all other applicable terms and policies, and your privacy policy, and for no other individual or entity and for no other purpose, including for the Third Party Provider&rsquo;s own purposes.</span></li>
                                <li><span >You must ensure that any Third Party Provider complies with these Terms and all other applicable terms and policies as if they were in your place, and you are responsible for their acts and omissions, including their noncompliance.</span></li>
                            </ul>

                            <ul >
                                <li><span >When you cease using a Third Party Provider, you must ensure they immediately cease using Platform and Processing Platform Data and promptly delete all Platform Data in their possession or control.</span></li>
                                <li><span >Upon our request, you must provide a list of your Third Party Providers including up-to-date contact information for each and proof of written agreements with your Third Party Providers to demonstrate compliance with this Section.</span></li>
                                <li><span >We may prohibit your use of any Third Party Provider in connection with your use of Platform or Processing of Platform Data if we believe that (1) they have violated these Terms or other applicable terms or policies or (2) they are negatively impacting Platform and will provide notice to you if we do. Promptly upon such notice, you must stop using that Third Party Provider Party Provider in connection with your use of Platform or Processing of Platform Data.</span></li>
                            </ul>
                            <p ><span ><br /> <strong>6. Data Security</strong></span></p>

                            <p ><strong><span >a. Data Security Requirements</span></strong><span >. &nbsp;</span></p>
                            <ul >
                                <li><span >You must always have in effect and maintain administrative, physical, and technical safeguards that do the following:</span>
                                    <ol >
                                        <li><span >Meet or exceed industry standards given the sensitivity of the Platform Data;</span></li>
                                        <li><span >Comply with applicable law and regulations, including data security and privacy laws, rules, and regulations; and</span></li>
                                        <li><span >Are designed to prevent any unauthorized (including in violation of these Terms or any other applicable terms or policies) Processing (including, for the avoidance of doubt, access, destruction, loss, alteration, disclosure, distribution, or compromise) of Platform Data.</span></li>
                                    </ol>
                                </li>
                                <li><span >You must have a publicly available way for people to report security vulnerabilities in your VR App to you, and you must promptly address identified deficiencies.</span></li>
                                <li><span >You must not transfer or share user IDs, passwords, or your access token and secret key, except with a Third Party Provider who helps you build, run, or operate your VR App.</span></li>
                            </ul>
                            <p ><strong><span >b. Incident Reporting</span></strong><span >. &nbsp;</span></p>

                            <ul >
                                <li><span >If any of the following incidents happen, you must promptly, and no later than 24 hours after you become aware of the incident, notify us immediately at <span >________@sweetrush.com</span> and provide us with information we request regarding:</span>
                                    <ol >
                                        <li><span >Any unauthorized (including in violation of these Terms or any other applicable terms or policies) Processing (including, for the avoidance of doubt, access, destruction, loss, alteration, disclosure, distribution or compromise) of Platform Data; or</span></li>
                                        <li><span >Any incidents that are reasonably likely to compromise the security, confidentiality, or integrity of your IT Systems or your Third Party Provider&rsquo;s or IT Systems.</span></li>
                                    </ol>
                                </li>
                                <li><span >You must immediately begin remediation of the incident and reasonably cooperate with us, including by informing us in reasonable detail of the impact of the incident upon Platform Data and corrective actions being taken, and keeping us updated about your compliance with any notification or other requirements under applicable laws and regulations.</span></li>
                            </ul>


                            <p ><strong><span >7. Compliance Review Rights and Suspension and Termination of these Terms</span></strong></p>

                            <p ><strong><span >a. VR App Review.</span></strong><span >&nbsp;We may require that you submit your VR App for our review or approval (&ldquo;<strong>VR App Review</strong>&rdquo;). Whether or not your VR App (including its access to any Platform Data) is approved (which will be in our sole discretion), you will ensure that your VR App is compliant with these Terms and all other applicable terms and policies, and we may review your VR App for such compliance from time to time, in our sole discretion. You will cooperate with our reviews and provide any information we request. We may verify information you provide to us during any such reviews or otherwise in your VR App dashboard, which you will update to keep it complete and accurate.</span></p>

                            <p ><strong><span >b. Regular Monitoring.</span></strong><span >&nbsp;We, or third-party professionals working at our direction (including auditors, attorneys, consultants, and/or computer forensics analysts) (collectively, &ldquo;<strong>Third-Party Auditors</strong>&rdquo;), may conduct regular monitoring of your VR App and its access to Platform and Processing of Platform Data using technical and operational measures.</span></p>

                            <p ><strong><span >c. Suspension and Termination</span></strong></p>

                            <ul >
                                <li><span >We may take enforcement action against you and your VR App if we believe, in our sole discretion, that:</span>
                                    <ol >
                                        <li><span >You have not timely responded to our requests related to monitoring or auditing;</span></li>
                                        <li><span >You or your VR App has violated or may have violated these Terms or any other applicable terms or policies or is negatively impacting Platform or Platform Data;</span></li>
                                    </ol>
                                </li>
                                <li><span >It is needed to comply with applicable laws or regulations or otherwise required or requested by a court order or governmental authority; or</span></li>
                                <li><span >It is needed to protect SweetRush from legal or regulatory liability.</span></li>
                                <li><span >We may take enforcement action at any time, including while we investigate your VR App, with or without notice to you. Enforcement can be both automated and manual. It can include suspending or removing your VR App, removing your access and your VR App&rsquo;s access to Platform, requiring that you stop Processing and delete Platform Data, terminating our agreements with you, or any other action that we consider to be appropriate, including terminating other agreements with you or your ability to use the Platform.</span></li>
                                <li><span >We may suspend or end your VR App&rsquo;s access to any Platform APIs, permissions, or features that your VR App has not used or accessed within a 90-day period with or without notice to you.</span></li>
                            </ul>
                            <p ><strong><span >8. Notice.&nbsp;</span></strong><span >After you agree to these Terms, any written notice, request, or communications from us to you may be provided via email or mail (for example, to the email address or mailing address in your VR App account with us). You will keep your contact information current, including name, business name, and email.</span></p>
                            <p ><strong><span >&nbsp;</span></strong></p>
                            <p ><strong><span >9. Indemnification. &nbsp;</span></strong><span >If anyone brings a claim, cause of action, or dispute against SweetRush&nbsp;related to your use of Platform, your Processing of Platform Data, Your Content, or your VR App, name or logo, products or services, or actions in connection with Platform, you will indemnify and hold SweetRush&nbsp;harmless from and against all damages, losses, and expenses of any kind (including reasonable legal fees and costs) related to any such claim, cause of action, or dispute.</span></p>
                            <p ><strong><span >&nbsp;</span></strong></p>
                            <p ><strong><span >10. General</span></strong></p>
                            <p ><span >a. You will not transfer any of your rights or obligations under these Terms to anyone else without our prior written consent. Transferring can include assignment, acquisition, merger, change of control, or other forms of transfer. Any unpermitted transfer will be considered null and void. For any permitted transfer, you can continue to Process Platform Data only for your VR App subject to these Terms and only after you re-submit your VR App and receive our approval through our VR App Review process.</span></p>

                            <p ><span >b. You also must comply with all applicable laws and regulations (including the Children&rsquo;s Online Privacy Protection Act (&ldquo;<strong>COPPA</strong>&rdquo;) and the Video Privacy Protection Act (&ldquo;<strong>VPPA</strong>&rdquo;)).</span></p>

                            <p ><span >c. If there is any conflict between these Terms and any other applicable online terms, the terms that are more restrictive on you and your VR App or more protective of us apply.</span></p>

                            <p ><span >d. We reserve the right to amend these Terms at any time. Your continued use of or access to Platform after any such amendment will constitute your binding agreement to these Terms as amended.</span></p>

                            <p ><span >e. We may change, suspend, or discontinue the availability of Platform at any time. In addition, we may impose limits on certain features and services or restrict your access to parts or all of our APIs or websites without notice or liability.</span></p>

                            <p ><span >f. If we elect to provide you with support or modifications for Platform, we may discontinue either at any time without notice to you.</span></p>

                            <p ><span >h. We can issue a press release or otherwise make public statements or disclosures describing our relationship with you or your use of Platform.</span></p>

                            <p ><span >i. When these Terms have ended, all rights granted to you under these Terms will immediately stop and you will immediately stop using Platform. <span >The following Sections will remain in effect after these Terms have ended: Section 2.b, Section 2.c, Section 3, Section 4, Section 5, Section 6, Section 7, Section 9, Section 10, Section 11, and Section 12.</span></span></p>
                            <p ><strong><span >&nbsp;</span></strong></p>
                            <p ><strong><span >12. Glossary</span></strong></p>

                            <p ><span >&ldquo;<strong>Audit</strong>&rdquo; means a review, inspection, or audit of your and your Third Party Providers&rsquo; IT Systems or Records.</span></p>

                            <p ><span >&ldquo;<strong>Client</strong>&rdquo; means the User of a Tech Provider&rsquo;s VR App.</span></p>

                            <p ><span >&ldquo;<strong>Developer</strong>&rdquo; means the person or entity that creates or operates an VR App.</span></p>

                            <p ><span >&ldquo;<strong>Developer Docs</strong>&rdquo; has the meaning given in <span >Section 1.c</span> (&ldquo;Introduction&rdquo;).</span></p>

                            <p ><span>&ldquo;<strong>IT Systems</strong>&rdquo; means information technology systems (real and virtual), networks, technologies, and facilities (including physical and remote access to data centers and cloud facilities) that Process Platform Data.</span></p>

                            <p ><span >&ldquo;<strong>Necessary Condition</strong>&rdquo; means any of the following:</span></p>
                            <ul >
                                <li><span >it is required by applicable law, rule, or regulation or otherwise required or requested by a court order or governmental authority;</span></li>
                                <li><span >we suspect that you or your VR App have Processed Platform Data in violation of these Terms or other applicable terms or policies;</span></li>
                                <li><span >you enter into a change of control transaction or transfer (or request to transfer) any of your rights or obligations under these Terms or other applicable terms or policies;</span></li>
                                <li><span >we determine in our sole discretion it is necessary to ensure that you and your VR App have deleted Platform Data in accordance with these Terms and all other applicable terms and policies; or</span></li>
                                <li><span >we determine in our sole discretion it is necessary to ensure proper remediation of any non-compliance revealed by an Audit.</span></li>
                            </ul>
                            <p >&ldquo;<strong>Platform</strong>&rdquo; <span> means the set of APIs, SDKs, tools, plugins, code, technology, content, and services that enables others, including app developers and website operators, to develop functionality, retrieve data from SweetRush, or provide data to us.</span></p>

                            <p ><span >&ldquo;<strong>Platform Data</strong>&rdquo; means any information, data, or other content you obtain from us, through Platform or through your VR App, whether directly or indirectly and whether before, on, or after the date you agree to these Terms, including data anonymized, aggregated, or derived from such data. Platform Data includes app tokens, page tokens, access tokens, app secrets, and user tokens.</span></p>

                            <p ><span >&ldquo;<strong>Process</strong>&rdquo; means any operation or set of operations performed on data or sets of data, whether or not by automated means, including use, collection, storage, sharing, or transmission.</span></p>

                            <p ><span >&ldquo;<strong>Prohibited Practices</strong>&rdquo; has the meaning given in <span >Section 3.a</span> (&ldquo;Prohibited Practices&rdquo;).</span></p>

                            <p ><span >&quot;<strong>Records</strong>&rdquo; mean books, agreements, access logs, third-party reports, policies, processes, and other records regarding the Processing of Platform Data.</span></p>

                            <p ><span >&ldquo;<strong>Restricted Platform Data</strong>&rdquo; means Platform Data that (i) reasonably can be used to identify a particular User or device; (ii) is accessed by way of the permitted uses included here in these Terms; or (iii) we otherwise designate as Restricted.&nbsp;</span></p>

                            <p ><span >&ldquo;<strong>SDKs</strong>&rdquo; means any object code, source code, or documentation you receive from us that helps you create VR Apps or content for use with the Platform.</span></p>

                            <p ><span >&ldquo;<strong>Third Party Provider</strong>&rdquo; means an entity you use to provide you services in connection with Platform or any Platform Data.</span></p>

                            <p ><span >&ldquo;<strong>Tech Provider</strong>&rdquo; means a Developer of an VR App whose primary purpose is to enable Users thereof to access and use Platform or Platform Data.</span></p>

                            <p ><span >&ldquo;<strong>Third-Party Auditors</strong>&rdquo; has the meaning given in <span >Section 7.b</span> (&ldquo;Regular Monitoring&rdquo;).</span></p>

                            <p ><span >&ldquo;<strong>User</strong>&rdquo; means the end user of an VR App (whether a person or an entity).</span></p>

                            <p ><span >&ldquo;<strong>VR App</strong>&rdquo; means any technical integration with Platform or to which we have assigned an VR App identification number. Any code, APIs, SDKs, tools, plugins, bots, websites, applications, specifications, and other technology made available by you or on your behalf in connection with Platform is considered part of your VR App.</span></p>

                            <p ><span >&ldquo;<strong>Your Content</strong>&rdquo; has the meaning given in <span >Section 2.b</span> (&ldquo;Your License to Us&rdquo;).</span></p>
                            <div ><br />
                                <div id="ftn1" >
                                    <p ><a href="#_ftnref1" target="_blank" name="_ftn1" title=""><sup><sup><span >[1]</span></sup></sup></a><span >&nbsp;</span><a target="_blank" href="https://docs.google.com/document/u/1/d/1nsXeghY5_c16M96WPUB0yl9JcJYzl80C/edit"><span >SR VR Experience Platform T&amp;C Outline.docx</span></a></p>
                                </div>
                            </div>
                        </div>
                        <div className="button-wrapper w-100">
                            <CustomButton variant="outline-secondary" className="w-auto text-normal" onClick={handleDecline}>
                                Don’t Agree
                            </CustomButton>
                            <CustomButton type="submit" className="w-auto px-5" onClick={handleAccept}>Agree</CustomButton>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default TermsAndConditions;