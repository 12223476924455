import React, { useContext, useEffect, useRef, useState } from "react";
import { courGet, courPut } from "../../service/courAPI";
import UserContext from "../../UserContext";
import { API_ENDPOINT } from "../literals";
import { Text } from "../Constant/string";
import { Dropdown, Modal } from "react-bootstrap";
import CustomDropdown from "../Inputs/CustomDropdown";
import CustomDropdownMenu from "../Inputs/customDropdownMenu";
import { Constant } from "../Constant/constant";
import ProfileAppearence from "../shared/ProfileAppearence";
import "./OrganizationProfile.css";
import InputBox from "../Inputs/InputBox";
import FileUpload from "../Inputs/FileUpload";
import axios, { CancelToken } from "axios";
import { v4 as uuidv4 } from 'uuid';
import { inputError } from "../Constant/errors";
import CustomButton from "../Inputs/Button";
import { IoIosWarning } from "react-icons/io";
import { RiDeleteBin6Line } from "react-icons/ri";
import { BackButton } from "../Course/BackButton";
import { useNavigate } from "react-router-dom";
import deleteIcon from '../../images/deleteIcon.svg';

const OrganizationProfile = () => {
    const navigate = useNavigate();
    const userContext = useContext(UserContext);
    const [orgDetails, setOrgDetails] = useState([]);
    const [orgName, setOrgName] = useState("");
    const [primaryColor, setPrimaryColor] = useState("");
    const [secondaryColor, setSecondaryColor] = useState("");
    const [thumbnailUrl, setThumbnailUrl] = useState('');
    const [file, setFile] = useState('');
    const cancelFileUpload = useRef(null);
    const [disable, setDisable] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [platformName, setPlatformName] = useState("");
    const [platformUrl, setPlatformUrl] = useState("");
    const [platformKey, setPlatformKey] = useState("");

    useEffect(() => {
        userContext.setThumbnail({});
        userContext.setUserMenu([
            {
                label: `${Text.course}`,
                to: '/client/' + userContext.selectedClient + '/courses',
            },
            {
                label: 'Admins',
                to: '/client/' + userContext.selectedClient + '/users',
            },
            {
                label: 'Profile',
                to: '/organization-details',
            }
        ]);
        userContext.setBackUrl([
            {
                label: "All Organizations",
                url: '/clients'
            },
            {
                label: userContext.selectedClientName,
                url: '/client/' + userContext.selectedClient + '/courses'
            }
        ]);
        getOrgDetails();
    }, [userContext.token]);

    const getOrgDetails = () => {
        userContext.selectedClient &&
            courGet(API_ENDPOINT.GetOrganizationDetails(userContext.selectedClient))
                .then((res) => {
                    courGet(API_ENDPOINT.getPreSignedGetUrl(res.data.thumbnail))
                        .then((response) => {
                            userContext.setThumbnail({ path: response.data, name: '' });
                        }).catch((error) => {
                            //  console.log(error);
                        })
                    setPrimaryColor(res.data.appearance.primary);
                    setSecondaryColor(res.data.appearance.secondary);
                    setOrgName(res.data.name);
                    setOrgDetails(res.data);
                    setPlatformName(res.data.platformName);
                    setPlatformUrl(res.data.platformUrl);
                    setPlatformKey(res.data.platformKey);

                })
                .catch((err) => {
                    console.log(err);
                });
    }

    const getColorCode = (type, color) => {
        if (type === "Primary") {
            setPrimaryColor(color);
        }

        if (type === "Secondary") {
            setSecondaryColor(color);
        }
    }

    const handleError = (err, title) => {
        if (err.code === Constant.ERROR.network) {
            userContext.createToast({ title: "internal error", message: inputError.internalError, variant: "danger" });

        }
        else {
            userContext.createToast({ title: title, message: err.response.data.message, variant: "danger" });
        }
    }

    const uploadFile = (fileData, type) => {
        if (fileData) {
            const fileName = uuidv4() + '-' + fileData.name.toLowerCase().split(' ').join('');
            setFile(fileName);
            setDisable(true);
            const formData = new FormData();
            formData.append('file', fileData, fileName);

            const file = new File([fileData], fileName);

            courGet(API_ENDPOINT.getPreSignedPutUrl(fileName))
                .then((res) => {
                    if (res.data) {
                        const fileExt = fileName.split('.')[1];
                        axios.put(res.data, file,
                            {
                                headers: { 'Content-Type': 'image/' + fileExt },
                                cancelToken: new CancelToken(
                                    cancel => (cancelFileUpload.current = cancel)
                                ),
                            }).then((response) => {
                                if (response) {
                                    setDisable(false);
                                    setThumbnailUrl(URL.createObjectURL(fileData));
                                    if (response.data.launchURL) {
                                        setOrgDetails((prevState) => ({
                                            ...prevState,
                                            launchURL: fileName.split('.')[0] + "/" + response.data.launchURL
                                        }));

                                    }
                                }
                            }).catch(error => {
                                setFile('');
                                setDisable(false);
                                console.log(error);
                                handleError(error, 'Upload file');
                            });
                    }
                })
        }
    }

    useEffect(() => {
        if (orgDetails.thumbnail) {
            courGet(API_ENDPOINT.getPreSignedGetUrl(orgDetails.thumbnail))
                .then((res) => {
                    setThumbnailUrl(res.data);
                }).catch((error) => {
                    console.log(error);
                })
        }
    }, [orgDetails])

    const handleSubmit = (e) => {
        e.preventDefault();
        let data = {
            _id: orgDetails._id,
            name: orgName,
            appearance: {
                primary: primaryColor,
                secondary: secondaryColor
            },
            thumbnail: file ? file : orgDetails.thumbnail,
            platformName: platformName,
            platformUrl: platformUrl,
            platformKey: platformKey
        }

        courPut(API_ENDPOINT.UpdateOrganization, data)
            .then((res) => {
                courGet(API_ENDPOINT.getPreSignedGetUrl(res.data.thumbnail))
                    .then((response) => {
                        userContext.setThumbnail({ path: response.data, name: '' });
                    }).catch((error) => {
                        //  console.log(error);
                    })
                setPrimaryColor(res.data.appearance.primary);
                setSecondaryColor(res.data.appearance.secondary);
                setOrgName(res.data.name)
                setOrgDetails(res.data);
                setPlatformName(res.data.platformName);
                setPlatformUrl(res.data.platformUrl);
                setPlatformKey(res.data.platformKey);

                userContext.createToast({ title: "Organization successfully updated", message: "Organization successfully updated.", variant: "Success" });
            })
            .catch((err) => {
                console.log(err);
            });


    }

    const handleDelete = () => {
        let data = {
            _id: userContext.selectedClient,
            isActive: false
        }
        courPut(API_ENDPOINT.DeleteOrganization, data)
            .then((res) => {
                userContext.createToast({ title: "Organization successfully deleted", message: "Organization successfully deleted.", variant: "Success" });
                navigate('/clients');
            })
            .catch((err) => {
                console.log(err);
            });

    }
    return <>
        <div className="dashboard">
            <form onSubmit={e => handleSubmit(e)}>
                <div className="d-flex justify-content-between">
                    <h1 className='title'>{orgDetails.name}</h1>
                    {userContext.role !== Constant.ROLE.partner &&
                        <Dropdown align="end">
                            <Dropdown.Toggle as={CustomDropdown} id="dropdown-custom-components">
                            </Dropdown.Toggle>

                            <Dropdown.Menu as={CustomDropdownMenu}>
                                <Dropdown.Item eventKey="1" className="dropDown-menu-text" onClick={() => { setShowDeleteModal(true) }}><RiDeleteBin6Line className="icon-dropdown" />Delete</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    }
                </div>
                <div className="member-list course-description mb-5">
                    Update your logo and details here.
                </div>

                <div className="profile-container">
                    <div className="org-profile-header org-profile-header-width">
                        Organization Name
                    </div>
                    <InputBox
                        id='orgName'
                        name="name"
                        value={orgName}
                        onChange={(e) => setOrgName(e.target.value)}
                        maxlength="20"
                        required />
                </div>
                <hr className="hr-line" />
                <div className="profile-container">
                    <div className="org-profile-header-width">
                        <span className="org-profile-header">Organization Logo</span>
                        <br />
                        <span>Upload an image (i.e., logo) to represent the organization.</span>
                    </div>
                    <div className="edit-image-exp-container ">
                        <img className='thumbnail-img' alt="Profile thumbnail"
                            src={thumbnailUrl} />
                        <div >
                            <FileUpload
                                type='image'
                                uploadFile={uploadFile}
                                aspect={7 / 3} />
                        </div>
                    </div>
                </div>
                <hr className="hr-line" />
                <div className="profile-container">
                    <div className="profile-org-details">
                        <ProfileAppearence sendColorCode={getColorCode} primaryColor={primaryColor} secondaryColor={secondaryColor} />
                    </div>
                </div>
                <hr className="hr-line mt-0" />
                <div className="lti-container">
                    <div className="lti-profile-container mt-4">
                        <h1 className='title'>
                            LTI Configuration
                        </h1>
                        <div className="">
                            Following information will be available on course configuration page of organization.
                        </div>
                    </div>
                    <div className="profile-container">
                        <div className="org-profile-header org-profile-header-width">
                            Platform Name
                        </div>
                        <InputBox
                            value={platformName}
                            onChange={(e) => setPlatformName(e.target.value)}
                            maxlength="250"
                            className="lti-input-box"
                            required />
                    </div>

                    <div className="profile-container">
                        <div className="org-profile-header org-profile-header-width">
                            Issuer
                        </div>
                        <InputBox
                            value={platformUrl}
                            onChange={(e) => setPlatformUrl(e.target.value)}
                            maxlength="250"
                            className="lti-input-box"
                            required />
                    </div>

                    <div className="profile-container">
                        <div className="org-profile-header org-profile-header-width">
                            Platform OAuth2 Well-Known/KeySet URL
                        </div>
                        <InputBox

                            name="key"
                            value={platformKey}
                            onChange={(e) => setPlatformKey(e.target.value)}
                            maxlength="250"
                            className="lti-input-box"
                            required />
                    </div>

                    <div className="button-wrapper">
                        <CustomButton
                            type="submit"
                            className={'w-auto px-5'}
                        >Update</CustomButton>
                    </div>
                </div>
            </form>
        </div>

        <Modal show={showDeleteModal} fullscreen={true}>
            <Modal.Body className="invite-owner">
                <div className='delete-div'>
                    <div className="delete-logo-container">
                        <img className='thumbnail-img' src={thumbnailUrl} alt="profile thumbnail" />
                        <div className="delete-org-heading">{orgDetails.name}</div>
                    </div>
                    <div className="div-flex-model">
                        <img src={deleteIcon} className="" />
                        <h1 className="text-header content-div header-color">
                            Delete Organization
                        </h1>
                    </div>
                    <>
                        <span className="version-title delete-subText">Are you sure you want to delete this organization?</span>
                        <div className="version-item warning-bg warning-delete">
                            <div>
                                <div className="warning-div">
                                    <IoIosWarning className="warning-icon" />
                                    <span className="delete-subText-warning">Warning</span>
                                </div>
                                <span className="warningText">This will delete all courses and admins associated with this organization. <b>This action cannot be undone.</b></span>
                            </div>
                        </div>
                    </>

                    <BackButton handleBackButton={() => { setShowDeleteModal(false) }}
                        handleOnClick={handleDelete}
                        classes={disable ? 'disabled' : ''}
                        buttonName={disable ? 'Processing...' : 'Yes, Delete'} />
                </div>
            </Modal.Body>
        </Modal>
    </>
}

export default OrganizationProfile;