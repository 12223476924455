import { useContext, useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import UserContext from "../../UserContext";
import FileUpload from "../Inputs/FileUpload";
import InputBox from "../Inputs/InputBox";
import ClientItem from "../shared/ClientItem";
import { API_ENDPOINT } from "../literals";
import { courDelete, courGet, courPost } from "../../service/courAPI";
import { Text } from "../Constant/string";
import ProfileAppearence from "../shared/ProfileAppearence";
import axios, { CancelToken } from "axios";
import { v4 as uuidv4 } from 'uuid';
import LinearProgressBar from "../Inputs/LinearProgressBar";
import { BackButton } from "../Course/BackButton";


const AllClients = (props) => {
    const userContext = useContext(UserContext);
    const navigate = useNavigate();

    const [clientList, setClientList] = useState([]);
    const [show, setShow] = useState(false);
    const [clientName, setClientName] = useState("");
    const [primaryColor, setPrimaryColor] = useState("#415464");
    const [secondaryColor, setSecondaryColor] = useState("#415464");
    const cancelFileUpload = useRef(null);
    const [file, setFile] = useState('');
    const [fileLoading, setFileLoading] = useState({ "percent": 0, "total": 0, "loaded": 0 });
    const [disable, setDisable] = useState(false);

    useEffect(() => {
        if (!show) {
            setClientName("");
        }
    }, [show])

    useEffect(() => {
        userContext.setThumbnail({});
        userContext.setUserMenu([
            {
                label: `${Text.allClients}`,
                to: '/clients',
            },
            {
                label: 'Platform Admins',
                to: '/platform-admins',
            }
        ]);
        userContext.setBackUrl([]);
        getAllOrganization();
    }, [userContext.token]);

    const onClickClient = (client) => {
        userContext.setSelectedClient(client._id);
        userContext.setSelectedClientName(client.name);
        navigate('/client/' + client._id + '/courses');
    }

    const getAllOrganization = () => {
        courGet(API_ENDPOINT.GetAllOrganization)
            .then((res) => {
                setClientList(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const addClient = (e) => {
        e.preventDefault();
        let data = {
            name: clientName,
            createdBy: userContext._id,
            appearance: {
                'primary': primaryColor,
                'secondary': secondaryColor
            },
            thumbnail: file,
            isActive: true

        }
        courPost(API_ENDPOINT.CreateOrganization, data)
            .then((res) => {
                getAllOrganization();
                userContext.createToast({ title: "Organization Added", message: "Organization Added.", variant: "Success" });
                clearStorage();
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const getColorCode = (type, color) => {
        if (type === "Primary") {
            setPrimaryColor(color);
        }

        if (type === "Secondary") {
            setSecondaryColor(color);
        }
    }

    const removeFile = (fileName) => {
        courDelete(API_ENDPOINT.ImageFileDelete(fileName))
            .then(res => {
                setFile('');
                setFileLoading({ "percent": 0, "total": 0, "loaded": 0 })

            }).catch(error => {
                userContext.createToast({ title: "File Delete Error", message: "Not able to delete file", variant: "danger" });
            })
    };

    const cancelUpload = (filename) => {
        removeFile(filename);
        if (cancelFileUpload.current) {
            cancelFileUpload.current("User has canceled the file upload.");
        }
    };

    const uploadFile = (fileData, type) => {
        if (fileData) {
            const fileName = uuidv4() + '-' + fileData.name.toLowerCase().split(' ').join('');
            setFile(fileName);
            const formData = new FormData();
            formData.append('file', fileData, fileName);
            const file = new File([fileData], fileName);

            courGet(API_ENDPOINT.getPreSignedPutUrl(fileName))
                .then((res) => {
                    if (res.data) {
                        const fileExt = fileName.split('.')[1];
                        axios.put(res.data, file,
                            {
                                headers: { 'Content-Type': 'image/' + fileExt },
                                onUploadProgress: (event) => {
                                    let percent = Math.floor((100 * event.loaded) / event.total);
                                    setFileLoading({ "percent": percent, "total": event.total, "loaded": event.loaded })
                                },
                                cancelToken: new CancelToken(
                                    cancel => (cancelFileUpload.current = cancel)
                                ),
                            }
                        ).catch(error => {
                            userContext.createToast({ title: "File Upload Error", message: "File could not be uploaded for some reason.", variant: "danger" });
                            setFile('');
                            setFileLoading({ "percent": 0, "total": 0, "loaded": 0 });
                            console.log(error);
                        });
                    }
                })
        }
    }

    const ProgressBar = ({ ...props }) => {
        return <LinearProgressBar {...props} />
    }

    const UploadThumbnail = () => {
        return <FileUpload
            type='image'
            text="Upload a Logo"
            uploadFile={uploadFile}
            aspect={7 / 3}
            subText="Upload an image (i.e., logo) to represent the organization." />
    }

    function handleBackButton() {
        file && removeFile(file);
        clearStorage();
    }

    const clearStorage = () => {
        setShow(false);
        setFileLoading({ "percent": 0, "total": 0, "loaded": 0 });
        setFile('');
        setDisable(false);
        setPrimaryColor("#415464");
        setSecondaryColor("#415464");
    }

    return <>
        <div className="organization-owners">
            <h1 className='title header-color'>{Text.allClients}</h1>
            <div className="member-list">
                {clientList.map((client) => {
                    return (<ClientItem client={client} key={"client" + client._id} onClick={() => onClickClient(client)} />);
                })}

                <div className='add-item add-client' onClick={() => setShow(true)}>
                    <i className='fa fa-plus' />
                </div>
            </div>
        </div>

        <Modal
            show={show}
            fullscreen={true}
            animation={false}
        // onHide={() => setShow(false)}
        >
            <Modal.Body className="invite-owner">
                <form onSubmit={(e) => addClient(e)}>
                    <h1 className="title header-color text-center">Add New Organization</h1>
                    <InputBox
                        label="Organization Name"
                        value={clientName}
                        onChange={(e) => setClientName(e.target.value)}
                        maxlength="20"
                        required
                    />

                    <div className="experience-container">
                        {fileLoading.percent === 0 ? <UploadThumbnail /> :
                            <ProgressBar type='image'
                                uploadedFileName={file}
                                fileName={file && file.substring(37, file.length)}
                                cancelUpload={cancelUpload}
                                removeFile={removeFile}
                                loading={fileLoading} />}
                    </div>

                    <ProfileAppearence sendColorCode={getColorCode}
                        primaryColor={primaryColor} secondaryColor={secondaryColor} />


                    <BackButton handleBackButton={handleBackButton}
                        cancelBtnName='Cancel'
                        classes={(fileLoading.percent === 0 || disable) ? 'disabled' : ''}
                        buttonName={disable ? 'Processing...' : 'Create Organization Profile'} />
                </form>
            </Modal.Body>
        </Modal>
    </>
}

export default AllClients;