import React, { useContext, useEffect } from "react";
import { Modal } from "react-bootstrap";
import UserContext from "../../UserContext";
import CustomButton from "../Inputs/Button";
import { API_ENDPOINT } from "../literals";
import { courGet, courPost } from "../../service/courAPI";
import Autocomplete from "../shared/Autocomplete";
import { Constant } from "../Constant/constant";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";


const InvitePartner = (props) => {
    const userContext = useContext(UserContext);

    const [show, setShow] = React.useState(false);
    const [email, setEmail] = React.useState("");
    const [firstName, setFirstName] = React.useState("");
    const [lastName, setLastName] = React.useState("");
    const [role, setRole] = React.useState(Constant.ROLE.partner);
    const [partnersList, setPartnersList] = React.useState([]);
    const [emailList, setEmailList] = React.useState([]);
    const [isExisting, setIsExisting] = React.useState(false);
    const [emailError, setEmailError] = React.useState(false);
    const [firstNameError, setFirstNameError] = React.useState(false);
    const [lastNameError, setLastNameError] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState("");

    useEffect(() => {
        setRole(Constant.ROLE.partner);
    }, [props])


    useEffect(() => {
        if (userContext.selectedClient) {
            getPartnersList();
        }
    }, [userContext.selectedClient])

    const handleSubmit = (e) => {
        e.preventDefault();
        let emailCheck = false;
        if (email === "") {
            setEmailError(true);
            emailCheck = true;
            setErrorMessage("Please fill out this field.")
        }
        else {
            setEmailError(false);
            emailCheck = false;
        }

        if (
            !new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(email)
        ) {
            setErrorMessage("Please enter valid email.")
            setEmailError(true);
            emailCheck = true;
        }
        else {
            setEmailError(false);
            emailCheck = false;
        }

        if (firstName === "") {
            setFirstNameError(true);
        }
        else {
            setFirstNameError(false);
        }

        if (lastName === "") {
            setLastNameError(true);
        }
        else {
            setFirstNameError(false);
        }

        if (emailCheck || firstName === "" || lastName === "" || errorMessage !== "") {
            return;
        }

        let experiences = []
        var existingUserId = 0;
        partnersList.forEach((element) => {
            if (email === element.email) {
                experiences = element.experience !== "" && element.experience?.length > 0 ? element.experience : []
                existingUserId = element._id
            }
        });

        if (experiences.length === 0) {
            experiences.push(props.experience);
        } else {
            //check if experience already added  
            var checkExperience = false;
            experiences.forEach(element => {
                if (props.experience === element) {
                    checkExperience = true;
                    return
                }
            });

            if (checkExperience === false) {
                experiences.push(props.experience)
            }
        }
        var data = {
            firstName: firstName,
            lastName: lastName,
            email: email,
            inviteBy: userContext.email,
            role: role,
            organization: userContext.selectedClient || "",
            experience: experiences,
            isActive: true
        }

        if (isExisting) {
            data = {
                ...data, _id: existingUserId
            }
        }
        courPost(API_ENDPOINT.addPartner, data)
            .then((res) => {
                if (res.status === 200) {
                    userContext.createToast({ title: "User invited successfully", message: "User invited successfully.", variant: "Success" });
                    // redirect to dashboard
                    setShow(false);
                    props.refreshMembers();
                    getPartnersList();
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const getPartnersList = () => {
        courGet(API_ENDPOINT.GetPartnersByOrganizationId(userContext.selectedClient))
            .then((res) => {
                setPartnersList(res.data);
                let tempUserList = [];
                res.data.forEach(element => {
                    tempUserList.push(element.email);
                });
                setEmailList(tempUserList);
            }).catch((err) => {
                console.log(err);
            });
    }

    const getData = (val) => {
        // do not forget to bind getData in constructor        
        setEmail(val);
        checkExistingUser(val);
        setIsExisting(false);

        partnersList.forEach(element => {
            if (val === element.email) {
                setFirstName(element.firstName);
                setLastName(element.lastName);
                setIsExisting(true);
            }
        });
    }

    const checkExistingUser = (val) => {
        let data = {
            email: val,
            role: role,
            course: props.experience,
            currentOrg: userContext.selectedClient
        }
        courPost(API_ENDPOINT.CheckExistingUser, data)
            .then((res) => {
                if (res.data) {
                    setErrorMessage("User already exists");
                } else {
                    setErrorMessage("");
                }
            })
            .catch((error) => {
                console.log(error)
            });

    }

    const modalShow = () => {
        setEmail("");
        setFirstName("");
        setLastName("");
        setErrorMessage("");
        setEmailError(false);
        setFirstNameError(false);
        setLastNameError(false);
        setShow(true);
    }

    return (
        <><div className="add-item-container">
            <div className='add-item' onClick={() => {
                modalShow()
            }}>
                <i className='fa fa-plus' />
            </div>
        </div>
            <Modal
                show={show}
                fullscreen={true}
                animation={false}
                centered
                size="lg"
            >
                <Modal.Body className="invite-owner">
                    <h1 className="title header-color">Add New Partner</h1>
                    <div className="add-partner-sub-body">Invite a partner to view the analytics for this course. Select their email address from the drop-down menu, or enter a new email address to send an invitation.</div>
                    <form onSubmit={handleSubmit}>
                        <div className="autocomplete-parent">
                            <Autocomplete
                                options={emailList}
                                placeholder="Email"
                                type="Email"
                                sendData={getData}
                                onBlur={() => checkExistingUser(email)}
                                className={`${emailError === true ? 'error ' : ''}`}
                            />
                            {errorMessage !== "" && <div className="error-message" style={{ "marginBottom": "10px" }}>{errorMessage}</div>}
                        </div>

                        <div className="d-flex gap-3 mt-3">
                            <div style={{ "display": "flex", "flexDirection": "column" }}>
                                <input
                                    className={`${firstNameError && firstName === "" ? 'error form-control' : 'form-control'}`}
                                    label="First Name"
                                    placeholder="First Name"
                                    value={firstName}
                                    onChange={(e) => setFirstName(e.target.value)}
                                />
                                {firstNameError && firstName === "" && <div className="error-message" style={{ "marginBottom": "10px" }}>Please fill out this field.</div>}
                            </div>
                            <div style={{ "display": "flex", "flexDirection": "column" }}>
                                <input
                                    label="Last Name"
                                    placeholder="Last Name"
                                    className={`${lastNameError && lastName === "" ? 'error form-control' : 'form-control'}`}
                                    value={lastName}
                                    onChange={(e) => setLastName(e.target.value)}
                                />
                                {lastNameError && lastName === "" && <div className="error-message" style={{ "marginBottom": "10px" }}>Please fill out this field.</div>}
                            </div>
                        </div>

                        <div className="button-wrapper">
                            <CustomButton variant="link-secondary" className="w-auto text-normal back-btn" onClick={() => setShow(false)}>

                                <HiOutlineArrowNarrowLeft className="back-arrow me-2" /><span className="cancelbtn">Cancel</span>

                            </CustomButton>
                            <CustomButton type="submit" className="w-auto px-5">{isExisting ? "Add Partner" : "Send Invitation"}</CustomButton>
                        </div>
                    </form>


                </Modal.Body>
            </Modal>
        </>
    );
};

export default InvitePartner;