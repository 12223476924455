import React, { useCallback, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import CustomButton from './Button';
import Cropper from 'react-easy-crop'
import getCroppedImg from './createImage';
import Form from 'react-bootstrap/Form';
import arrow from '../../images/Arrow.svg'
import { HiOutlineArrowNarrowLeft } from 'react-icons/hi';

const ImageCropDialog = ({ show = false, ...props }) => {

    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [initialCroppedArea, setInitialCroppedArea] = useState(undefined)
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [rotation, setRotation] = useState(0)

    useEffect(() => {
        setZoom(1);
    }, [show])
    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])

    const showCroppedImage = useCallback(async () => {
        try {
            const croppedImage = await getCroppedImg(
                props.file,
                croppedAreaPixels,
                rotation
            )
            props.handleYes(croppedImage);
        } catch (e) {
            console.error(e)
        }
    }, [croppedAreaPixels, rotation])

    const inputElements = document.querySelectorAll('[type="range"]');

    const handleInput = (inputElement) => {
        let isChanging = false;

        const setCSSProperty = () => {
            const percent =
                ((inputElement.value - inputElement.min) /
                    (inputElement.max - inputElement.min)) *
                100;
            // Here comes the magic 🦄🌈
            inputElement.style.setProperty("--webkitProgressPercent", `${percent}%`);
        }

        // Set event listeners
        const handleMove = () => {
            if (!isChanging) return;
            setCSSProperty();
        };
        const handleUpAndLeave = () => isChanging = false;
        const handleDown = () => isChanging = true;

        inputElement.addEventListener("mousemove", handleMove);
        inputElement.addEventListener("mousedown", handleDown);
        inputElement.addEventListener("mouseup", handleUpAndLeave);
        inputElement.addEventListener("mouseleave", handleUpAndLeave);
        inputElement.addEventListener("click", setCSSProperty);

        // Init input
        setCSSProperty();
    }

    inputElements.forEach(handleInput)

    return (
        <Modal
            show={show}
            centered>

            <Modal.Body className="crop-model">
                <div className='crop-title'>{props.title === '' || props.title === undefined ? 'Crop your photo' : props.title}</div>
                <div className="crop-container">
                    <Cropper
                        image={props.file}
                        crop={crop}
                        zoom={zoom}
                        aspect={props.aspect}
                        onCropChange={setCrop}
                        onCropComplete={onCropComplete}
                        onZoomChange={setZoom}
                        zoomSpeed={0.05}
                        maxZoom={5}
                        initialCroppedAreaPercentages={initialCroppedArea}
                    />
                </div>
                <div className="zoom-corp">
                    <input type="range" step={0.05} min={1} max={5} onChange={(e) => { setZoom(e.target.value) }} value={zoom} />
                </div>
                <div className='content-model'>
                    <CustomButton
                        variant="link-secondary"
                        className="w-auto text-normal back-btn"
                        onClick={props.cancel}>
                        <HiOutlineArrowNarrowLeft className="back-arrow me-2" /><span className="cancelbtn">Cancel</span>
                    </CustomButton>
                    <CustomButton type="submit" className="w-auto px-5" onClick={showCroppedImage}>Save Changes </CustomButton>

                </div>
            </Modal.Body>
        </Modal>
    );
}

export default ImageCropDialog