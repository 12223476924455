import { createContext } from 'react'

const UserContext = createContext({
    userId: null,
    firstName: null,
    lastName: null,
    email: null,
    role: null,
    login: () => { },
    logout: () => { }

});

export default UserContext