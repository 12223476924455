import React, { useEffect, useRef } from "react";

const Checkbox = ({ label, value, onChange, id = "checkbox", ...props }) => {
    const inputEl = useRef(null);
    const [error, setError] = React.useState(Boolean(props.error));
    const [errorClass, setErrorClass] = React.useState("");

    useEffect(() => {
        if (error) {
            setErrorClass("error");
        } else {
            setErrorClass("");
        }
    }, [error]);

    useEffect(() => {
        if (props.error) {
            setError(true);
        } else {
            setError(false);
        }
    }, [props.error, props.errorText, props.checked]);

    const handleErrors = (e) => {
        inputEl.current.setCustomValidity(" ");

        if (e.target.checked === false) {
            setError(true);
            setErrorClass("error");
        } else {
            setError(false);
            setErrorClass("");
            inputEl.current.setCustomValidity("");
        }
    };

    return (
        <div className="form-group m-0 checkbox-wrapper">
            <input
                id={id}
                type="checkbox"
                className={"form-check-input me-2 " + errorClass}
                autoComplete="off"
                onChange={onChange}
                onInvalid={handleErrors}
                {...props}
                ref={inputEl}
            />
            <label htmlFor={id}>{label}</label>
        </div>
    );
};

export default Checkbox;