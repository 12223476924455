import React, { useEffect, useState } from "react";
import UserContext from "../../UserContext";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ProfileUpdate from "../ProfileUpdate/ProfileUpdate";
import rect from "../../images/Rectangle.svg"
import { courGet } from "../../service/courAPI";
import { API_ENDPOINT } from "../literals";
import avatar from '../../images/icon_avatar.png';
import angleRight from '../../images/angleRight.png';

const Header = (props) => {
    const contextUser = React.useContext(UserContext);
    const navigate = useNavigate();
    const [user, setUser] = React.useState(contextUser);

    const [showProfilePopup, setProfilePopup] = useState(false);
    const [showEditProfile, setEditProfile] = useState(false);
    const [profileImage, setProfileImage] = useState("")


    useEffect(() => {
        if (contextUser.email) {
            setUser(contextUser);

            if (contextUser.picture && contextUser.picture !== "") {
                if (contextUser.picture) {
                    courGet(API_ENDPOINT.getPreSignedGetUrl(contextUser.picture))
                        .then((res) => {
                            setProfileImage(res.data);
                        }).catch((error) => {
                            console.log(error);
                        })
                }
            } else {
                setProfileImage(avatar)
            }
        } else {
            localStorage.getItem("user") && setUser(JSON.parse(localStorage.getItem("user")));
        }
    }, [contextUser]);

    const handleBackClick = (url) => {
        // navigate to previous page with react router
        if (url !== '') {
            navigate(url);
        }
    }

    useEffect(() => {
        // console.log("user", user);
    }, []);

    const logout = () => {
        contextUser.logout();

    }
    return (<>
        <div className="header">
            <div className="back-button" >
                <div className={!contextUser.backUrl.length > 0 ? "hidden" : "back-breadcrumb-container"} >
                    <svg width="12" height="12" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9 18L10.5863 16.4137L4.30875 10.125L18 10.125L18 7.875L4.30875 7.875L10.5975 1.5975L9 -3.93402e-07L-3.93402e-07 9L9 18Z" fill="white" />
                    </svg>
                    {contextUser.backUrl && contextUser.backUrl.map((back, index) => {
                        return <div className="d-flex align-items-center" key={back.label}>
                            {index != 0 && <img src={angleRight} className="" />}
                            <div className="m-2 back-url" onClick={() => handleBackClick(back.url)}>{back.label}</div></div>
                    })
                    }
                </div>
            </div>
            <div className="user-profile" onClick={() => setProfilePopup(!showProfilePopup)}>
                <div className="user-name" >Welcome, {user.firstName + " " + user.lastName} </div>
                <img src={rect} alt="course thumbnail" className={showProfilePopup ? "transform-180" : ""} />
                <div className="user-image">
                    <img src={profileImage} alt="profile thumbnail" />
                </div>
            </div >
        </div >
        {showProfilePopup &&
            <div className="profile-popup-close" onClick={() => setProfilePopup(false)} >
                <div className="profile-dropdown">
                    <div className="header-profile-img">
                        <img src={profileImage} alt="profile thumbnail" />
                    </div>
                    <div className="header-user-email">{user.email}</div>
                    <div className="header-user-profile-btn" onClick={() => setEditProfile(true)}>Edit Personal Details</div>
                    <div className="header-user-profile-btn" onClick={() => logout()}>Logout</div>
                </div>
            </div>
        }

        <Modal
            show={showEditProfile}
            fullscreen={true}
            animation={false}
        // onHide={() => setShow(false)}
        >
            <Modal.Body className="invite-owner justify-space-evenly">
                <ProfileUpdate setEditProfile={setEditProfile} showEditProfile={showEditProfile} user={user} setUser={contextUser.setUser} />
            </Modal.Body>
        </Modal>
    </>
    );
};

export default Header;