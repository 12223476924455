import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import "./CourseLaunch.css";
import { useEffect, useState } from "react";
import CryptoJS from "crypto-js"
import { courGet, courPost } from "../../service/courAPI";
import { API_ENDPOINT } from "../literals";
import setThemeColor from '../Utils/setThemeColor';
import { Button } from "react-bootstrap";
import headset from "../../images/headset.png";
import danger from '../../images/Vector.png';
import scanner from '../../images/scanQR-icon.svg'
import { Toast, ToastContainer } from "react-bootstrap";
import QRcode from 'qrcode.react'


const ScannerAR = () => {
    const params = useParams();
    const [experienceData, setExperienceData] = useState([]);
    const [courseData, setCourseData] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const [userId, setUserId] = useState("");
    const [authToken, setAuthToken] = useState("");
    const [organizationName, setOrganizationName] = useState("");
    const [selectDeviceDisabled, setSelectDeviceDisabled] = useState(true);
    const [invalidCourseDisabled, setInvalidCourseDisabled] = useState(true);
    const [toastMessage, setToastMessage] = useState("");
    const [showToastMessage, setShowToastMessage] = useState(false)
    const [qrUrl, setQRUrl] = useState("")

    setThemeColor({ primary: "#415464", secondary: "#415464", btnHover: '#768390' });
    useEffect(() => {
        var reb64 = CryptoJS.enc.Hex.parse(params.data);
        var bytes = reb64.toString(CryptoJS.enc.Base64);
        var decrypt = CryptoJS.AES.decrypt(bytes, 'sweet-rush-key@123');
        var plain = JSON.parse(decrypt.toString(CryptoJS.enc.Utf8));
        setExperienceData(plain);
        if (searchParams.get("userId") != null && searchParams.get("ltiKey") != null) {
            getInfo();
            setUserId(searchParams.get("userId"));
        } else {
            setToastMessage("Invalid user details. Please launch course again");
            setShowToastMessage(true);
        }
    }, [searchParams]);


    const getInfo = () => {
        setSelectDeviceDisabled(true);
        try {
            let fetchRes = fetch('/lti/info', { credentials: 'include', headers: { Authorization: 'Bearer ' + searchParams.get("ltiKey") } });
            fetchRes.then(res =>
                res.json()).then(launchInfo => {
                    if (launchInfo && launchInfo.context && launchInfo.context.custom && launchInfo.context.custom.custom_channel_redirect) {
                        if (searchParams.get("userId") === launchInfo.userId) {
                            setSelectDeviceDisabled(false);
                        } else {
                            setSelectDeviceDisabled(true);
                            setToastMessage("Invalid user details. Please launch the course again.");
                            setShowToastMessage(true);
                        }
                    }
                })

        } catch (err) {
            console.log(err)
            // errorPrompt('Failed trying to retrieve custom parameters! ' + err)
        }
    }
    useEffect(() => {
        if (experienceData.experience) {
            getAuthToken();
            //getCloudFlareContentAuth();
        }
    }, [experienceData.experience]);

    const getAuthToken = () => {
        //get organizationID from course id
        courGet(API_ENDPOINT.GenerateClientDetails(experienceData.experience))
            .then((res) => {
                if (res.data && res.data.courseDetails && res.data.clientAuthToken && res.data.organization) {
                    setCourseData(res.data.courseDetails);
                    setAuthToken(res.data.clientAuthToken);
                    setOrganizationName(res.data.organization);
                    setInvalidCourseDisabled(false);
                } else {
                    setToastMessage("Course no longer available");
                    setShowToastMessage(true);
                    setInvalidCourseDisabled(true);
                }
            }).catch((error) => {
                console.log(error);
            })
    }

    useEffect(() => {
        if (!selectDeviceDisabled && !invalidCourseDisabled && courseData.version && organizationName != "") {
            onClickQR()
        }

    }, [invalidCourseDisabled, courseData, selectDeviceDisabled, organizationName]);

    const onClickQR = () => {
        let launchURL = courseData.version.filter(x => x.active);
        let actor = JSON.stringify({
            mbox: userId + organizationName
        });
        let token = authToken;

        let tempExtraParam = ""
        if (experienceData?.level != "" && experienceData?.level != undefined) {
            tempExtraParam = "&module=" + experienceData?.level
        }
        let CourseLaunchURL = "endpoint=" + process.env.REACT_APP_LRS_ENDPOINT + "/data/xAPI/statements&auth=" + token + "&experience_id=" + experienceData.experience + "&mode=AR" + "&course_id=" + courseData.courseId + "&actor=" + actor + tempExtraParam

        let encodeURI = encodeURIComponent(CourseLaunchURL);
        // setQRUrl(process.env.REACT_APP_S3_FOLDER + launchURL[0].launchURL + "?" + encodeURI);

        let data = {
            userId: searchParams.get("userId"),
            experience: experienceData.experience,
            course: courseData.courseId,
            launchURL: process.env.REACT_APP_S3_FOLDER + launchURL[0].launchURL + "?" + encodeURI,
            moduleLevel: experienceData?.level
        }

        courPost(API_ENDPOINT.GenerateCourseCode, data)
            .then((res) => {
                setQRUrl(process.env.REACT_APP_APPURL + "/verification-ar/" + res.data.code);
                console.log("launch url", process.env.REACT_APP_APPURL);
                //setQRUrl("https://staging-xp-server.sweetrush.app" + "/verification-ar/" + res.data.code);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    return <>
        <div className="create-code-container container-ar">
            <div className="select-device-header select-ar">
                <div className="">Please scan the QR code with your mobile device to begin the experience.</div>
                <img src={scanner} className="scanner-thumb" />
                <a href={qrUrl} class="btn btn-sm btn-secondary headset-btn mb-2" target="_blank">Launch Course</a>
            </div>
            <div className="select-device-button-wrapper ">

                <div className=" box-ar">
                    <div className=" box-ar-inner">
                        {qrUrl != "" &&
                            <QRcode
                                id="myqr"
                                value={qrUrl}
                                size={320}
                                includeMargin={true}
                            />
                        }
                    </div>
                </div>
            </div>
        </div>
        {showToastMessage === true &&
            <ToastContainer className="p-3" position={"top-center"} >
                <Toast
                    className="toast-message"
                    bg="danger"
                    key="2">
                    <Toast.Body className="toast-text"> <img src={danger} className="me-2" />
                        {toastMessage}
                    </Toast.Body>
                </Toast>
            </ToastContainer>
        }
    </>
}

export default ScannerAR
