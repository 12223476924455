export const API_ENDPOINT =
{
    Login: '/user/login',
    InviteOwners: '/user/create',
    addPartner: '/user/addPartner',
    UpdateUser: (id) => `/user/update-user/${id}`,
    UpdateUserProfile: (id) => `/user/update-profile/${id}`,
    UpdateUserPassword: (id) => `/user/createPassword/${id}`,
    GetUser: (role) => `/user/role/${role}`,
    ForgotPassword: '/user/forgot-password',
    GetPartnersByOrganizationId: (id) => `/user/getPartnersByOrganizationId/${id}`,
    DeleteUser: (id) => `/user/deleteUser/${id}`,
    UpdatePartner: (id) => `/user/update-partner/${id}`,
    CheckExistingUser: '/user/check-user-existing',
    GetMetaData: (role) => `/user/getmetadata`,

    FileUpload: (type) => `/file/upload/${type}`,
    ZipUpload: (type) => `/file/upload/extract/${type}`,
    FolderUpload: (fileName) => `/file/upload/folder/${fileName}`,
    FileRemove: (fileName) => `/file/remove/${fileName}`,
    ImageFileDelete: (fileName) => `/file/deleteImage/${fileName}`,
    getPreSignedPutUrl: (fileName) => `/file/putUrl/${fileName}`,
    getPreSignedGetUrl: (fileName) => `/file/getUrl/${fileName}`,
    GetCloudFlareContentAuth: (userId) => `/file/getCloudFlareContentAuth/${userId}`,

    GetAllCourses: (id) => `/organization/${id}/course`,
    GetOrganizationOwner: (id) => `/organization/${id}/users`,
    GetAllOrganization: '/organization',
    CreateOrganization: '/organization/create',
    GetOrganizationDetails: (id) => `/organization/${id}`,
    UpdateOrganization: '/organization/update',
    DeleteOrganization: '/organization/delete',

    AddCourse: '/course/create',
    GetCourseById: (id) => `/course/getByCourseId/${id}`,
    GetPartners: (id) => `/course/${id}/getPartnersByCourseId`,
    UpdateCourse: (id) => `course/updateCourse/${id}`,
    DeleteCourse: (id) => `/course/delete/${id}`,
    GetPlatformByCourseId: (id) => `/course/getPlatformByCourseId/${id}`,
    UpdatePlatformByCourseId: (id) => `/course/updatePlatformByCourseId/${id}`,

    CreateModules: '/modules/create',
    GetAllModules: (id) => `/modules/experience/${id}`,
    GetModuleById: (id) => `/modules/${id}`,
    AddModuleVersion: (id) => `/modules/version/${id}`,
    UpdateActiveVersionId: (id, versionId) => `/modules/${id}/version/${versionId}`,
    DeleteModule: (id) => `/modules/delete/${id}`,
    EditExperienceById: (id, name) => `/modules/experience/${id}/${name}`,
    GetLevelListByCourse: (id, expId) => `/modules/getLevels/${id}/${expId}`,
    updateFileStatus: (id, versionId) => `/modules/fileStatus/${id}/${versionId}`,

    GenerateCourseCode: '/verificationCode/generate-code',
    GenerateClientDetails: (course) => `/verificationCode/generate-client-auth/${course}`,
    VerificationQRCode: (code) => `/verificationCode/verify-code/${code}`,

    GetAnalyticsDataByFilter: '/dashboard/getDataByFilter',
    GetAnalyticsDataByDevice: '/dashboard/get',
    GetFirstTryFinishCount: (id, platform, experienceId) => `/dashboard/firstTryFinishCount/${id}/${platform}/${experienceId}`,
    GetRevisiteScenes: (id, platform, experienceId) => `/dashboard/revisitScenesCount/${id}/${platform}/${experienceId}`,
    GetReturnOnceCompletedCount: (id, platform, experienceId) => `/dashboard/returnOnceCompleted/${id}/${platform}/${experienceId}`,
    GetAverageTime: '/dashboard/getAverageTime',

    GetExportData: (experienceId, startdate, enddate) => `/lrs/getexportdata/${experienceId}/${startdate}/${enddate}`,
    GetCustomExportData: (experienceId, startdate, enddate) => `/lrs/getcustomexportdata/${experienceId}/${startdate}/${enddate}`,

}