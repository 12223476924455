import React, { useEffect, useRef } from "react";
import { inputError } from "../Constant/errors";


const TextArea = ({ cols = "4", label, value, errorText = "", onChange, ...props }) => {
    const inputEl = useRef(null);
    const [error, setError] = React.useState(Boolean(props.error));
    const [errorClass, setErrorClass] = React.useState("");
    const [errorMsg, setErrorMsg] = React.useState(errorText);

    useEffect(() => {
        if (error) {
            setErrorClass("error");
        } else {
            setErrorClass("");
        }
    }, [error]);

    useEffect(() => {
        if (props.error) {
            setError(true);
            setErrorMsg(errorText);
        } else {
            setError(false);
            setErrorMsg("");
        }
    }, [props.error, errorText]);

    const handleErrors = (e) => {
        console.log("handleErrors", e.target.validity);
        if (e.target.validity.valid) {
            setError(false);
            setErrorMsg("");
            setErrorClass("");
            inputEl.current.setCustomValidity("");
        } else {
            setError(true);
            // get error message from validity object
            const error = inputError[e.target.validity.typeMismatch ? "typeMismatch" : e.target.validationMessage];
            console.log("error", error);
            setErrorMsg(e.target.validationMessage);
            setErrorClass("error");
            inputEl.current.setCustomValidity(" ");
        }
    };

    const resetError = () => {
        setError(false);
        setErrorMsg("");
        setErrorClass("");
        inputEl.current.setCustomValidity("");
    };

    return (
        <div className="form-group">
            <textarea

                {...props}
                className={"form-control " + errorClass + " " + props.className}
                placeholder={label}
                value={value}
                onChange={(e) => { onChange(e); resetError() }}
                onInvalid={handleErrors}
                ref={inputEl}
            />
            {
                <div className="error-message">{errorMsg}</div>
            }
        </div>
    );
};

export default TextArea;