import React, { useEffect } from "react";
import ReactTooltip from 'react-tooltip';
import CustomButton from "../Inputs/Button";
import NavigationItem from "./NavigationItem";

const LeftNavigation = (props) => {
    const [userMenu, setUserMenu] = React.useState([]);
    const name = props.thumbnail.name && props.thumbnail.name.length > 20 ? props.thumbnail.name.substring(0, 20) + '...' : props.thumbnail.name;


    useEffect(() => {
        setUserMenu(props.userMenu);
    }, [props.userMenu, props.thumbnail]);


    return (
        <div className="left-sidebar background-color">
            {props.thumbnail && props.thumbnail.path &&
                <div className="thumbnail-container">
                    <img src={props.thumbnail.path} width='220px' alt="experience_thumbnail" />
                    <div className="thumbnail-gradient"></div>
                    {name && <><ReactTooltip />
                        <div data-tip={name.length > 20 ? props.thumbnail.name : ''} className="thumbnail-text white-text">{name}</div>
                    </>}
                </div>
            }
            <div className="left-sidebar-content">
                {/* <div className="client-logo">
                    smaple image url
                </div> */}
                <ul className="menu-list">
                    {userMenu.map((item, index) => {
                        // remove space and convert to lowercase 
                        const label = item.label.replace(/\s/g, '').toLowerCase();
                        let key = "menu-item-" + label + index;
                        return <NavigationItem {...item} key={key} />
                    })}

                </ul>
            </div>
            <div>
                {
                    false &&
                    <CustomButton className="w-100" variant="outline-primary" >
                        LAUNCH EXPERIENCE
                    </CustomButton>}
            </div>
        </div >
    );
}

export default LeftNavigation;  