import React from "react";
import { Toast, ToastContainer } from "react-bootstrap";
import './Toast.css';
import blast from '../../images/blast.png';
import danger from '../../images/Vector.png';

const ToastMessage = (props) => {

    const handleDismiss = (id) => {
        props.setToastList(props.toastList.filter(x => x.id !== id));
    }

    return (
        <div className="toast-container-wrapper">
            <ToastContainer className="p-3" position={"top-center"} >
                {props.toastList && props.toastList.map(toast => {
                    return (
                        <Toast
                            className="toast-message"
                            onClose={() => handleDismiss(toast.id)}
                            bg={toast.variant ? toast.variant?.toLowerCase() : ""}
                            key={toast.id}
                            delay={4000}
                            autohide>
                            <Toast.Body  className="toast-text">{toast.variant?.toLowerCase() === "success" ? <img src={blast} className="me-2" /> : <img src={danger} className="me-2" />}
                                {toast.message}
                            </Toast.Body>
                        </Toast>)
                })}
            </ToastContainer>
        </div>
    )
}

ToastMessage.defaultProps = {
    variant: "Dark",
    title: "Toast Message",
    message: "Hello, world! This is a toast message.",
    time: new Date().getTime(),
    show: false
}

export default ToastMessage;