export const Constant = {
   ROLE: {
      organization: "orgOwner",
      partner: "partner",
      admin: "superAdmin"
   },
   ERROR: {
      network: "ERR_NETWORK"
   },
   SELECTED: {
      All: 'All',
      Desktop: 'Desktop',
      Headset: 'Headset'
   },
   STATUS: {
      start: 'started',
      complete: 'completed'
   },
   TYPE: {
      course: 'course',
      experience: 'experience',
      level: 'level'
   }
}