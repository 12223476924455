const inputError = {
    badInput: "Invalid input",
    customError: "Something went wrong",
    patternMismatch: "Value does not match pattern",
    rangeOverflow: "Maximum value exceeded",
    rangeUnderflow: "Minimum value not met",
    stepMismatch: "Step mismatch",
    tooLong: "Value is too long",
    tooShort: "Value is too short",
    typeMismatch: "Please enter valid ",
    valueMissing: "Value missing",
    isRequired: ' is required',
    internalError: 'An error occurred during processing. Please try again later',
    fileError: 'Error while uploading file, Please try again.',
    fileFormatError: (fileType) => `Please upload a ${fileType} file.`,
    fileSizeError: (fileSize) => `File is larger than ${fileSize}.`
}

export { inputError };