import React from "react";
import { NavLink } from 'react-router-dom';

const NavigationItem = (props) => {

    const checkIsActive = (e) => {
        console.log(e)
    }

    return (
        <NavLink to={props.to} activeClassName="active" onChange={checkIsActive} className="menu-item">
            {/* <li> */}
            {props.label}
            {/* Arrow icon on active menu*/}
            <i className="fa fa-chevron-right menu-item-icon ps-2" />
            {/* </li> */}
        </NavLink>
    );
}

export default NavigationItem;