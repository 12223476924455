import React, { useContext, useEffect, useRef, useState } from "react";
import { Dropdown, Modal } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import UserContext from "../../UserContext";
import CustomDropdown from "../Inputs/CustomDropdown";
import CustomDropdownMenu from "../Inputs/customDropdownMenu";
import PartnerItem from "../shared/PartnerItem";
import { API_ENDPOINT } from "../literals";
import { courGet, courPut, courDelete, courPost } from "../../service/courAPI";
import InvitePartner from "../Dashboard/InvitePartner";
import { Text } from "../Constant/string";
import { inputError } from "../Constant/errors";
import { Constant } from "../Constant/constant";
import { BackButton } from "./BackButton";
import axios, { CancelToken } from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { validateTextInput } from "../Utils/validation";
import { AddModule } from "./AddModule";
import { EditCourse } from "./EditCourse";
import CourseItem from "../shared/CourseItem";
import DeleteItem from "./DeleteItem";
import { RiDeleteBin6Line } from "react-icons/ri";
import { MdModeEditOutline, MdOutlineClose } from "react-icons/md";




const CourseDetails = (props) => {
   const userContext = useContext(UserContext);
   const navigate = useNavigate();
   const location = useLocation();
   const course = location.state;
   const [experienceDetails, setExperienceDetails] = useState(course || []);
   const [partners, setPartners] = useState([]);
   const [showDeleteCourse, setShowDeleteCourse] = useState(false);
   const [showEditExperience, setShowEditExperience] = useState(false);
   const [showAddNewModule, setShowAddNewModule] = useState(false);
   const [newExperience, setNewExperience] = useState([]);
   const [newExperienceError, setNewExperienceError] = useState([]);
   const [modules, setModules] = useState([]);
   const [modulesError, setModulesError] = useState([]);
   const [moduleList, setModuleList] = useState([]);
   const [error, setError] = useState('');
   const [applyValidation, setApplyValidation] = useState(false);
   const [file, setFile] = useState('');
   const [fileLoading, setFileLoading] = useState({ "percent": 0, "total": 0, "loaded": 0 });
   const cancelFileUpload = useRef(null);
   const [step, setStep] = useState(0);
   const [deleteCourseName, setDeleteCourseName] = useState('');
   const [courseList, setCourseList] = useState([]);
   const [disable, setDisable] = useState(false);
   const [thumbnailUrl, setThumbnailUrl] = useState('');
   const [fileData, setFileData] = useState('');
   const [isRemove, setIsRemove] = useState(false);

   const clearStorage = () => {
      setExperienceDetails([]);
      setPartners([]);
      handleModalBack();
   }

   const handleModalBack = () => {
      setShowAddNewModule(false);
      setShowEditExperience(false);
      setShowDeleteCourse(false);
      setError('');
      setApplyValidation(false);
      setNewExperience([]);
      setNewExperienceError([]);
      setFileLoading({ "percent": 0, "total": 0, "loaded": 0 });
      localStorage.removeItem('fileLoading');
      setFile('');
      setFileData('');
      setModules([]);
      setModulesError([]);
      setStep(0);
      setThumbnailUrl('');
      setDisable(false);
      setDeleteCourseName('');
   }

   const handleBackTOCourseDetails = () => {
      handleGoBackButton();
      getExperienceById();
   }

   const handleGoBackButton = () => {
      file && removeFile(file);
      abortFileUpload();
      handleModalBack();
   }
   useEffect(() => {
      if (userContext.role === Constant.ROLE.admin) {
         userContext.setUserMenu([
            {
               label: 'Course Analytics',
               to: '/analytics/' + userContext.selectedCourse,
            },
            {
               label: 'Course Details',
               to: '/course/' + userContext.selectedCourse,
            },
            {
               label: 'LTI Configuration',
               to: '/course/lti/' + userContext.selectedCourse,

            },
            {
               label: 'Export',
               to: '/export/' + userContext.selectedCourse,
            }
         ]);
         userContext.setBackUrl([
            {
               label: "All Organizations",
               url: '/clients'
            },
            {
               label: userContext.selectedClientName,
               url: '/client/' + userContext.selectedClient + '/courses'
            },
            {
               label: 'Course',
               url: '/analytics/' + userContext.selectedCourse
            }
         ]);
      } else if (userContext.role === Constant.ROLE.organization) {
         userContext.setUserMenu([
            {
               label: 'Course Analytics',
               to: '/analytics/' + userContext.selectedCourse,
            },
            {
               label: 'Course Details',
               to: '/course/' + userContext.selectedCourse,
            },
            {
               label: 'Export',
               to: '/export/' + userContext.selectedCourse,
            }
         ]);
         userContext.setBackUrl([
            {
               label: userContext.selectedClientName,
               url: '/dashboard'
            },
            {
               label: 'Course',
               url: '/analytics/' + userContext.selectedCourse
            }
         ]);
      } else {
         userContext.setUserMenu([
            {
               label: 'Course Analytics',
               to: '/analytics/' + userContext.selectedCourse,
            },
            {
               label: 'Course Details',
               to: '/course/' + userContext.selectedCourse,
            }
         ]);
         userContext.setBackUrl([
            {
               label: userContext.selectedClientName,
               url: '/dashboard'
            },
            {
               label: 'Course',
               url: '/analytics/' + userContext.selectedCourse
            }
         ]);
      }

      if (userContext.selectedClient) {
         getExperienceById();
         getPartners();
         getModuleListByExperienceId();
         getAllCourses();
      }
   }, [userContext.selectedClient]);

   const getModuleListByExperienceId = () => {
      courGet(API_ENDPOINT.GetAllModules(userContext.selectedCourse))
         .then((res) => {
            setModuleList(res.data);
            getExperienceById();
         }).catch((error) => {
            console.log(error);
         })
   }

   useEffect(() => {
      localStorage.setItem('fileLoading', JSON.stringify(fileLoading));
   }, [fileLoading])

   useEffect(() => {
      courGet(API_ENDPOINT.getPreSignedGetUrl(experienceDetails.thumbnail))
         .then((res) => {
            userContext.setThumbnail({ path: res.data, name: experienceDetails.name })
            setThumbnailUrl(res.data);
         }).catch((error) => {
            //  console.log(error);
         })
   }, [experienceDetails])

   const getExperienceById = () => {
      courGet(API_ENDPOINT.GetCourseById(userContext.selectedCourse))
         .then((res) => {
            setExperienceDetails(res.data);
         }).catch((error) => {
            // console.log(error);
         })
   }

   const getPartners = () => {
      courGet(API_ENDPOINT.GetPartners(userContext.selectedCourse))
         .then((res) => {
            setPartners(res.data);
         }).catch((error) => {
            // console.log(error);
         })
   }

   const updateExperience = (e) => {
      e.preventDefault();
      setApplyValidation(true);
      const nameError = validateTextInput(newExperience.name, "Course name", true, 1, 100);
      const descriptionError = validateTextInput(newExperience.description, "Course description", true, 1, 500);

      handleExperienceValidation('name', nameError);
      handleExperienceValidation('description', descriptionError);
      if (!nameError && !descriptionError) {
         let data = {
            name: newExperience.name,
            description: newExperience.description,
            thumbnail: file ? file : experienceDetails.thumbnail
         }
         setDisable(true);
         courPut(API_ENDPOINT.UpdateCourse(newExperience._id), data)
            .then((res) => {
               setExperienceDetails(res.data);
               handleModalBack();
               userContext.createToast({ title: "Course Updated", message: "Course successfully updated.", variant: "Success" });
            }).catch((error) => {
               setDisable(false);
               handleError(error, 'Course update');
            })
      }
   }

   function handleNewExperience(e) {
      e.preventDefault();

      const { name, value } = e.target;
      setNewExperience((prevState) => ({
         ...prevState,
         [name]: value
      }));
      if (applyValidation) {
         setNewExperienceError((prevState) => ({
            ...prevState,
            [name]: ''
         }));
      }
   }

   const handleExperienceValidation = (name, message) => {
      setNewExperienceError((prevState) => ({
         ...prevState,
         [name]: message
      }));
   }

   const handleFileError = () => {
      setFile('');
      setDisable(false);
      setFileLoading({ "percent": 0, "total": 0, "loaded": 0 });
      localStorage.removeItem('fileLoading');
      setFileData('');
   }
   const uploadFile = (fileData, type) => {
      setError('');
      if (fileData) {
         const fileName = uuidv4() + '-' + fileData.name.replace(/[^a-zA-Z0-9. ]/g, "").toLowerCase().split(' ').join('');
         setFile(fileName);
         setDisable(true);
         setFileData(fileData);
         handleFileUpload(type, fileName, fileData);
      }
   }

   const resumeFileUpload = () => {
      handleFileUpload('zip', file, fileData);
   }

   const uploadImageFile = (fileData, type) => {
      if (fileData) {
         const fileName = uuidv4() + '-' + fileData.name.toLowerCase().split(' ').join('');
         setFile(fileName);
         setDisable(true);
         setFileData(fileData);

         const formData = new FormData();
         formData.append('file', fileData, fileName);
         const file = new File([fileData], fileName);

         courGet(API_ENDPOINT.getPreSignedPutUrl(fileName))
            .then((res) => {
               if (res.data) {
                  const fileExt = fileName.split('.')[1];
                  axios.put(res.data, file,
                     {
                        headers: { 'Content-Type': 'image/' + fileExt }
                     }
                  ).then((response) => {
                     if (response) {
                        setDisable(false);
                        showEditExperience && setThumbnailUrl(URL.createObjectURL(fileData));
                     }
                  }).catch(error => {
                     setFile('');
                     setDisable(false);
                     setFileData('');
                     setFileLoading({ "percent": 0, "total": 0, "loaded": 0 });
                     localStorage.removeItem('fileLoading');
                     handleError(error, 'Upload file');
                  });
               }
            })
            .catch((err) => {
               console.log(err);
               setFile('');
               setDisable(false);
               setFileData('');
               setFileLoading({ "percent": 0, "total": 0, "loaded": 0 });
               localStorage.removeItem('fileLoading');
               handleError(error, 'Upload file');
            });
      }
   }

   const handleFileUpload = (type, fileName, fileData) => {
      const formData = new FormData();
      formData.append('file', fileData, fileName);
      const apiRequest = type === "zip" ? API_ENDPOINT.ZipUpload(type) : API_ENDPOINT.FileUpload(type);

      axios.post(apiRequest, formData,
         {
            onUploadProgress: (event) => {
               const prevLoaded = JSON.parse(localStorage.getItem('fileLoading'));
               if (prevLoaded.loaded <= event.loaded) {
                  let percent = Math.floor((100 * event.loaded) / event.total);
                  setFileLoading({ "percent": percent, "total": event.total, "loaded": event.loaded });
               }
            },
            cancelToken: new CancelToken(
               cancel => (cancelFileUpload.current = cancel)
            ),
         }
      ).then((response) => {
         if (response) {
            setDisable(false);
            showEditExperience && setThumbnailUrl(URL.createObjectURL(fileData));
            if (response.data.fileExtractFields.launchURL) {
               setModules((prevState) => ({
                  ...prevState,
                  launchURL: fileName.split('.')[0] + "/" + response.data.fileExtractFields.launchURL,
                  levels: response.data.fileExtractFields.levelList
               }));
            }
         }
      }).catch(error => {
         if (error.code === 'ERR_NETWORK') {
            handleFileUpload(type, fileName, fileData);
         } else if (error.code === 'ERR_CANCELED') {
            console.log(error);
         } else {
            setFile('');
            setDisable(false);
            setFileData('');
            setFileLoading({ "percent": 0, "total": 0, "loaded": 0 });
            handleError(error, 'Upload file');
         }
      });

   }
   const removeFile = (fileName) => {
      const apiReq = showEditExperience ? API_ENDPOINT.ImageFileDelete(fileName) : API_ENDPOINT.FileRemove(fileName);

      courDelete(apiReq)
         .then(res => {
            setFileLoading({ "percent": 0, "total": 0, "loaded": 0 })
            setFile('');
            setError('');
         }).catch(error => {
            handleError(error, 'Not able to delete file');
         })
      abortFileUpload();
   };

   const cancelUpload = (filename) => {
      removeFile(filename);
      abortFileUpload();
   };

   const abortFileUpload = () => {
      if (cancelFileUpload.current) {
         cancelFileUpload.current("User has canceled the file upload.");
      }
   }

   const handleModuleDetails = (e) => {
      e.preventDefault();
      const { name, value } = e.target;
      setModules((prevState) => ({
         ...prevState,
         [name]: value
      }));
      if (applyValidation) {
         setModulesError((prevState) => ({
            ...prevState,
            [name]: ''
         }));
      }
   }

   const handleModuleSelect = (e) => {
      const { value } = e;
      setModules((prevState) => ({
         ...prevState,
         mode: value
      }));
   }

   const handleModuleValidation = (name, message) => {
      setModulesError((prevState) => ({
         ...prevState,
         [name]: message
      }));
   }


   const addNewModule = (e) => {
      e.preventDefault();
      setApplyValidation(true);
      const nameError = validateTextInput(modules.name, Text.nameExperience, true, 1, 100);
      handleModuleValidation("name", nameError);
      if (!nameError && modules.launchURL && file && modules.mode != undefined && modules.mode != "") {
         let id = uuidv4();
         let data = {
            name: modules.name,
            version: [{ id, fileName: file, launchURL: modules.launchURL, active: false, fileStatus: '0', levels: modules.levels }],
            courseId: userContext.selectedCourse,
            isActive: true,
            mode: modules.mode
         }
         let moduleId = '';
         setDisable(true);
         courPost(API_ENDPOINT.CreateModules, data)
            .then((res) => {
               moduleId = res.data._id
               handleModalBack();
               getModuleListByExperienceId();
               userContext.createToast({ title: "Experience Created", message: "Experience Created.", variant: "Success" });
               data = { _id: res.data._id, versionId: id }
               courPost(API_ENDPOINT.FolderUpload(file), data).then((res) => {
                  getModuleListByExperienceId();
                  // console.log('Upload file successfully');
               }).catch((err) => {
                  if (err.code === Constant.ERROR.network) {
                     //Console.log("network error")
                  } else if (err.response.status != 524) {
                     updateFileStatus(moduleId, id);
                  }
               });
            })
            .catch((err) => {
               setDisable(false);
               handleError(err, 'Experience Created');
            });
      }
   }

   const updateFileStatus = (id, versionId) => {
      courPut(API_ENDPOINT.updateFileStatus(id, versionId)).then((res) => {
         // console.log("Updated Sucessfully");
      })
   }

   const handleError = (err, title) => {
      if (err.code === Constant.ERROR.network) {
         userContext.createToast({ title: "internal error", message: inputError.internalError, variant: "danger" });

      }
      else {
         userContext.createToast({ title: title, message: err.response.data.message, variant: "danger" });
      }
   }

   const onClickModule = (modules) => {
      navigate("/course/" + userContext.selectedCourse + "/" + modules.name, { state: { modules, course: experienceDetails } });
   }

   function handleBackButton() {
      clearStorage();
      navigate("/dashboard");
   }

   const getAllCourses = () => {
      courGet(API_ENDPOINT.GetAllCourses(userContext.selectedClient))
         .then((res) => {
            setCourseList(res.data);
         })
         .catch((err) => {
            // console.log(err);
         });
   }

   const handleInputField = (e) => {
      setDeleteCourseName(e.target.value)
   }

   const handleDeleteStep = () => {
      if (step === 0) {
         setStep(1)
      }
      if (step === 1) {
         setDisable(true);
         courDelete(API_ENDPOINT.DeleteCourse(experienceDetails._id))
            .then(() => {
               clearStorage();
               userContext.createToast({ title: "Course deleted", message: "Course deleted.", variant: "Success" });
               const path = userContext.role === Constant.ROLE.admin ?
                  '/client/' + userContext.selectedClient + '/courses' : '/dashboard'
               navigate(path);
            })
            .catch((err) => {
               setDisable(false);
               console.log(err);
               handleError(err, 'Course delete');
            });
      }
   }

   return <>
      <div className="dashboard">
         <div className="d-flex justify-content-between">
            <h1 className='title'>{experienceDetails.name}</h1>
            {userContext.role !== Constant.ROLE.partner &&
               <Dropdown align="end">
                  <Dropdown.Toggle as={CustomDropdown} id="dropdown-custom-components">
                  </Dropdown.Toggle>

                  <Dropdown.Menu as={CustomDropdownMenu}>
                     <Dropdown.Item eventKey="1" className="dropDown-menu-text" onClick={() => { setNewExperience(experienceDetails); setShowEditExperience(true) }}><MdModeEditOutline className="icon-dropdown" />Edit</Dropdown.Item>
                     <Dropdown.Item eventKey="2" className="dropDown-menu-text" onClick={() => { setShowDeleteCourse(true) }}><RiDeleteBin6Line className="icon-dropdown" />Delete</Dropdown.Item>
                  </Dropdown.Menu>
               </Dropdown>
            }
         </div>
         <div className="member-list course-description">
            {experienceDetails.description}
         </div>
         <hr className="hr-line" />
         {((Constant.ROLE.partner !== userContext.role) || (moduleList?.length > 0 && Constant.ROLE.partner === userContext.role)) && <>
            <div className="organization-owners">
               <h1 className='title'>{Text.experience}</h1>
               <div className="member-list">
                  {moduleList && moduleList.map((module) => {
                     return (<CourseItem
                        type="module"
                        experience={module}
                        thumbnail={thumbnailUrl}
                        key={'module-' + module._id}
                        onClick={() => onClickModule(module)} />);
                  })}
                  {Constant.ROLE.partner !== userContext.role &&
                     <div className='add-item add-experience'
                        onClick={() => setShowAddNewModule(true)}>
                        <i className='fa fa-plus' />
                     </div>
                  }
               </div>
            </div>

            <hr className="hr-line" />
         </>}

         <div className="organization-owners">
            <div className="d-flex justify-content-between">
               <h1 className='title'>{Text.partner}</h1>
               {userContext.role !== Constant.ROLE.partner &&
                  <Dropdown align="end">
                     <Dropdown.Toggle as={CustomDropdown} id="dropdown-custom-components">
                     </Dropdown.Toggle>

                     <Dropdown.Menu as={CustomDropdownMenu}>
                        {!isRemove &&
                           <Dropdown.Item eventKey="1" className="dropDown-menu-text" onClick={() => { setIsRemove(true) }}><RiDeleteBin6Line className="icon-dropdown" />Remove Partners</Dropdown.Item>}
                        {isRemove &&
                           <Dropdown.Item eventKey="1" className="dropDown-menu-text" onClick={() => { setIsRemove(false) }}><MdOutlineClose className="icon-dropdown" />Cancel</Dropdown.Item>}
                     </Dropdown.Menu>
                  </Dropdown>
               }
            </div>
            <div className="member-list">
               {partners && partners.map((member) => {
                  return (<PartnerItem member={member} key={'member-' + member._id} refreshMembers={getPartners} experienceList={courseList} isRemove={isRemove} />);
               })}
               {userContext.role !== Constant.ROLE.partner &&
                  <InvitePartner experience={userContext.selectedCourse} refreshMembers={getPartners} />
               }
            </div>
         </div>

      </div>
      <DeleteItem show={showDeleteCourse}
         handleBackButton={handleGoBackButton}
         step={step}
         type="course"
         name={experienceDetails.name}
         handleDeleteStep={handleDeleteStep}
         handleOnChange={handleInputField}
         typeName={deleteCourseName}
         disable={disable}
      />

      <Modal
         show={showEditExperience || showAddNewModule}
         fullscreen={true}
         animation={false} >
         <Modal.Body className="invite-owner">
            <div className="experience-container">
               {showEditExperience && <EditCourse
                  newExperience={newExperience}
                  handleNewExperience={handleNewExperience}
                  newExperienceError={newExperienceError}
                  file={file}
                  uploadFile={uploadImageFile}
                  thumbnailUrl={thumbnailUrl}
                  error={error} />
               }
               {
                  showAddNewModule && <AddModule
                     fileLoading={fileLoading}
                     file={file}
                     cancelUpload={cancelUpload}
                     abortFileUpload={abortFileUpload}
                     resumeFileUpload={resumeFileUpload}
                     removeFile={removeFile}
                     uploadFile={uploadFile}
                     module={modules}
                     moduleError={modulesError}
                     handleModuleDetails={handleModuleDetails}
                     handleFileError={handleFileError}
                     handleModuleSelect={handleModuleSelect}
                     applyValidation={applyValidation} />
               }

               <BackButton
                  handleBackButton={handleBackTOCourseDetails}
                  cancelBtnName='Cancel'
                  buttonName={disable ? <div className="loading-btn-processing"><span className="loading">Processing</span></div> : (showEditExperience ? Text.updateCourse : Text.addExperience)}
                  classes={(disable || (!showEditExperience && fileLoading.percent < 100)) ? 'disabled' : ''}
                  handleOnClick={showEditExperience ? updateExperience : addNewModule} />
               {error && <div className="error" >{error}</div>}
            </div>
         </Modal.Body>
      </Modal>
   </>
}

export default CourseDetails;