import CustomButton from "../Inputs/Button";
import classnames from 'classnames';
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";


export const BackButton = ({ classes = '', buttonName = 'Next', cancelBtnName = 'Cancel', ...props }) => {
    return (
        <div className="button-wrapper">
            <CustomButton
                variant="link-secondary"
                className="w-auto text-normal back-btn"
                onClick={props.handleBackButton}>
                <HiOutlineArrowNarrowLeft className="back-arrow me-2" />
                <span className="cancelbtn">{cancelBtnName}</span>
            </CustomButton>
            <CustomButton
                type="submit"
                className={classnames(classes, 'w-auto px-5')}
                onClick={props.handleOnClick}>{buttonName}</CustomButton>
        </div>
    )
}