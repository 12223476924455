const Text = {
    yourCourse: "Your Courses",
    addCourse: "Add New Course",
    addModule: "Add New Experience",
    course: "Courses",
    nameCourse: "Name of Course",
    updateCourse: "Update",
    description: "Description",
    allClients: "All Organizations",
    experiences: "Experiences",
    nameExperience: "Experience name",
    addExperience: "Add Experience",
    uploadImage: "Upload Image",
    uploadImageSubText: "Upload an image to represent your course.",
    uploadZip: 'Upload File',
    uploadZipSubText: 'Upload a zip file of your experience.',
    experience: "Experiences",
    partner: "Partners",
    coursera: "coursera",
    activeLink: "Active Link",
    version: "Versions",
    dialogBackButtonText: "Cancel"
}

export { Text };