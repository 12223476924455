import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import Header from "./Header";
import LeftNavigation from "./LeftNavigation";

const SidebarLayout = (props) => {


    return props.isAuthenticate ?
        <>
            <Header />
            <div className={'content-wrapper'}>
                <LeftNavigation userMenu={props.userMenu} thumbnail={props.thumbnail} />
                <div className='content'>
                    <Outlet />
                </div>
            </div>
        </>
        :
        <Navigate to="/login" />

}

export default SidebarLayout;