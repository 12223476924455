import React, { useEffect, useRef } from "react";
import './inputs.css';

const PasswordInput = ({ label, value, onChange, ...props }) => {
    const inputEl = useRef(null);
    const [type, setType] = React.useState("password");
    const [icon, setIcon] = React.useState("fa fa-eye");

    const [error, setError] = React.useState(Boolean(props.error));
    const [errorClass, setErrorClass] = React.useState("");
    const [errorText, setErrorText] = React.useState(props.errorText || "");

    const toggleType = () => {
        if (type === "password") {
            setType("text");
            setIcon("fa fa-eye-slash");
        } else {
            setType("password");
            setIcon("fa fa-eye");
        }
    };

    useEffect(() => {
        if (error) {
            setErrorClass("error");
        } else {
            setErrorClass("");
        }
    }, [error]);

    useEffect(() => {
        if (props.error) {
            setError(true);
            setErrorClass("error");
            setErrorText(props.errorText);
        } else {
            setError(false);
            setErrorClass("");
            setErrorText(errorText);
        }
    }, [props.error, props.errorText]);

    const handleErrors = (e) => {
        if (e.target.validity.valid) {
            setError(false);
            setErrorText("");
            setErrorClass("");
            inputEl.current.setCustomValidity("");
        } else {
            setError(true);
            // get validity state from target.validity
            let state = Object.keys(e.target.validity).filter((key) => e.target.validity[key]);
            setErrorText(e.target.validationMessage);
            setErrorClass("error");
        }

    };

    const resetError = () => {
        setError(false);
        setErrorText("");
        setErrorClass("");
        inputEl.current.setCustomValidity("");
    };

    return (
        <div className="form-group">
            <div>
                <input
                    {...props}
                    type={type}
                    className={"form-control passwordWidth " + errorClass + " " + props.className}
                    placeholder={label}
                    value={value}
                    autoComplete="new-password"
                    onChange={(e) => { onChange(e); resetError(); }}
                    onInvalid={handleErrors}
                    ref={inputEl}
                />
                {error && <div className="error-message">{errorText}</div>}
            </div>
            <i
                className={icon + " muted-text password-icon"}
                aria-hidden="true"
                onClick={toggleType}

            />
        </div>
    );
};

export default PasswordInput;