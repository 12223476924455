import React, { useContext, useEffect, useState } from 'react';
import InviteOwner from './InviteOwner';
import AddCourse from '../Course/AddCourse';
import MemberItem from '../shared/MemberItem';
import UserContext from '../../UserContext';
import CourseItem from '../shared/CourseItem';
import { useNavigate } from 'react-router-dom';
import { API_ENDPOINT } from "../literals";
import { courGet } from "../../service/courAPI";
import { Text } from "../Constant/string";
import { Constant } from "../Constant/constant";
import { Dropdown } from 'react-bootstrap';
import CustomDropdown from "../Inputs/CustomDropdown";
import CustomDropdownMenu from "../Inputs/customDropdownMenu";
import { RiDeleteBin6Line } from 'react-icons/ri';
import { MdOutlineClose } from 'react-icons/md';

const Dashboard = () => {
    const userContext = useContext(UserContext);
    const [experienceList, setExperienceList] = useState();
    const [orgDetails, setOrgDetails] = useState();
    const navigate = useNavigate();
    const [members, setMembers] = React.useState([]);
    const [isRemove, setIsRemove] = useState(false);

    useEffect(() => {
        userContext.setBackUrl([]);
        if (userContext.selectedClient && userContext.token) {
            getMembers();
            getAllCourses();
            getOrgDetails();
        }
    }, [userContext.selectedClient]);


    const getOrgDetails = () => {
        userContext.organization &&
            courGet(API_ENDPOINT.GetOrganizationDetails(userContext.organization))
                .then((res) => {
                    setOrgDetails(res.data);
                })
                .catch((err) => {
                    console.log(err);
                });
    }

    const getAllCourses = () => {
        userContext.selectedClient &&
            courGet(API_ENDPOINT.GetAllCourses(userContext.selectedClient))
                .then((res) => {
                    if (userContext.role !== Constant.ROLE.partner) {
                        setExperienceList(res.data);
                    } else {
                        let result = res.data.filter(x => userContext?.experience?.filter(y => y === x._id).length > 0);
                        setExperienceList(result);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
    }

    const getMembers = () => {
        userContext.selectedClient &&
            courGet(API_ENDPOINT.GetOrganizationOwner(userContext.selectedClient))
                .then((res) => {
                    setMembers(res.data);
                })
                .catch((err) => {
                    console.log(err);
                });

    };

    const onClickExperience = (course) => {
        userContext.setSelectedCourse(course._id);
        navigate('/analytics/' + course._id, { state: { ...course } });
    }

    return (
        <>
            <div className="dashboard">
                {orgDetails && orgDetails.name &&
                    <h1 className='title primary-color'>{orgDetails.name}</h1>
                }
                <h1 className='title header-color'>{Text.yourCourse}</h1>
                <div className="dashboard-content member-list">
                    {experienceList && experienceList?.map((experience) => {
                        return (<CourseItem
                            experience={experience}
                            key={'exp-' + experience._id} onClick={() => onClickExperience(experience)} />);
                    })}
                    {userContext.role !== Constant.ROLE.partner &&
                        <AddCourse getAllCourses={getAllCourses} />
                    }
                </div>
                {userContext.role !== Constant.ROLE.partner &&
                    <div className="organization-owners">
                        <div className="d-flex justify-content-between">
                            <h1 className='title header-color'>Your Team (Organization Admins)</h1>
                            {userContext.role !== Constant.ROLE.partner &&
                                <Dropdown align="end">
                                    <Dropdown.Toggle as={CustomDropdown} id="dropdown-custom-components">
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu as={CustomDropdownMenu}>
                                        {!isRemove &&
                                            <Dropdown.Item eventKey="1" className="dropDown-menu-text" onClick={() => { setIsRemove(true) }}><RiDeleteBin6Line className="icon-dropdown" />Remove Admins</Dropdown.Item>}
                                        {isRemove &&
                                            <Dropdown.Item eventKey="1" className="dropDown-menu-text" onClick={() => { setIsRemove(false) }}><MdOutlineClose className="icon-dropdown" />Cancel</Dropdown.Item>}
                                    </Dropdown.Menu>
                                </Dropdown>
                            }
                        </div>
                        <div className="member-list">
                            {members && members.map((member) => {
                                return (<MemberItem member={member} key={'member-' + member._id} refreshMembers={getMembers} isRemove={isRemove} />);
                            })}
                            <InviteOwner refreshMembers={getMembers} />
                        </div>
                    </div>
                }
            </div>
        </>
    );
};

export default Dashboard;