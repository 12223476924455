import { Bar } from 'react-chartjs-2';
import { memo } from 'react';

const options = (levels) => {
   return {
      responsive: true,
      plugins: {
         legend: {
            display: false
         },
         datalabels: {
            display: false
         },
         tooltip: {
            displayColors: false,
            yAlign: 'bottom',
            backgroundColor: '#FFFFFF',//'#FFFFFF',
            titleColor: '#415464',
            titleFont: { weight: 'bold' },
            bodyColor: '#415464',
            borderRadius: 8,
            borderColor: ['#D3D3D3'],
            borderWidth: 2,
            bodyFont: { weight: 'bold' },
            titleSpacing: 2,
            bodyAlign: 'center',
            titleAlign: 'center',
            caretSize: 8,
            caretPadding: 6,
            cornerRadius: 10,
            padding: 6,
            callbacks: {
               labelPointStyle: function (context) {
                  return {
                     pointStyle: 'triangle',
                  };
               },
               label: function (context) {
                  let label = context.dataset.label || '';
                  let level = ''
                  const levelList = levels && levels.length > 0 && levels.filter((x => x.value));
                  for (let i = 0; i < levelList.length; i++) {
                     if (context.dataset.stack.trim() === `Stack ${i}`.trim()) {
                        level = levelList[i].label;
                     }
                  }
                  return [`${level}`, `${label}, ${context.parsed.y}`];
               }
            }
         }
      },
      scales: {
         y: {
            grid: {
               color: '#F1F1F5'
            },
            min: 0,
            //max: 100,
            stacked: true,
            ticks: {
               stepSize: 100,
               // Include a dollar sign in the ticks
               callback: function (value, index, ticks) {
                  return value;
               },
               color: '#383874',
               font: {
                  family: 'Mulish',
                  size: '12px'
               },
               opacity: 0.6,
            }
         },
         x: {
            grid: {
               color: '#F1F1F5'
            },
            stacked: true,
            ticks: {
               color: '#7F85A2',
               font: {
                  family: 'Mulish',
                  size: '14px'
               },
               opacity: 0.6,
            }
         },
      }
   }
};

const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
const stackStarted = {
   label: "Started",
   backgroundColor: "#D7EBFF",
   borderColor: "white",
   borderWidth: 2,
   hoverBackgroundColor: "#D7EBFF",
   hoverBorderColor: "white",
   borderRadius: {
      topLeft: 10,
      topRight: 10,
      bottomLeft: 10,
      bottomRight: 10
   },
   borderSkipped: false,
   barPercentage: 0.9,
   categoryPercentage: 0.6,
}

const stackCompleted = {
   label: "Completed",
   backgroundColor: "#FFCCBE",
   borderColor: "white",
   borderWidth: 2,
   borderRadius: {
      topLeft: 10,
      topRight: 10,
      bottomLeft: 10,
      bottomRight: 10
   },
   borderSkipped: false,
   barPercentage: 0.9,
   categoryPercentage: 0.6,
   hoverBackgroundColor: "#FFCCBE",
   hoverBorderColor: "white",
}

const dataSet = ((levels, selectedData) => {
   const levelList = levels && levels.length > 0 && levels.filter((x => x.value));
   const data = [];
   for (let i = 0; i < levelList.length; i++) {
      const levelDetailsStarted = selectedData && selectedData.filter(x => x.level === levelList[i].value && x.status === 'started')
      data.push(barStarted(levelDetailsStarted[0], i));
      const levelDetailsCompleted = selectedData && selectedData.filter(x => x.level === levelList[i].value && x.status === 'completed')
      data.push(barCompleted(levelDetailsCompleted[0], i));
   }
   return data;
})

const barStarted = (levelData, index) => {
   return {
      ...stackStarted,
      data: levelData ? levelData.data : false,
      stack: `Stack ${index} `,
   }
}

const barCompleted = (levelData, index) => {
   return {
      ...stackCompleted,
      data: levelData ? levelData.data : false,
      stack: `Stack ${index} `,
   }
}

const BarChart = ({ selectedData, levels }) => {
   const activeChartData = {
      labels,
      datasets: dataSet(levels, selectedData)
   };

   return (
      <Bar
         data={activeChartData}
         options={options(levels)} />
   )
}

export default memo(BarChart);