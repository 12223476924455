import ProgressBar from 'react-bootstrap/ProgressBar';
import { RiDeleteBin6Line, RiCheckLine, RiCloseFill } from 'react-icons/ri';
import { ImFilePicture } from 'react-icons/im';
import "./inputs.css";
import ReactTooltip from 'react-tooltip';
import { ConfirmationDialog } from './ConfirmationDialog';
import { useState } from 'react';
import { bytesToSize } from '../Utils/commonFunctions'

export default function LinearProgressBar(props) {
  const [show, setShow] = useState(false);
  const [cancelShow, setCancelShow] = useState(false);
  const { fileName, type, uploadedFileName, loading = { "percent": 0, "total": 0, "loaded": 0 } } = props;

  const handleDialog = () => {
    setShow(!show)
  }
  const handleCancelDialog = () => {
    setCancelShow(true);
    props.abortFileUpload();
  }

  const handleCancelYesDialog = () => {
    setCancelShow(false);
    props.cancelUpload(uploadedFileName, type);
    props.handleFileError();
  }

  const handleYes = () => {
    setShow(false);
    props.removeFile(uploadedFileName, type);
  }

  const handleCancelNoDialog = () => {
    setCancelShow(false);
    props.resumeFileUpload();
  }

  const fileLabel = fileName && fileName.length > 46 ? fileName.substring(0, 46) + '...' : fileName;
  return (
    <div className="zip-container">
      {type === "image" ? <ImFilePicture className='zip-upload subText-color' /> : <div className="zip-upload zip-upload-image"></div>}
      <div className='progressbar-content'>
        <div className='progressbar-subcontent'>
          {fileLabel && <><ReactTooltip /><span className='file-name' data-tip={fileName.length > 46 ? fileName : ''}>{fileLabel}</span></>}
          {(loading.percent === 100 && fileName) ?
            <div>
              <RiDeleteBin6Line className='icons cursor-pointer' onClick={handleDialog} />
              <RiCheckLine className='icons' />
            </div>
            :
            <RiCloseFill className='icons' onClick={handleCancelDialog} />
          }
        </div>
        <ProgressBar now={loading.percent} className={loading.percent < 100 ? 'linear-progressbar-loading' : 'linear-progressbar-complete'} />
        <div className='progressbar-subcontent'>
          {loading.total && loading.loaded && <span className="loading-text-size">{`${bytesToSize(loading.loaded)} of ${bytesToSize(loading.total)} `}</span>}
          {loading.percent < 100 && <span className="loading-text-size">{`Uploading...${loading.percent}%`}</span>}
        </div>
        <ConfirmationDialog show={cancelShow || show}
          cancel={cancelShow ? handleCancelNoDialog : handleDialog}
          handleYes={cancelShow ? handleCancelYesDialog : handleYes}
          closeButton={cancelShow ? 'No, continue uploading' : 'Cancel'}
          yesButton={cancelShow ? 'Yes, Cancel' : 'Yes'}
          text={cancelShow ? 'Are you sure you want to cancel this file upload?' :
            'Are you sure you want to delete the uploaded file?'}
          subText={cancelShow && 'If you cancel, you will lose all upload progress. You may start over or select a new image to upload.'} />
      </div></div>)
}
