import { useContext, useEffect, useState } from "react";
import UserContext from "../../UserContext";
import { API_ENDPOINT } from "../literals";
import { courGet } from "../../service/courAPI";
import Select, { components } from "react-select";
import ReactTooltip from 'react-tooltip';
import exportFromJSON from 'export-from-json';
import moment from "moment";
import { Constant } from "../Constant/constant";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { Toast, ToastContainer } from "react-bootstrap";
import danger from '../../images/Vector.png';

const Export = (props) => {
    const userContext = useContext(UserContext);
    const [experienceList, setExperienceList] = useState([]);
    const [experienceId, setExperienceId] = useState();
    const [dateRange, setDateRange] = useState([null, null]);
    const [configuration, setConfiguration] = useState([]);
    const [dateInputValue, setDateInputValue] = useState('');
    const [isErrorDate, setIsErrorDate] = useState(false);
    const [showToastMessage, setShowToastMessage] = useState(false);
    const [toastMessage, setToastMessage] = useState("");

    useEffect(() => {
        if (userContext.role === Constant.ROLE.admin) {
            userContext.setUserMenu([
                {
                    label: 'Course Analytics',
                    to: 'analytics/' + userContext.selectedCourse,
                },
                {
                    label: 'Course Details',
                    to: '/course/' + userContext.selectedCourse,
                },
                {
                    label: 'LTI Configuration',
                    to: '/course/lti/' + userContext.selectedCourse,

                },
                {
                    label: 'Export',
                    to: '/export/' + userContext.selectedCourse,
                }
            ]);
            userContext.setBackUrl([
                {
                    label: "All Organizations",
                    url: '/clients'
                },
                {
                    label: userContext.selectedClientName,
                    url: '/client/' + userContext.selectedClient + '/courses'
                },
                {
                    label: 'Course',
                    url: '/analytics/' + userContext.selectedCourse
                }
            ]);
        }

        if (userContext.role === Constant.ROLE.organization) {
            userContext.setUserMenu([
                {
                    label: 'Course Analytics',
                    to: 'analytics/' + userContext.selectedCourse,
                },
                {
                    label: 'Course Details',
                    to: '/course/' + userContext.selectedCourse,
                },
                {
                    label: 'Export',
                    to: '/export/' + userContext.selectedCourse,
                }
            ]);
            userContext.setBackUrl([
                {
                    label: userContext.selectedClientName,
                    url: '/dashboard'
                },
                {
                    label: 'Course',
                    url: '/analytics/' + userContext.selectedCourse
                }
            ]);
        }

        if (userContext.selectedCourse) {
            getModuleListByExperienceId();
            getCourseById();
            courGet(API_ENDPOINT.GetMetaData())
                .then((res) => {
                    setConfiguration(res.data);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [userContext.selectedCourse]);

    const getModuleListByExperienceId = () => {
        courGet(API_ENDPOINT.GetAllModules(userContext.selectedCourse))
            .then((res) => {
                if (res.data.length > 0) {
                    setExperienceList(res.data);
                } else {
                    setToastMessage("No experience available.");
                    setShowToastMessage(true);
                }

            }).catch((error) => {
                console.log(error);
            })
    }

    const getCourseById = () => {
        courGet(API_ENDPOINT.GetCourseById(userContext.selectedCourse))
            .then((res) => {
                getPresignedURL(res.data.thumbnail, res.data.name);
            }).catch((error) => {
                console.log(error);
            })
    }

    const getPresignedURL = (fileName, courseName) => {
        courGet(API_ENDPOINT.getPreSignedGetUrl(fileName))
            .then((res) => {
                userContext.setThumbnail({ path: res.data, name: courseName })
            }).catch((error) => {
                console.log(error);
            })
    }
    const { Option } = components;
    const customOption = props => {
        return (
            <><ReactTooltip />
                <Option {...props} data-tip={props.label} />
            </>
        );
    };

    const handleExperienceChange = (e) => {
        setExperienceId(e.value)
    }

    const options = () => {
        const optionList = [];
        if (experienceList && experienceList.length > 0) {
            experienceList.map((exp, index) => {
                const option = {
                    value: exp._id, tabValue: exp.name, label: (
                        <><ReactTooltip /><span data-tip={exp.name && exp.name.length > 34 ? exp.name : ''}>{expLabel(exp.name)}</span></>
                    )
                }
                optionList.push(option);
            })
        }
        return optionList;
    }

    const expLabel = (name) => {
        return name && name.length > 34 ? name.substring(0, 34) + '...' : name;
    }

    const customFilter = (option, searchText) => {
        if (
            option.data.tabValue.toLowerCase().includes(searchText.toLowerCase())
        ) {
            return true;
        } else {
            return false;
        }
    }

    const exportData = () => {
        if (experienceId == null || experienceId == undefined) {
            userContext.createToast({ title: "Please, select valid experience", message: "Please, select valid experience", variant: "danger" });
            return;
        }

        if (isErrorDate == false) {
            courGet(API_ENDPOINT.GetExportData(experienceId, dateRange[0], dateRange[1]))
                .then((res) => {
                    const data = [];
                    if (res.data.length > 0) {
                        const experienceName = experienceList.find((x) => x._id === experienceId);
                        res.data.forEach(element => {
                            const completionDate = moment(element.completionDate).format("MM/DD/YYYY");
                            if (element.level != '' && element.level != null && element.level != undefined) {
                                data.push({
                                    'Learner Id': element.userEmail.split('@')[0],
                                    'Experience Name': experienceName ? experienceName.name : '',
                                    'Completed Level': element.level,
                                    'Completion Date': completionDate,
                                    'Mode': element.platform
                                })
                            }
                        });
                        const fileName = experienceName.name;
                        const exportType = 'csv'
                        exportFromJSON({ data, fileName, exportType })
                    }
                    else {
                        userContext.createToast({ title: "Sorry, no data available", message: "Sorry, No data available", variant: "danger" });
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }

    const exportCustomData = () => {
        if (experienceId == null || experienceId == undefined) {
            userContext.createToast({ title: "Please, select valid experience", message: "Please, select valid experience", variant: "danger" });
            return;
        }
        if (isErrorDate == false) {
            courGet(API_ENDPOINT.GetCustomExportData(experienceId, dateRange[0], dateRange[1]))
                .then((res) => {
                    const data = [];
                    if (res.data.length > 0) {
                        const experienceName = experienceList.find((x) => x._id === experienceId);
                        res.data.forEach(element => {
                            if (element.objectLevel != '' && element.objectLevel != null && element.objectLevel != undefined) {
                                data.push({
                                    'Learner Id': element.userEmail.split('@')[0],
                                    'Course Id': element.courseId ? element.experienceId : '',
                                    'Experience Id': element.experienceId ? element.experienceId : '',
                                    'Experience Name': experienceName ? experienceName.name : '',
                                    'Level': element.objectLevel ? element.objectLevel : "",
                                    // 'Completion Date': completionDate,
                                    // 'Mode': element.platform
                                    'Action Type': element.actiontype ? element?.actiontype : "",
                                    'Object': element.object ? element?.object : "",
                                    'Timestamp': element.timestampObject ? element?.timestampObject.toString() : "",
                                    'Ip': element.ip ? element?.ip : "",
                                    'Device': element.device ? element.device : "",
                                    'Platform': element.platform ? element.platform : "",
                                    'OS': element.os ? element.os : "",
                                    'Browser': element.browser ? element.browser : "",
                                    'Time Spent': element.timeSpent ? element.timeSpent : "",
                                })
                            }
                        });
                        if (data.length > 0) {
                            const fileName = experienceName.name;
                            const exportType = 'csv'
                            exportFromJSON({ data, fileName, exportType })
                        } else {
                            userContext.createToast({ title: "Sorry, no data available", message: "Sorry, No data available", variant: "danger" });
                        }
                    }
                    else {
                        userContext.createToast({ title: "Sorry, no data available", message: "Sorry, No data available", variant: "danger" });
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }

    const handleApply = (event, picker) => {
        setIsErrorDate(false);
        const pickerVal = picker.startDate.format('MM/DD/YYYY') +
            ' - ' +
            picker.endDate.format('MM/DD/YYYY')
        picker.element.val(pickerVal);
        setDateInputValue(pickerVal);

        const monthRange = (configuration.length && configuration[0]['monthRange']) ? configuration[0]['monthRange'] : 6;
        const startDate = new Date(picker.startDate);
        const endDate = new Date(picker.endDate);

        const sixMonthsLater = new Date(startDate);
        sixMonthsLater.setMonth(sixMonthsLater.getMonth() + monthRange);

        if (endDate && endDate > sixMonthsLater) {
            setIsErrorDate(true);
        }
        setDateRange([new Date(picker.startDate), new Date(picker.endDate)]);

    };


    const handleCancel = (event, picker) => {
        picker.element.val('');
        setDateInputValue('');
        setDateRange([null, null]);

    };

    return <>
        <div className="organization-owners">
            {experienceList && experienceList.length > 0 &&
                <>
                    <div className="exp-dropdown-div" style={{ "justifyContent": "start" }}>
                        <div className="exp-buttons" >
                            <span className="learner-text">Experience</span>
                            <Select
                                components={{
                                    Option: customOption
                                }}
                                className="select-exp-dropDown"
                                classNamePrefix="select"
                                onChange={handleExperienceChange}
                                options={options()}
                                styles={{
                                    control: (provided, state) => ({
                                        ...provided,
                                        minHeight: '40px',
                                        height: '40px',
                                        boxShadow: state.isFocused ? null : null,
                                    }),
                                    valueContainer: (provided, state) => ({
                                        ...provided,
                                        height: '40px',
                                        padding: '0 15px'
                                    }),
                                    input: (provided, state) => ({
                                        ...provided,
                                        margin: '0px',
                                    }),
                                    indicatorSeparator: state => ({
                                        display: 'none',
                                    }),
                                    indicatorsContainer: (provided, state) => ({
                                        ...provided,
                                        height: '40px',
                                    }),
                                }}
                                isSearchable={true}
                                value={
                                    options().filter(option =>
                                        option.value === experienceId)
                                }
                                filterOption={customFilter}
                            />
                        </div>
                    </div>
                    <div className="exp-dropdown-div" style={{ "justifyContent": "start", "marginTop": "20px" }}>
                        <div className="exp-buttons position-relative" >
                            <span className="learner-text">Date range</span>

                            <DateRangePicker
                                initialSettings={{
                                    autoUpdateInput: false,
                                    locale: {
                                        cancelLabel: 'Clear',
                                    },
                                }}
                                onApply={handleApply}
                                onCancel={handleCancel}
                            >

                                <input type="text" className={`form-control calendar-export ${isErrorDate ? "error" : ""}`} placeholder="Start date - End date" value={dateInputValue} defaultValue="" />

                            </DateRangePicker >

                        </div>
                    </div>
                    {configuration && configuration.length > 0 && isErrorDate &&
                        <div className="export-warning error-message" >Duration max {configuration[0]['monthRange']} months</div>
                    }
                    <button className="w-auto px-5 btn btn-primary me-3" onClick={() => exportData()} style={{ 'marginTop': '30px' }}>Export</button>
                    <button className="w-auto px-5 btn btn-primary " onClick={() => exportCustomData()} style={{ 'marginTop': '30px' }}>Custom Export</button>
                </>
            }
            {showToastMessage === true &&
                <ToastContainer className="p-3" position={"top-center"} >
                    <Toast
                        className="toast-message"
                        bg="danger"
                        key="2">
                        <Toast.Body className="toast-text"> <img src={danger} className="me-2" />
                            {toastMessage}
                        </Toast.Body>
                    </Toast>
                </ToastContainer>
            }
        </div >

    </>
}

export default Export;