import React, { useContext, useEffect, useState } from "react";
import { courGet } from "../../service/courAPI";
import { API_ENDPOINT } from "../literals";
import UserContext from "../../UserContext";
import DeleteUser from "./DeleteUser";
import avatar from '../../images/icon_avatar.png'

const MemberItem = ({ member, refreshMembers, isRemove }) => {
    const [user, setUser] = useState(member);
    const userContext = useContext(UserContext);
    const [profileImage, setProfileImage] = useState("")

    useEffect(() => {
        if (member.picture && member.picture != "") {
            if (member.picture) {
                courGet(API_ENDPOINT.getPreSignedGetUrl(member.picture))
                    .then((res) => {
                        setProfileImage(res.data);
                    }).catch((error) => {
                        console.log(error);
                    })
            }
        } else {
            setProfileImage(avatar)
        }
    }, []);



    return (<>
        {userContext._id !== user._id &&
            < div className="member-item" >
                <div className="member-image">
                    <img src={profileImage} alt="member" />
                    {isRemove &&
                        <div className="member-delete">
                            <DeleteUser user={user} refreshMembers={refreshMembers} />
                        </div>
                    }
                </div>
                <div className="member-details">
                    <div className="member-name">{user.firstName + " " + user.lastName}</div>
                    <div className="member-role">{user.email}</div>
                </div>
            </div>
        }
    </>
    );
};

export default MemberItem;