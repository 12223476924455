import React, { useEffect, useRef, useState } from "react";
import UserContext from "../../UserContext";
import CustomButton from "../Inputs/Button";
import InputBox from "../Inputs/InputBox";
import PasswordInput from "../Inputs/PasswordInput";
import { API_ENDPOINT } from "../literals";
import { courDelete, courGet, courPut } from "../../service/courAPI";
import "./ProfileUpdate.css";
import axios from "axios";
import { v4 as uuidv4 } from 'uuid';
import ImageCropDialog from "../Inputs/ImageCropDialog";
import arrow from '../../images/Arrow.svg';
import avatar from '../../images/icon_avatar.png';
import bin from '../../images/Bin.png';
import { Modal } from "react-bootstrap";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";


const ProfileUpdate = (props) => {
    const contextUser = React.useContext(UserContext);
    const [user, setUser] = React.useState(contextUser);
    const [rePassword, setRePassword] = useState("");
    const [password, setPassword] = useState("");
    const [currentPassword, setCurrentPassword] = useState("");
    const [error, setError] = React.useState(false);
    const [errorText, setErrorText] = React.useState("");
    const [selectedTab, setTab] = useState(0);
    const [file, setFile] = useState('');
    const [fileName, setFileName] = useState('');
    const [profileUrl, setProfileUrl] = useState('');
    const [isIncorrectPasswordError, setIsIncorrectPasswordError] = useState(false);
    const [incorrectPasswordText, setIncorrectPasswordText] = useState("");
    const inputRef = useRef(null);
    const [showImageCrop, setShowImageCrop] = useState(false);
    const [showImageDelete, setShowImageDelete] = useState(false);

    const handleClick = () => {
        // 👇️ open file input box on click of other element
        inputRef.current.click();
    };


    useEffect(() => {
        if (contextUser.email) {
            setUser(contextUser);
        }
    }, [contextUser]);

    useEffect(() => {
        if (contextUser.picture && contextUser.picture !== "") {
            if (contextUser.picture) {
                courGet(API_ENDPOINT.getPreSignedGetUrl(contextUser.picture))
                    .then((res) => {
                        setProfileUrl(res.data);
                    }).catch((error) => {
                        console.log(error);
                    })
            }
        } else {
            setProfileUrl(avatar)
        }
    }, [contextUser])

    const handleSubmit = (e) => {
        e.preventDefault();
        setError(false);
        setErrorText("");
        if (password !== rePassword) {
            setError(true);
            setErrorText("Password does not match. Please try again.");
            return;
        }
        var data = {};
        if (selectedTab === 0) {

            data = {
                _id: user._id,
                firstName: user.firstName,
                lastName: user.lastName,
                picture: user.picture,
                isPictureUpdated: fileName !== '' || user.picture == '' ? true : false
            }

            courPut(API_ENDPOINT.UpdateUserProfile(user._id), data)
                .then((res) => {
                    props.setEditProfile(false);
                    contextUser.setUser(res.data);
                    contextUser.createToast({ title: "Profile Updated", message: "Profile Updated.", variant: "Success" });
                    const localUser = JSON.parse(localStorage.getItem('user'));
                    localStorage.setItem("user", JSON.stringify({ ...localUser, firstName: res.data.firstName, lastName: res.data.lastName, picture: res.data.picture }));
                })
                .catch((err) => {
                    console.log(err);
                })
        } else if (selectedTab === 1) {
            data = {
                _id: user._id,
                password: password,
                currentPassword: currentPassword
            }

            courPut(API_ENDPOINT.UpdateUserPassword(user._id), data)
                .then((res) => {
                    if (res.data.message === "Incorrect current password") {
                        setIsIncorrectPasswordError(true);
                        setIncorrectPasswordText("Incorrect current password");
                    } else {
                        props.setEditProfile(false);
                        contextUser.createToast({ title: "Password Updated", message: "Password Updated.", variant: "Success" });
                        logout();
                    }
                })
                .catch((err) => {
                    console.log(err);
                })
        }
    };

    const logout = () => {
        contextUser.logout();
    }

    const uploadFile = (fileData) => {
        setError('')

        if (fileData) {
            const file = new File([fileData], fileName);
            courGet(API_ENDPOINT.getPreSignedPutUrl(fileName))
                .then((res) => {
                    if (res.data) {
                        const fileExt = fileName.split('.')[1];
                        axios.put(res.data, file,
                            {
                                headers: { 'Content-Type': 'image/' + fileExt },
                            }
                        ).then((response) => {
                            setProfileUrl(URL.createObjectURL(fileData));
                            setUser({ ...user, picture: fileName });
                            setShowImageCrop(false);
                        }).catch(error => {
                            setError('File could not be uploaded for some reason.');
                            setFile('');
                            console.log(error);
                        });
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }

    const handleImageCrop = (e) => {
        var fileData = e.target.files[0];
        inputRef.current.value = null;
        setError('')
        if (fileData) {
            if (fileData.type !== "image/jpeg" && fileData.type !== "image/png" && fileData.type !== "image/jpg") {
                contextUser.createToast({ message: "File type must be jpeg, png, jpg", variant: "danger" });
            } else if (Math.round((fileData.size / 1024)) > 1024) {
                contextUser.createToast({ message: "File is larger than 1 MB", variant: "danger" });
            } else {
                setFile(URL.createObjectURL(fileData));
                setFileName(uuidv4() + '-' + fileData.name.toLowerCase().split(' ').join(''));
                setShowImageCrop(true);
            }
        }
    }

    const removeFile = (fileName) => {
        courDelete(API_ENDPOINT.ImageFileDelete(fileName))
            .then(res => {
                setFileName('');
            }).catch(error => {
                setError('Not able to delete file');
            })
    };

    const handleUpdateProfileBackButton = () => {
        props.setEditProfile(false);
        if (fileName !== '') {
            removeFile(fileName);
        }
    }

    const handleBackButton = () => {
        setShowImageCrop(false);
        setFile([]);
        setFileName('');
    }

    const handleImageDelete = () => {
        setShowImageDelete(false);
        setProfileUrl(avatar);
        setUser({ ...user, picture: '' });
        setFileName('')
    }
    return <>
        <div className="edit-profile-tabs gap-5">
            <div className={selectedTab === 0 ? "profile-tab-name profile-selected-tab" : "profile-tab-name"} onClick={() => setTab(0)}>Personal Info
                {selectedTab === 0 && <div className="profile-underline-div"><div className="profile-underline"></div></div>}
            </div>
            <div className={selectedTab === 1 ? "profile-tab-name profile-selected-tab" : "profile-tab-name"} onClick={() => setTab(1)}>Password
                {selectedTab === 1 && <div className="profile-underline-div"><div className="profile-underline"></div></div>}
            </div>
        </div>
        <form onSubmit={handleSubmit}>
            {selectedTab === 0 && <>
                <div className="upload-heading subText-color mb-3">Edit Personal Information</div>
                <div className="user-profile-picture">
                    <div className="d-flex justify-content-left align-items-center">
                        <div className="user-edit-profile-email">{user.email}</div>
                    </div>
                </div>

                <div className="d-flex gap-3 ">
                    <div className="profile-left">
                        <div className="profile-input-heading subText-color mb-2 text-start">First Name</div>
                        <InputBox
                            label="First Name"
                            value={user.firstName}
                            onChange={(e) => setUser({ ...user, firstName: e.target.value })}
                            required
                        />
                        <div className="profile-input-heading subText-color mb-2 text-start">Last Name</div>
                        <InputBox
                            label="Last Name"
                            value={user.lastName}
                            onChange={(e) => setUser({ ...user, lastName: e.target.value })}
                            required
                        />
                    </div>
                    <div className="profile-right ">
                        <div className="user-profile-container mb-2">
                            <img src={profileUrl} alt="profile thumbnail" className="profile-thumbnail" />
                            <div className="member-edit" onClick={handleClick}>
                                <i className="fa fa-pencil" />
                            </div>

                        </div>
                        <input id="image-upload" type="file" accept="image/png, image/jpg, image/jpeg" ref={inputRef} onChange={(e) => handleImageCrop(e)} style={{ display: 'none' }} />

                        <div className="profile-info">JPG or PNG. Max</div>
                        <div className="profile-info mb-2">file size: 1 MB</div>
                        {user.picture && user.picture !== '' && <div className="d-flex"><img src={bin} height="18px" width="18px" className=" me-2" alt="delete thumbnail" /><div className="profile-btn-link" onClick={() => { setShowImageDelete(true); }}>Delete Photo</div></div>}
                    </div>
                </div>
            </>
            }

            {selectedTab === 1 &&
                <>
                    <div className="upload-heading subText-color mb-5">Edit Password</div>
                    <PasswordInput
                        label="Current Password"
                        value={currentPassword}
                        onChange={(e) => {
                            setCurrentPassword(e.target.value); setIsIncorrectPasswordError(false);
                            setIncorrectPasswordText("");
                        }}
                        error={isIncorrectPasswordError}
                        errorText={incorrectPasswordText}
                        required
                    />
                    <PasswordInput
                        label="Enter New Password"
                        value={rePassword}
                        onChange={(e) => {
                            setRePassword(e.target.value); setError(false);
                            setErrorText("");
                        }}
                        required
                    />

                    <PasswordInput
                        label="Confirm New Password"
                        value={password}
                        onChange={(e) => {
                            setPassword(e.target.value); setError(false);
                            setErrorText("");
                        }}
                        error={error}
                        errorText={errorText}
                        required
                    />
                </>
            }
            <div className="button-wrapper">
                <CustomButton variant="link-secondary" className="w-auto text-normal back-btn" onClick={handleUpdateProfileBackButton}>
                    <HiOutlineArrowNarrowLeft className="back-arrow me-2" /><span className="cancelbtn">Cancel</span>
                </CustomButton>
                <CustomButton type="submit" className="w-auto px-5" >Update </CustomButton>
            </div>

        </form >

        <ImageCropDialog show={showImageCrop}
            cancel={handleBackButton}
            file={file}
            handleYes={uploadFile}
            aspect={1}
            title={'Profile photo'}
        />

        <Modal
            show={showImageDelete}
            centered>

            <Modal.Body className="crop-model">
                <div className='delete-image-title '>Delete photo</div>
                <div className="crop-container mb-2">
                    <img src={profileUrl} style={{ "height": "100%" }} />
                </div>
                <div className="delete-image-text">
                    Are you sure you want to remove your photo?
                </div>
                <div className="delete-image-text mb-4">
                    We’ll replace it with a default avatar.
                </div>
                <div className='content-model'>
                    <CustomButton
                        variant="link-secondary"
                        className="w-auto text-normal back-btn"
                        onClick={() => setShowImageDelete(false)}>
                        <HiOutlineArrowNarrowLeft className="back-arrow me-2" /><span className="cancelbtn">Cancel</span>
                    </CustomButton>
                    <CustomButton type="submit" className="w-auto " onClick={handleImageDelete}>Yes, Delete Photo</CustomButton>

                </div>
            </Modal.Body>
        </Modal>
    </>
}

export default ProfileUpdate;