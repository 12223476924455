import React, { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import CustomButton from "../Inputs/Button";
import InputBox from "../Inputs/InputBox";
import Multiselect from 'multiselect-react-dropdown';
import UserContext from "../../UserContext";
import { Constant } from "../Constant/constant";
import { courGet, courPut } from "../../service/courAPI";
import { API_ENDPOINT } from "../literals";
import DeleteUser from "./DeleteUser";
import arrow from '../../images/Arrow.svg'
import avatar from '../../images/icon_avatar.png'
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";


const PartnerItem = ({ member, refreshMembers, experienceList, isRemove }) => {
    const [show, setShow] = useState(false);
    const [user, setUser] = useState(member)
    const userContext = useContext(UserContext);
    const [selectedCourse, setSelectedCourse] = useState([]);
    const [profileImage, setProfileImage] = useState("");
    const [error, setError] = useState(false);

    useEffect(() => {
        setSelectedCourse(experienceList.filter(x => member.experience.filter(y => x._id == y).length > 0));
    }, [experienceList, member])

    useEffect(() => {
        if (member.picture && member.picture !== "") {
            if (member.picture) {
                courGet(API_ENDPOINT.getPreSignedGetUrl(member.picture))
                    .then((res) => {
                        setProfileImage(res.data);
                    }).catch((error) => {
                        console.log(error);
                    })
            }
        } else {
            setProfileImage(avatar);
        }
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        setError(false);
        if (selectedCourse.length == 0) {
            setError(true);
            return;
        }

        let courseList = [];
        selectedCourse.forEach((element) => {
            courseList.push(element._id);
        });
        let data = {
            _id: user._id,
            inviteBy: userContext.email,
            firstName: user.firstName,
            lastName: user.lastName,
            experience: courseList
        }

        courPut(API_ENDPOINT.UpdatePartner(user._id), data)
            .then((res) => {
                setShow(false);
                refreshMembers();
                userContext.createToast({ title: "User updated", message: "User updated", variant: "success" });
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const handelOnClick = () => {
        if (Constant.ROLE.partner != userContext.role && !isRemove) {
            setShow(true);
        }
    }

    return (<>
        {userContext._id !== user._id &&
            <div className={Constant.ROLE.partner === userContext.role || isRemove ? "member-item" : "member-item cursor-pointer"} onClick={() => handelOnClick()}>
                <div className="member-image">
                    <img src={profileImage} alt="member" />
                    {isRemove &&
                        <div className="member-delete">
                            <DeleteUser user={user} refreshMembers={refreshMembers} setShowPrevious={setShow} courseList={selectedCourse} />
                        </div>
                    }
                    {Constant.ROLE.partner != userContext.role && !isRemove && <div className="member-edit" >
                        <i className="fa fa-pencil" />
                    </div>
                    }

                </div>
                <div className="member-details">
                    <div className="member-name">{user.firstName + " " + user.lastName}</div>
                    <div className="member-role">{user.email}</div>
                </div>
            </div >
        }

        <Modal
            show={show}
            fullscreen={true}
            animation={false}
            centered
            size="lg"
        >
            <Modal.Body className="invite-owner">
                <form onSubmit={(e) => handleSubmit(e)}>
                    <div className="user-profile-picture">
                        <div className="d-flex justify-content-left align-items-center">
                            <div className="user-profile-container me-2">
                                <img src={profileImage} alt="member" />
                                {/* <div className="member-edit">
                                    <i className="fa fa-pencil" />
                                </div> */}
                            </div>
                            <div className="user-edit-profile-email">{user.email}</div>
                        </div>

                    </div>

                    {/* <div className="upload-heading subText-color mb-2 text-start">Name</div>
                    <div className="d-flex gap-3 mb-3">
                        <InputBox
                            label="First Name"
                            value={user.firstName}
                            onChange={(e) => setUser({ ...user, firstName: e.target.value })}
                            required
                        />
                        <InputBox
                            label="Last Name"
                            value={user.lastName}
                            onChange={(e) => setUser({ ...user, lastName: e.target.value })}
                            required
                        />
                    </div> */}
                    <div className="upload-heading subText-color mb-2 text-start">Courses</div>
                    <Multiselect
                        options={experienceList} // Options to display in the dropdown
                        selectedValues={selectedCourse} // Preselected value to persist in dropdown
                        onSelect={(e) => { setSelectedCourse(e); setError(false); }} // Function will trigger on select event
                        onRemove={(e) => { setSelectedCourse(e); setError(false); }} // Function will trigger on remove event
                        displayValue="name" // Property name to display in the dropdown options
                        showCheckbox={true}
                        placeholder="Which courses can this partner access?"
                        required
                    />
                    {error && <div className="error-message">Please select at least one course</div>

                    }
                    <div className="button-wrapper mt-4">
                        <CustomButton variant="link-secondary" className="w-auto text-normal back-btn" onClick={() => setShow(false)}>
                            <HiOutlineArrowNarrowLeft className="back-arrow me-2" /><span className="cancelbtn">Cancel</span>
                        </CustomButton>
                        <CustomButton type="submit" className="w-auto px-5">Update</CustomButton>
                    </div>
                </form>
            </Modal.Body>
        </Modal>


    </>
    );
};

export default PartnerItem;