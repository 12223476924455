import { Modal } from "react-bootstrap";
import { BackButton } from "./BackButton";
import { RiDeleteBin6Line } from 'react-icons/ri';
import { IoIosWarning } from 'react-icons/io';
import InputBox from "../Inputs/InputBox";
import deleteIcon from '../../images/deleteIcon.svg';

const DeleteItem = ({ error, show, step, type, name, ...props }) => {

    return (
        <Modal show={show} fullscreen={true}>
            <Modal.Body className="invite-owner">
                <div className='delete-div'>
                    <div className="div-flex-model">
                        <img src={deleteIcon} className="" />
                        <h1 className="text-header content-div header-color">
                            {step === 0 ? `Delete this ${type}` :
                                `Are you sure you want to delete this ${type}?`}
                        </h1>
                    </div>
                    {step === 0 ?
                        <>
                            <span className="version-title delete-subText">{`Are you sure you want to delete this ${type}?`}</span>
                            <div className="version-item warning-bg warning-delete">
                                <div>
                                    <div className="warning-div">
                                        <IoIosWarning className="warning-icon" />
                                        <span className="delete-subText-warning">Warning</span>
                                    </div>
                                    <span className="warningText">{`This will delete all analytics associated with this ${type}.`} <b>This action cannot be undone.</b></span>
                                </div>
                            </div>
                        </> :
                        <>
                            <span className="warningText">Confirm by typing <b>{name}</b> below.</span>
                            <InputBox
                                id='name'
                                name="name"
                                type="text"
                                className="input-div"
                                value={props.typeName}
                                onChange={(e) => props.handleOnChange(e)}
                                required />
                        </>
                    }
                    <BackButton handleBackButton={props.handleBackButton}
                        handleOnClick={props.handleDeleteStep}
                        classes={step === 1 && (name !== props.typeName || props.disable) ? 'disabled' : ''}
                        buttonName={props.disable ? 'Processing...' : (step === 0 ? 'Yes, Delete' : 'Delete Forever')} />
                    {error && <div className="error" >{error}</div>}
                </div>
            </Modal.Body>
        </Modal>);
}

export default DeleteItem;