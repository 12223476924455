import React, { useEffect } from "react";
import { Buffer } from 'buffer';
import PasswordInput from "../Inputs/PasswordInput";
import CustomButton from "../Inputs/Button";
import { useNavigate, useParams } from "react-router-dom";
import TermsAndConditions from "./TermsAndConditions";
import { API_ENDPOINT } from "../literals";
import { courPut } from "../../service/courAPI";


const CreatePassword = (props) => {
    const navigate = useNavigate();
    const [userId, setUserId] = React.useState("");
    const [firstName, setFirstName] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [rePassword, setRePassword] = React.useState("");
    const [error, setError] = React.useState(false);
    const [errorText, setErrorText] = React.useState("");
    const [checked, setChecked] = React.useState(false);
    const params = useParams();

    useEffect(() => {
        // read token from url with hashRouter
        const token = params.token;
        let data = Buffer.from(token, 'base64').toString('ascii');
        let userData = JSON.parse(data);
        setUserId(userData._id);
        setFirstName(userData.firstName);
        setEmail(userData.email);
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        setError(false);
        setErrorText("");
        if (password !== rePassword) {
            setError(true);
            setErrorText("Password does not match. Please try again.");
            return;
        }
        const data = {
            _id: userId,
            password: password
        }

        courPut(API_ENDPOINT.UpdateUserPassword(userId), data)
            .then((res) => {
                if (res.data) {
                    navigate("/login");
                }
            })
            .catch((err) => {
                console.log(err);
            })

    };

    return (

        <div className="login-wrapper row">
            <div className="left-side col-6 justify-content-between">
                <div />
                <div className="c-wrapp">
                    <div className="welcome" >Welcome to</div>
                    <h4 className="brand-name">SweetRush <br />XR Platform</h4>

                </div>
                <div className="brand-logo" />
            </div>
            <div className="right-side col-6">

                <div className="c-wrapp">
                    <h1 className="login-text">Welcome, {firstName}</h1>
                    <form onSubmit={(e) => handleSubmit(e)}>
                        <div className="user-email">{email}</div>

                        <PasswordInput
                            label="Create Password"
                            value={rePassword}
                            onChange={(e) => {
                                setRePassword(e.target.value); setError(false);
                                setErrorText("");
                            }}
                            required
                        />

                        <PasswordInput
                            label="Repeat Password"
                            value={password}
                            onChange={(e) => {
                                setPassword(e.target.value); setError(false);
                                setErrorText("");
                            }}
                            required
                            error={error}
                            errorText={errorText}
                        />

                        <TermsAndConditions checked={checked} setChecked={setChecked} />

                        <CustomButton type="submit" disabled={!checked} className="btn btn-primary btn-block w-100">Sign Up</CustomButton>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default CreatePassword;
