import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { courGet } from "../../service/courAPI";
import danger from '../../images/Vector.png';
import { Toast, ToastContainer } from "react-bootstrap";
import { API_ENDPOINT } from "../literals";

const VerifyAr = () => {
    const params = useParams();
    const [code, setCode] = useState();
    const [showToastMessage, setShowToastMessage] = useState(false)
    const [toastMessage, setToastMessage] = useState("");
    useEffect(() => {
        setCode(params.code);
    }, []);

    useEffect(() => {
        if (code) {
            verifyCode()
        }
    }, [code])

    const verifyCode = () => {

        courGet(API_ENDPOINT.VerificationQRCode(code))
            .then((res) => {
                if (res.data.message === "invalid code") {
                    setToastMessage("Code is expired");
                    setShowToastMessage(true);
                } else {
                    setToastMessage("");
                    window.open(res.data.launchURL, '_self');

                }
            }).catch((error) => {
                console.log(error);
            })
    }
    return <div className="create-code-container">
        {showToastMessage === true &&
            <ToastContainer className="p-3" position={"top-center"} >
                <Toast
                    className="toast-message"
                    bg="danger"
                    key="2">
                    <Toast.Body className="toast-text"> <img src={danger} className="me-2" />
                        {toastMessage}
                    </Toast.Body>
                </Toast>
            </ToastContainer>
        }
    </div>
}

export default VerifyAr