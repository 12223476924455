import React, { useCallback, useEffect, useState } from 'react';
import './App.css';
import './components/shared/shared-style.css';
import axios from 'axios';
import UserContext from './UserContext';
import { Routes, Route, Navigate, useLocation, Outlet, } from "react-router-dom";

import Login from './components/Login/Login';
import CreatePassword from './components/Registration/CreatePassword';
import Dashboard from './components/Dashboard/Dashboard';
import InviteExpred from './components/shared/InviteExpred';
import PlatformAdmins from './components/SuperAdmin/PlatformAdmins';
import OrganizationOwners from './components/SuperAdmin/OrganizationOwners';
import AdminCourses from './components/SuperAdmin/AdminCourses';
import AllClients from './components/SuperAdmin/AllClients';
import ResetPassword from './components/Registration/ResetPassword';
import SidebarLayout from './components/shared/SidebarLayout';
import FullLayout from './components/shared/FullLayout';
import HeaderLayout from './components/shared/HeaderLayout';
import CourseDetails from './components/Course/CourseDetails';
import AnalyticsDetails from './components/Analytics/AnalyticsDetails';
import { Text } from "./components/Constant/string";
import ToastMessage from './components/shared/ToastMessage';
import ModuleDetails from './components/Course/ModuleDetails';
import SelectDevice from './components/CourseLaunch/SelectDevice';
import CreateLaunchCode from './components/CourseLaunch/CreateLaunchCode';
import setThemeColor from './components/Utils/setThemeColor';
import OrganizationProfile from './components/SuperAdmin/OrganizationProfile';
import { Constant } from './components/Constant/constant';
import LtiConfiguration from './components/CourseLaunch/LtiConfiguration';
import LaunchLayout from './components/shared/LaunchLayout';
import Export from './components/SuperAdmin/Export';
import LtiCourseConfigurations from './components/LtiCourseConfigurations/LtiCourseConfigurations';
import ScannerAR from './components/CourseLaunch/ScannerAR';
import VerifyAr from './components/CourseLaunch/VerifyAr';


axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL;

axios.defaults.headers = {
  'Content-Type': 'application/json',
  'X-Content-Type-Options': 'nosniff',
  'Content-Security-Policy': "default-src 'self'",
  'X-Frame-Options': "deny",
  "Strict-Transport-Security": "max-age=31536000; includeSubDomains; preload",
  "withCredentials": true
};

// userContext.createToast({ title: "Success", message: "Success.", variant: "success" });
// userContext.createToast({ title: "Error", message: "Something went wrong", variant: "danger" });
function App() {
  const [tokenExpirationDate, setTokenExpirationDate] = useState();
  const [user, setUser] = useState({ email: "", firstName: "", lastName: "", role: "", token: "" });
  const [selectedClient, setSelectedClient] = useState("");
  const [selectedClientName, setSelectedClientName] = useState("");

  const [selectedCourse, setSelectedCourse] = useState("");
  const [selectedCourseName, setSelectedCourseName] = useState("");
  const [backUrl, setBackUrl] = useState([]);
  const [thumbnail, setThumbnail] = useState({});
  const [toastList, setToastList] = useState([]);
  const [isAuthenticate, setIsAuthenticate] = useState(true);
  const [userMenu, setUserMenu] = useState([
    {
      label: `${Text.allClients}`,
      to: '/clients',
    },
    {
      label: 'Platform Admins',
      to: '/platform-admins',
    },
  ]);



  useEffect(() => {
    // get selected client from local storage
    const selectedClient = localStorage.getItem('selectedClient');
    if (selectedClient) {
      setSelectedClient(selectedClient);
    }

    const selectedClientNameLocal = localStorage.getItem('selectedClientName');
    if (selectedClientNameLocal) {
      setSelectedClientName(selectedClientNameLocal);
    }

    // get selected Experience from local storage
    const selectedCourseNameLocal = localStorage.getItem('selectedCourseName');
    if (selectedCourseNameLocal) {
      setSelectedCourseName(selectedCourseNameLocal);
    }

    const selectedCourseLocal = localStorage.getItem('selectedCourse');
    if (selectedCourseLocal) {
      setSelectedCourse(selectedCourseLocal);
    }

    //get user details from local storage 
    const localUser = JSON.parse(localStorage.getItem('user'));
    if (user) {
      setUser(localUser);
      if ((localUser?.role === Constant.ROLE.partner || localUser?.role === Constant.ROLE.organization) && localUser.primary != "" && localUser.secondary != "") {
        setThemeColor({ primary: localUser.primary, secondary: localUser.secondary, btnHover: localUser.secondary });
      }
    }

  }, []);

  useEffect(() => {
    if (selectedClient) {
      localStorage.setItem('selectedClient', selectedClient);
    }
    if (selectedCourse) {
      localStorage.setItem('selectedCourse', selectedCourse);
    }

    if (selectedClientName) {
      localStorage.setItem('selectedClientName', selectedClientName);
    }

    if (selectedCourseName) {
      localStorage.setItem('selectedCourseName', selectedCourseName);
    }
  }, [selectedClient, selectedCourse, selectedClientName, selectedCourseName]);

  const logout = () => {
    window.location.href = "/";
    localStorage.removeItem('user');
    localStorage.clear();
    //setUser(null);
  }


  const clearUserData = () => {
    setUser(null);
    localStorage.removeItem('user');
  }

  const createToast = (toast) => {
    let newToast = {
      ...toast,
      id: Math.random().toString(36).replace('0.', 'toast-' || ''),
      title: toast.title || "Message",
      message: toast.message || "Something happened",
      show: true,
      variant: toast.variant || 'light',
      timeout: toast.timeout || 2000,
    }
    setToastList([...toastList, newToast]);
  }

  const checkAuth = () => {
    let token = JSON.parse(localStorage.getItem('user'));
    if (token !== null) {
      token = token.token;
    }
    setIsAuthenticate(token && token !== "" ? true : false);
  }

  useEffect(() => {
    checkAuth();
  }, []);



  return (<>
    {/* <Helmet>
      <meta http-equiv="Content-Security-Policy" content="default-src 'self'" />
      <meta http-equiv="X-Frame-Options" content="deny" />
      <meta http-equiv="X-Content-Type-Options" content="nosniff" />
      <meta http-equiv="Strict-Transport-Security" content="max-age=31536000; includeSubDomains; preload" />
    </Helmet> */}


    < div className='app-container' >


      <UserContext.Provider value={{
        ...user,
        setUserMenu,
        selectedClient,
        setSelectedClient,
        selectedClientName,
        setSelectedClientName,
        selectedCourse,
        setSelectedCourse,
        selectedCourseName,
        setSelectedCourseName,
        setUser,
        backUrl,
        setBackUrl,
        logout,
        clearUserData,
        thumbnail,
        setThumbnail,
        createToast,
        checkAuth,
        setThemeColor
      }}>
        <Routes>

          <Route element={<SidebarLayout userMenu={userMenu} thumbnail={thumbnail} isAuthenticate={isAuthenticate} />}>
            <Route path="/clients" element={<AllClients />} />
            <Route path="/client/:clientId/courses" element={<AdminCourses />} />
            <Route path='/analytics/:experienceId/' element={<AnalyticsDetails />} />
            <Route path="/client/:clientId/users" element={<OrganizationOwners />} />
            <Route path="/course/:id" element={<CourseDetails />} />
            <Route path="/course/lti/:id" element={<LtiCourseConfigurations />} />
            <Route path="/platform-admins" element={<PlatformAdmins />} />
            <Route path="/coursera-experience/:client" element={<OrganizationOwners />} />
            <Route path='/organization-details' element={<OrganizationProfile />} />
            <Route path='/export/:id' element={<Export />} />
          </Route>

          <Route element={<HeaderLayout userMenu={userMenu} isAuthenticate={isAuthenticate} />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/course/:id/:module" element={<ModuleDetails />} />
          </Route>

          <Route element={<LaunchLayout />} >
            <Route path="/launchAR/:data" element={<ScannerAR />} />
            <Route path="/launch/:data" element={<SelectDevice />} />
            <Route path="/create-launch-code/:data" element={<CreateLaunchCode />} />
            <Route path="/verification-ar/:code" element={<VerifyAr />} />
          </Route>

          <Route element={<FullLayout />} >
            <Route path="/create-password/:token" element={<CreatePassword setUser={setUser} />} />
            <Route path="/reset-password/:token" element={<ResetPassword setUser={setUser} />} />
            <Route path="/invite-expired" element={<InviteExpred />} />
            <Route path="/login" element={<Login setUser={setUser} />} />
            <Route path="/lti" element={<LtiConfiguration />} />
            <Route path="*" element={<Navigate to="/login" replace />} />
          </Route>

        </Routes>
      </UserContext.Provider>
      <ToastMessage toastList={toastList} setToastList={setToastList} />

    </div >
  </>
  );
}

export default App;
