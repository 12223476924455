import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { Text } from "../Constant/string";
import { BackButton } from '../Course/BackButton';

export const ConfirmationDialog = ({ show = false,
  title = '',
  text = 'Are you sure?',
  closeButton = Text.dialogBackButtonText,
  yesButton = 'Yes',
  ...props }) => {
  return (
    <Modal
      dialogClassName="confirmation-dialog"
      show={show}
      centered>
      {title && <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      }
      <Modal.Body className="confirm-model">
        <h3 className="confirm-title">{text}</h3>
        <span className='subText-model'>{props.subText}</span>
        <div className='content-model'>
          <BackButton
            handleBackButton={props.cancel}
            cancelBtnName={closeButton}
            buttonName={yesButton}
            handleOnClick={props.handleYes} />
        </div>
      </Modal.Body>
    </Modal>
  );
}
