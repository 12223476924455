import { useContext, useEffect, useState } from "react";
import UserContext from "../../UserContext";
import AddCourse from "../Course/AddCourse";
import CourseItem from "../shared/CourseItem";
import { useNavigate } from "react-router-dom";
import { API_ENDPOINT } from "../literals";
import { courGet } from "../../service/courAPI";
import { Text } from "../Constant/string";

const AdminCourses = (props) => {
    const userContext = useContext(UserContext);
    const navigate = useNavigate();
    const [experienceList, setExperienceList] = useState([]);

    useEffect(() => {
        userContext.setThumbnail({});
        userContext.setUserMenu([
            {
                label: `${Text.course}`,
                to: '/client/' + userContext.selectedClient + '/courses',
            },
            {
                label: 'Admins',
                to: '/client/' + userContext.selectedClient + '/users',
            },
            {
                label: 'Profile',
                to: '/organization-details',
            }
        ]);
        userContext.setBackUrl([
            {
                label: "All Organizations",
                url: '/clients'
            },
            {
                label: userContext.selectedClientName,
                url: '/client/' + userContext.selectedClient + '/courses'
            }
        ])
        if (userContext.selectedClient) {
            getAllCourses();
            getOrganizationThumbnail();
        }
    }, [userContext.selectedClient]);

    const onClickExperience = (course) => {
        userContext.setSelectedCourse(course._id);
        navigate('/analytics/' + course._id, { state: { ...course } });

    }

    const getOrganizationThumbnail = () => {
        userContext.selectedClient &&
            courGet(API_ENDPOINT.GetOrganizationDetails(userContext.selectedClient))
                .then((res) => {
                    courGet(API_ENDPOINT.getPreSignedGetUrl(res.data.thumbnail))
                        .then((response) => {
                            userContext.setThumbnail({ path: response.data, name: '' });
                        }).catch((error) => {
                            //  console.log(error);
                        })
                })
                .catch((err) => {
                    console.log(err);
                });
    }

    const getAllCourses = () => {
        courGet(API_ENDPOINT.GetAllCourses(userContext.selectedClient))
            .then((res) => {
                setExperienceList(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    return <>
        <div className="organization-owners">
            <h1 className='title header-color'>{Text.course}</h1>
            <div className="member-list">
                {experienceList.map((experience) => {
                    return (<CourseItem experience={experience} key={`experience_${experience._id}`} onClick={() => onClickExperience(experience)} />);
                })}
                <AddCourse getAllCourses={getAllCourses} />
            </div>
        </div>

    </>
}

export default AdminCourses;