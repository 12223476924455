const { inputError } = require('../../components/Constant/errors');


export function validateTextInput(value, label, isRequired, minLength, maxLength) {
  if (!value && isRequired) {
    return label + inputError.isRequired;
  }
  
  if (value && minLength && maxLength) {
    if (value.length < minLength || value.length > maxLength) {
      return label+ ' will not allow more than ' + maxLength + ' characters.';
    }
  }
  return '';
}