import { useCallback, useEffect, useState } from "react";
import { courGet } from "../../service/courAPI";
import { API_ENDPOINT } from "../literals";
import AR from "../../images/ar.svg";
import VR from "../../images/vr.svg";

const CourseItem = (props) => {
    const [thumbnail, setThumbnail] = useState();

    const { experience } = props;
    let fileName = experience.thumbnail;
    useEffect(() => {
        if (fileName) {
            courGet(API_ENDPOINT.getPreSignedGetUrl(fileName))
                .then((res) => {
                    setThumbnail(res.data);
                }).catch((error) => {
                    console.log(error);
                })
        }
    }, [experience])

    return (
        <div className="experience-item" >
            <div className="experience-image">
                {/* {thumbnail && <img src={thumbnail} alt="experience" />} */}
                {(props.thumbnail || thumbnail) && <img src={props.thumbnail || thumbnail} alt="experience" />}

            </div>
            <div className="experience-details">
                {experience.name &&
                    <div className="experience-name">{experience.name}</div>
                }
                {props.type == "module" && <> {experience?.mode === "AR" ? <img src={AR} className="experience-mode" /> : <img src={VR} className="experience-mode" />}
                </>}
            </div>
            <div className="experience-edit" onClick={props.onClick}>
            </div>
        </div>
    );
}

export default CourseItem;