import React, { useContext, useEffect } from "react";
import { Modal } from "react-bootstrap";
import UserContext from "../../UserContext";
import CustomButton from "../Inputs/Button";
import { API_ENDPOINT } from "../literals";
import { courPost } from "../../service/courAPI";
import { Constant } from "../Constant/constant";
import arrow from '../../images/Arrow.svg'
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";

const InviteOwner = (props) => {
    const userContext = useContext(UserContext);

    const [show, setShow] = React.useState(false);
    const [email, setEmail] = React.useState("");
    const [firstName, setFirstName] = React.useState("");
    const [lastName, setLastName] = React.useState("");
    const [role, setRole] = React.useState(Constant.ROLE.organization);
    const [title, setTitle] = React.useState("Add New Organization Admin");
    const [emailError, setEmailError] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [firstNameError, setFirstNameError] = React.useState(false);
    const [lastNameError, setLastNameError] = React.useState(false);

    useEffect(() => {
        setRole(props.role || Constant.ROLE.organization);
        setTitle(props.title || "Add New Organization Admin");
    }, [props])

    const handleSubmit = (e) => {
        e.preventDefault();
        let emailCheck = false;



        if (email === "") {
            setEmailError(true);
            setErrorMessage("Please fill out this field.")
            emailCheck = true;
        }
        else {
            setEmailError(false);
            emailCheck = false;
        }

        if (
            !new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(email)
        ) {
            setErrorMessage("Please enter valid email.")
            setEmailError(true);
            emailCheck = true;
        }
        else {
            setEmailError(false);
            emailCheck = false;
        }

        if (firstName === "") {
            setFirstNameError(true);
        }
        else {
            setFirstNameError(false);
        }

        if (lastName === "") {
            setLastNameError(true);
        }
        else {
            setFirstNameError(false);
        }

        if (emailCheck || firstName === "" || lastName === "" || errorMessage !== "") {
            return;
        }
        const data = {
            firstName: firstName,
            lastName: lastName,
            email: email,
            inviteBy: userContext.email,
            role: role,
            organization: userContext.selectedClient || "",
            experience: props.experience || "",
            isActive: true
        }

        courPost(API_ENDPOINT.InviteOwners, data)
            .then((res) => {
                if (res.status === 200) {
                    // redirect to dashboard
                    setShow(false);
                    setEmail("");
                    setFirstName("");
                    setLastName("");
                    props.refreshMembers();
                    userContext.createToast({ title: "User invited successfully", message: "User invited successfully.", variant: "Success" });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const checkExistingUser = () => {
        let data = {
            email: email,
            role: role,
            currentOrg: userContext.selectedClient
        }
        courPost(API_ENDPOINT.CheckExistingUser, data)
            .then((res) => {
                if (res.data) {
                    setErrorMessage("User already exists");
                } else {
                    setErrorMessage("");
                }
            })
            .catch((error) => {
                console.log(error)
            });

    }
    const modalShow = () => {
        setEmail("");
        setFirstName("");
        setLastName("");
        setErrorMessage("");
        setEmailError(false);
        setFirstNameError(false);
        setLastNameError(false);
        setShow(true);
    }
    return (
        <><div className="add-item-container">
            <div className='add-item' onClick={() => {
                modalShow()
            }}>
                <i className='fa fa-plus' />
            </div>
        </div>
            <Modal
                show={show}
                fullscreen={true}
                animation={false}
                centered
                size="lg" >
                <Modal.Body className="invite-owner">
                    <h1 className="title header-color">{title}</h1>
                    <form onSubmit={handleSubmit}>
                        <input
                            type="text"
                            placeholder="Email"
                            className={`${emailError === true ? 'error form-control ' : 'form-control '}`}
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                            label="Email"
                            onBlur={checkExistingUser}
                        />

                        {errorMessage !== "" && <div className="error-message" style={{ "marginBottom": "10px" }}>{errorMessage}</div>}
                        <div className="d-flex gap-3 mt-3">
                            <div style={{ "display": "flex", "flexDirection": "column" }}>
                                <input
                                    className={`${firstNameError && firstName === "" ? 'error form-control ' : 'form-control '}`}
                                    label="First Name"
                                    placeholder="First Name"
                                    value={firstName}
                                    onChange={(e) => setFirstName(e.target.value)}
                                />
                                {firstNameError && firstName === "" && <div className="error-message" style={{ "marginBottom": "10px" }}>Please fill out this field.</div>}
                            </div>
                            <div style={{ "display": "flex", "flexDirection": "column" }}>
                                <input
                                    label="Last Name"
                                    placeholder="Last Name"
                                    className={`${lastNameError && lastName === "" ? 'error form-control ' : 'form-control '}`}
                                    value={lastName}
                                    onChange={(e) => setLastName(e.target.value)}
                                />
                                {lastNameError && lastName === "" && <div className="error-message" style={{ "marginBottom": "10px" }}>Please fill out this field.</div>}
                            </div>
                        </div>



                        <div className="button-wrapper">
                            <CustomButton variant="link-secondary" className="w-auto text-normal  back-btn" onClick={() => setShow(false)}>
                                <HiOutlineArrowNarrowLeft className="back-arrow me-2" /> <span className="cancelbtn">Cancel</span>
                            </CustomButton>
                            <CustomButton type="submit" className="w-auto px-5">Send Invitation</CustomButton>
                        </div>
                    </form>


                </Modal.Body>
            </Modal>
        </>
    );
};

export default InviteOwner;