import { useContext, useEffect, useState } from "react";
import MemberItem from "../shared/MemberItem";
import InviteOwner from '../Dashboard/InviteOwner';
import UserContext from "../../UserContext";
import { API_ENDPOINT } from "../literals";
import { courGet } from "../../service/courAPI";
import { Text } from "../Constant/string";
import { Dropdown } from "react-bootstrap";
import { Constant } from "../Constant/constant";
import CustomDropdown from "../Inputs/CustomDropdown";
import CustomDropdownMenu from "../Inputs/customDropdownMenu";
import { RiDeleteBin6Line } from "react-icons/ri";
import { MdOutlineClose } from "react-icons/md";


const OrganizationOwners = (props) => {
    const userContext = useContext(UserContext);
    const [organizationOwnerList, setOrganizationOwnerList] = useState([]);
    const [isRemove, setIsRemove] = useState(false);

    useEffect(() => {
        getOrganizationOwner();
        getOrganizationThumbnail();
        userContext.setUserMenu([
            {
                label: `${Text.course}`,
                to: '/client/' + userContext.selectedClient + '/courses',
            },
            {
                label: 'Admins',
                to: '/client/' + userContext.selectedClient + '/users',
            },
            {
                label: 'Profile',
                to: '/organization-details',
            }
        ])
        userContext.setBackUrl([
            {
                label: "All Organizations",
                url: '/clients'
            },
            {
                label: userContext.selectedClientName,
                url: '/client/' + userContext.selectedClient + '/courses'
            }
        ])
    }, [userContext.selectedClient]);

    const getOrganizationOwner = () => {
        courGet(API_ENDPOINT.GetOrganizationOwner(userContext.selectedClient))
            .then((res) => {
                setOrganizationOwnerList(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const getOrganizationThumbnail = () => {
        userContext.selectedClient &&
            courGet(API_ENDPOINT.GetOrganizationDetails(userContext.selectedClient))
                .then((res) => {
                    courGet(API_ENDPOINT.getPreSignedGetUrl(res.data.thumbnail))
                        .then((response) => {
                            userContext.setThumbnail({ path: response.data, name: '' });
                        }).catch((error) => {
                            //  console.log(error);
                        })
                })
                .catch((err) => {
                    console.log(err);
                });
    }
    return <>
        <div className="organization-owners">

            <div className="d-flex justify-content-between">
                <h1 className='title header-color'>Admins</h1>
                {userContext.role !== Constant.ROLE.partner &&
                    <Dropdown align="end">
                        <Dropdown.Toggle as={CustomDropdown} id="dropdown-custom-components">
                        </Dropdown.Toggle>

                        <Dropdown.Menu as={CustomDropdownMenu}>
                            {!isRemove &&
                                <Dropdown.Item eventKey="1" className="dropDown-menu-text" onClick={() => { setIsRemove(true) }}><RiDeleteBin6Line className="icon-dropdown" />Remove Admins</Dropdown.Item>}
                            {isRemove &&
                                <Dropdown.Item eventKey="1" className="dropDown-menu-text" onClick={() => { setIsRemove(false) }}><MdOutlineClose className="icon-dropdown" />Cancel</Dropdown.Item>}
                        </Dropdown.Menu>
                    </Dropdown>
                }
            </div>
            <div className="member-list">
                {organizationOwnerList.map((client) => {
                    return (<MemberItem member={client} refreshMembers={getOrganizationOwner} isRemove={isRemove} />);
                })}
                <InviteOwner refreshMembers={getOrganizationOwner} />
            </div>
        </div>
    </>
}

export default OrganizationOwners;