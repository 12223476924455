import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import "./CourseLaunch.css";
import { Buffer } from 'buffer';
import { useEffect, useState } from "react";
import CryptoJS from "crypto-js"
import { courGet } from "../../service/courAPI";
import { API_ENDPOINT } from "../literals";
import setThemeColor from '../Utils/setThemeColor';
import { Button } from "react-bootstrap";
import desktop from "../../images/desktop.png";
import headset from "../../images/headset.png";
import danger from '../../images/Vector.png';
import { Toast, ToastContainer } from "react-bootstrap";

const SelectDevice = () => {
    const params = useParams();
    const [experienceData, setExperienceData] = useState([]);
    const [courseData, setCourseData] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const [userId, setUserId] = useState("");
    const [authToken, setAuthToken] = useState("");
    const [organizationName, setOrganizationName] = useState("");
    const [selectDeviceDisabled, setSelectDeviceDisabled] = useState(true);
    const [invalidCourseDisabled, setInvalidCourseDisabled] = useState(true);
    const [toastMessage, setToastMessage] = useState("");
    const [showToastMessage, setShowToastMessage] = useState(false)

    setThemeColor({ primary: "#415464", secondary: "#415464", btnHover: '#768390' })
    useEffect(() => {
        var reb64 = CryptoJS.enc.Hex.parse(params.data);
        var bytes = reb64.toString(CryptoJS.enc.Base64);
        var decrypt = CryptoJS.AES.decrypt(bytes, 'sweet-rush-key@123');
        var plain = JSON.parse(decrypt.toString(CryptoJS.enc.Utf8));
        setExperienceData(plain);
        if (searchParams.get("userId") != null && searchParams.get("ltiKey") != null) {
            getInfo();
            setUserId(searchParams.get("userId"));
        } else {
            setToastMessage("Invalid user details. Please launch course again");
            setShowToastMessage(true);
        }
    }, [searchParams]);


    const getInfo = () => {
        setSelectDeviceDisabled(true);
        try {
            let fetchRes = fetch('/lti/info', { credentials: 'include', headers: { Authorization: 'Bearer ' + searchParams.get("ltiKey") } });
            fetchRes.then(res =>
                res.json()).then(launchInfo => {
                    if (launchInfo && launchInfo.context && launchInfo.context.custom && launchInfo.context.custom.custom_channel_redirect) {
                        if (searchParams.get("userId") === launchInfo.userId) {
                            setSelectDeviceDisabled(false);
                        } else {
                            setSelectDeviceDisabled(true);
                            setToastMessage("Invalid user details. Please launch the course again.");
                            setShowToastMessage(true);
                        }
                    }
                })

        } catch (err) {
            console.log(err)
            // errorPrompt('Failed trying to retrieve custom parameters! ' + err)
        }
    }
    useEffect(() => {
        if (experienceData.experience) {
            getAuthToken();
            //getCloudFlareContentAuth();
        }
    }, [experienceData.experience]);


    const navigate = useNavigate();

    const onClickHeadset = () => {
        let launchURL = courseData.version.filter(x => x.active === true);
        let encryptedData = {
            userId: userId,
            experience: experienceData.experience,
            course: courseData?.courseId,
            launchURL: launchURL[0].launchURL,
            moduleLevel: experienceData?.level ? experienceData?.level : "",
            backurl: "/launch/" + params.data + "?userId=" + userId + "&ltiKey=" + searchParams.get("ltiKey")
        }
        let data = encodeURIComponent(Buffer.from(JSON.stringify(encryptedData)).toString('base64'));
        navigate('/create-launch-code/' + data);
    }

    const getAuthToken = () => {
        //get organizationID from course id
        courGet(API_ENDPOINT.GenerateClientDetails(experienceData.experience))
            .then((res) => {
                if (res.data && res.data.courseDetails && res.data.clientAuthToken && res.data.organization) {
                    setCourseData(res.data.courseDetails);
                    setAuthToken(res.data.clientAuthToken);
                    setOrganizationName(res.data.organization);
                    setInvalidCourseDisabled(false);
                } else {
                    setToastMessage("Course no longer available");
                    setShowToastMessage(true);
                    setInvalidCourseDisabled(true);
                }
            }).catch((error) => {
                console.log(error);
            })
    }



    const onClickDesktop = () => {

        let launchURL = courseData.version.filter(x => x.active);
        let actor = JSON.stringify({
            mbox: userId + organizationName
        });
        let token = authToken;

        let tempExtraParam = ""
        if (experienceData?.level != "" && experienceData?.level != undefined) {
            tempExtraParam = "&module=" + experienceData?.level
        }
        let CourseLaunchURL = "endpoint=" + process.env.REACT_APP_LRS_ENDPOINT + "/data/xAPI/statements&auth=" + token + "&experience_id=" + experienceData.experience + "&mode=Desktop" + "&course_id=" + courseData.courseId + "&actor=" + actor + tempExtraParam

        let encodeURI = encodeURIComponent(CourseLaunchURL);
        window.open(
            process.env.REACT_APP_S3_FOLDER + launchURL[0].launchURL + "?" + encodeURI,
            '_self');

    }


    return <>
        <div className="create-code-container">
            <div className="select-device-header ">
                <div className="w-100">This experience can be launched on both a <b>desktop</b> and a<b> VR headset</b>.</div>
                <div className="w-100"> Select how you would like to continue.</div>
            </div>
            <div className="select-device-button-wrapper ">
                <div className="select-device-btn-box">
                    <div className="select-device-box-inner">
                        <img src={desktop} alt="Desktop icon" />
                        {invalidCourseDisabled === false && selectDeviceDisabled === false ? <Button className="w-auto px-5 headset-btn select-device-btn" onClick={onClickDesktop}>Continue On Desktop</Button>
                            : <Button className="w-auto px-5 headset-btn select-device-btn disabled" >Continue On Desktop</Button>
                        }
                    </div>
                </div>
                <div className="select-device-btn-box">
                    <div className="select-device-box-inner">
                        <img src={headset} alt="Headset icon" />
                        {invalidCourseDisabled === false && selectDeviceDisabled === false ? <Button className="w-auto px-5 headset-btn select-device-btn" onClick={onClickHeadset}>Continue On Headset</Button>
                            : <Button className="w-auto px-5 headset-btn select-device-btn disabled" >Continue On Headset</Button>
                        }

                    </div>
                </div>
            </div>
        </div>
        {showToastMessage === true &&
            <ToastContainer className="p-3" position={"top-center"} >
                <Toast
                    className="toast-message"
                    bg="danger"
                    key="2">
                    <Toast.Body className="toast-text"> <img src={danger} className="me-2" />
                        {toastMessage}
                    </Toast.Body>
                </Toast>
            </ToastContainer>
        }
    </>
}

export default SelectDevice