import React from "react";
import { FiMoreVertical } from "react-icons/fi";

const CustomDropdown = React.forwardRef(({ children, onClick }, ref) => {

    return (
        <div
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
            className='dropdown-background'>
            <FiMoreVertical className="" />
        </div>)
});

export default CustomDropdown;