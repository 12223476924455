import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { courPost } from "../../service/courAPI";
import CustomButton from "../Inputs/Button"
import { API_ENDPOINT } from "../literals";
import { Buffer } from 'buffer';
import arrow from '../../images/ArrowBlack.svg'
import step from '../../images/stepone.png'

const CreateLaunchCode = () => {
    const navigate = useNavigate();
    const [code, setCode] = useState(0);
    const [codeSplit, setCodeSplit] = useState([]);
    const [userData, setUserData] = useState();
    const params = useParams();

    const backSelectDevice = () => {
        navigate(userData.backurl);
    }

    useEffect(() => {
        let dataEncrypted = decodeURIComponent(params.data);
        let data = Buffer.from(dataEncrypted, 'base64').toString('ascii');
        setUserData(JSON.parse(data));
    }, [])

    useEffect(() => {
        if (userData) {
            generateCode();
        }
    }, [userData]);

    const generateCode = () => {
        let data = {
            userId: userData.userId,
            experience: userData.experience,
            course: userData.course,
            launchURL: userData.launchURL,
            moduleLevel: userData.moduleLevel
        }

        courPost(API_ENDPOINT.GenerateCourseCode, data)
            .then((res) => {
                setCode(res.data.code);
                setCodeSplit(res.data.code.toString().split(''))
            })
            .catch((err) => {
                console.log(err);
            });
    }

    return <div className="create-code-container">
        <div className="create-code-header ">
            <div className="w-100">Start your experience with the following steps</div>
        </div>
        <div className="target-url">
            <img src={step} />
        </div>
        <div className="crate-code-background">
            <div className="crete-code-sub-header ">
                <div className="w-100"><span className="blue-color">3.</span> When prompted, <b>enter this code:</b></div>
            </div>
            <div className="code-container">
                <div className="digit-box">{codeSplit[0] || "-"}</div>
                <div className="digit-box">{codeSplit[1] || "-"}</div>
                <div className="digit-box">{codeSplit[2] || "-"}</div>
                <div className="digit-box">{codeSplit[3] || "-"}</div>
                <div className="digit-box">{codeSplit[4] || "-"}</div>
                <div className="digit-box">{codeSplit[5] || "-"}</div>
            </div>
        </div>
        <div className="select-device-button-wrapper ">
            <CustomButton variant="link-secondary" className="w-auto px-5 button-color" onClick={backSelectDevice}><img src={arrow} className="me-2" alt="arrow" /> Continue on desktop instead</CustomButton>
        </div>
    </div>
}

export default CreateLaunchCode