import { useState, useRef, useContext, memo } from "react";
import { Modal } from "react-bootstrap";
import FileUpload from "../Inputs/FileUpload";
import LinearProgressBar from "../Inputs/LinearProgressBar";
import axios, { CancelToken } from 'axios';
import InputBox from "../Inputs/InputBox";
import TextArea from "../Inputs/TextArea";
import { v4 as uuidv4 } from 'uuid';
import "./courses.css";
import UserContext from "../../UserContext";
import { API_ENDPOINT } from "../literals";
import { BackButton } from "./BackButton";
import { courDelete, courGet, courPost } from "../../service/courAPI";
import { validateTextInput } from "../Utils/validation";
import { Text } from "../Constant/string";
import { inputError } from "../Constant/errors";
import { Constant } from "../Constant/constant";

const ExperienceDetails = ({ experience, handleExperience, experienceError, fileLoading = { "percent": 0, "total": 0, "loaded": 0 }, ...props }) => {
    return (
        <form>
            <div className="experience-user-details">
                <InputBox
                    id='expName'
                    label={Text.nameCourse}
                    name="name"
                    value={experience.name}
                    onChange={(e) => handleExperience(e)}
                    error={experienceError.name}
                    errorText={experienceError.name}
                    maxlength="100"
                    required />
                <TextArea
                    id='expDescription'
                    label={Text.description}
                    maxlength="500"
                    name="description"
                    rows="4"
                    value={experience.description}
                    onChange={(e) => handleExperience(e)}
                    error={experienceError.description}
                    errorText={experienceError.description}
                    required />
            </div>
            <BackButton handleBackButton={props.handleBackButton}
                handleOnClick={props.addExperience}
                cancelBtnName='Cancel'
                classes={(fileLoading.percent === 0 || props.disable) ? 'disabled' : ''}
                buttonName={props.disable ? 'Processing...' : 'Add Course'} />
        </form>
    )
}

const AddCourse = (props) => {
    const [show, setShow] = useState(false);
    const [experience, setExperience] = useState({ name: '', description: '' });
    const [experienceError, setExperienceError] = useState({ name: '', description: '' });
    const [file, setFile] = useState('');
    const [fileLoading, setFileLoading] = useState({ "percent": 0, "total": 0, "loaded": 0 });
    const [applyValidation, setApplyValidation] = useState(false);
    const [error, setError] = useState('');
    const cancelFileUpload = useRef(null);
    const userContext = useContext(UserContext);
    const [disable, setDisable] = useState(false);

    const removeFile = (fileName) => {
        courDelete(API_ENDPOINT.ImageFileDelete(fileName))
            .then(res => {
                setFile('');
                setFileLoading({ "percent": 0, "total": 0, "loaded": 0 })

            }).catch(error => {
                setError('Not able to delete file');
            })
    };

    const cancelUpload = (filename) => {
        removeFile(filename);
        if (cancelFileUpload.current) {
            cancelFileUpload.current("User has canceled the file upload.");
        }
    };

    const uploadFile = (fileData, type) => {
        setError('')
        if (fileData) {
            const fileName = uuidv4() + '-' + fileData.name.toLowerCase().split(' ').join('');
            setFile(fileName);
            const file = new File([fileData], fileName);
            courGet(API_ENDPOINT.getPreSignedPutUrl(fileName))
                .then((res) => {
                    if (res.data) {
                        const fileExt = fileName.split('.')[1];
                        axios.put(res.data, file,
                            {
                                headers: { 'Content-Type': 'image/' + fileExt },
                                onUploadProgress: (event) => {
                                    let percent = Math.floor((100 * event.loaded) / event.total);
                                    setFileLoading({ "percent": percent, "total": event.total, "loaded": event.loaded })
                                },
                                cancelToken: new CancelToken(
                                    cancel => (cancelFileUpload.current = cancel)
                                ),
                            }
                        ).catch(error => {
                            setError('File could not be uploaded for some reason.');
                            setFile('');
                            setFileLoading({ "percent": 0, "total": 0, "loaded": 0 });
                        });
                    }
                })
                .catch((err) => {
                    // console.log(err);
                });
        }
    }
    const clearStorage = () => {
        setShow(false);
        setFileLoading({ "percent": 0, "total": 0, "loaded": 0 });
        setFile('');
        setError('');
        setExperience([]);
        setExperienceError([]);
        setDisable(false);
    }

    function handleBackButton() {
        file && removeFile(file);
        clearStorage();
    }

    const addExperience = (e) => {
        e.preventDefault();
        setApplyValidation(true);
        const nameError = validateTextInput(experience.name, "Course name", true, 1, 100);
        const descriptionError = validateTextInput(experience.description, "Course description", true, 1, 500);
        handleValidation('name', nameError);
        handleValidation('description', descriptionError);
        if (file && experience.name && experience.description && !nameError && !descriptionError) {
            let data = {
                name: experience.name,
                description: experience.description,
                thumbnail: file,
                userId: userContext._id,
                organizationId: userContext.selectedClient,
                isActive: true
            }
            setDisable(true);
            courPost(API_ENDPOINT.AddCourse, data)
                .then((res) => {
                    clearStorage();
                    userContext.createToast({ title: "Course Added", message: "Course Added.", variant: "Success" });
                    props.getAllCourses();
                })
                .catch((err) => {
                    setDisable(false);
                    if (err.code === Constant.ERROR.network) {
                        setError(inputError.internalError);
                    }
                    else {
                        setError(err.response.data.message);
                    }
                });
        }
    }

    function handleExperience(e) {
        e.preventDefault();

        const { name, value } = e.target;
        setExperience((prevState) => ({
            ...prevState,
            [name]: value
        }));
        if (applyValidation) {
            setExperienceError((prevState) => ({
                ...prevState,
                [name]: ''
            }));
        }
    }

    const handleValidation = (name, message) => {
        setExperienceError((prevState) => ({
            ...prevState,
            [name]: message
        }));
    }

    const ProgressBar = ({ ...props }) => {
        return <LinearProgressBar {...props} />
    }

    const UploadThumbnail = () => {
        return <FileUpload
            type='image'
            text={Text.uploadImage}
            uploadFile={uploadFile}
            aspect={5 / 4}
            subText={Text.uploadImageSubText} />
    }

    return (
        <>
            <div className='add-item add-experience' onClick={() => setShow(true)}>
                <i className='fa fa-plus' />
            </div>
            <Modal
                dialogClassName='experience-model'
                show={show}
                fullscreen={true}
                animation={false} >
                <Modal.Body className="invite-owner modal-margin">
                    <div className="experience-container">
                        <h1 className="title header-color">{Text.addCourse}</h1>
                        {fileLoading.percent === 0 ? <UploadThumbnail /> :
                            <ProgressBar type='image'
                                uploadedFileName={file}
                                fileName={file && file.substring(37, file.length)}
                                cancelUpload={cancelUpload}
                                removeFile={removeFile}
                                loading={fileLoading} />}
                        <ExperienceDetails
                            experience={experience}
                            handleExperience={handleExperience}
                            experienceError={experienceError}
                            handleBackButton={handleBackButton}
                            fileLoading={fileLoading}
                            addExperience={addExperience}
                            disable={disable} />

                        {error && <div className="error" >{error}</div>}
                    </div>
                </Modal.Body>
            </Modal>
        </>)
}

export default memo(AddCourse);