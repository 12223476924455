import { React, useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { BsFileEarmarkFill } from "react-icons/bs";
import { MdDownload } from "react-icons/md";
import LinearProgressBar from "../Inputs/LinearProgressBar";
import { BackButton } from "./BackButton";
import CustomButton from "../Inputs/Button";
import FileUpload from "../Inputs/FileUpload";
import { Text } from "../Constant/string";
import classnames from 'classnames';
import { ConfirmationDialog } from "../Inputs/ConfirmationDialog";
import ReactTooltip from 'react-tooltip';
import { convertDateFormat } from "../Utils/commonFunctions";
import { inputError } from "../Constant/errors";



const VersionList = (props) => {
   const { file, cancelUpload, removeFile, loading, handleBackButton,
      addExperienceVersion, error = '', versionList } = props;
   let sortedVersionList = versionList && versionList.length > 0 && versionList.sort((a, b) => new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime());

   const [uploadVersionDisable, setUploadVersionDisable] = useState(false);

   useEffect(() => {
      let FileStatusProgress = versionList.filter(x => x.fileStatus == "0");

      if (FileStatusProgress?.length > 0) {
         setUploadVersionDisable(true);
      } else {
         setUploadVersionDisable(false);
      }

   }, [versionList]);
   const VersionName = ({ fileName }) => {
      return (
         <> <ReactTooltip />
            {fileName && fileName.length > 0 &&
               <span className="version-name" data-tip={fileName.length > 46 ? fileName : ''}>
                  {fileName && fileName.length > 46 ? fileName.substring(0, 46) + '...' : fileName}
               </span>}
         </>
      )
   };

   return (
      <div>
         <div className='upload-version-header'>
            <h1 className='text-header'>{Text.version}</h1>
            <CustomButton
               type="submit"
               className={uploadVersionDisable ? 'w-auto px-5 disabled' : 'w-auto px-5'}
               onClick={props.handleNewVersionModel}>Upload New Version</CustomButton>
         </div>
         <ul className="versionList">
            {sortedVersionList && sortedVersionList.length > 0 && sortedVersionList.map((version) => {
               return (
                  <li key={version.id}
                     className={classnames("version-item", { "activeVersion": version.active === true })}>
                     <form className="upload-version-header">
                        <Form.Check
                           className="version-radio-btn"
                           type="radio"
                           name="version"
                           value={version.id}
                           id={`radio_${version.id}`}
                           key={version.id}
                           disabled={version.fileStatus === '-1' || version.fileStatus === '0' ? true : false}
                           checked={version.active === true}
                           onChange={props.handleRadioButton}
                        />
                        <div className="upload-version-header content-div">
                           <BsFileEarmarkFill className="module-icon" />
                           <Form.Check.Label className='version-title-div'>
                              {
                                 <VersionName
                                    fileName={version.fileName && version.fileName.substring(37, version.fileName.length)} />
                              }
                              <span>
                                 {version.createdDate && version.fileStatus !== '0' && <span className="version-subTitle">{convertDateFormat(version.createdDate)}</span>}
                                 {version.fileStatus === '0' && <span className="loading primary-color">{` Processing  `}<span style={{ fontSize: '14px' }}>{`${version.progress != undefined ? version.progress : 0}% `}</span></span>}
                                 {version.fileStatus === '-1' && <span className="error">{` ${inputError.fileError}`}</span>}
                              </span>
                           </Form.Check.Label>
                        </div>
                     </form>
                     <div className="upload-version-header">
                        {version.active === true && <span className="active-status">Currently Active</span>}
                        <a href={version.fileStatus === '1' && `${process.env.REACT_APP_S3_FOLDER}${version.fileName}`} target="_blank" rel="noreferrer" download>
                           <MdDownload className="version-download-icon" />
                        </a>
                     </div>
                  </li>
               )
            })}
         </ul>
         <ConfirmationDialog show={props.showConfirmationVersionDialog || props.showUploadVersionDialog}
            cancel={handleBackButton}
            handleYes={props.showUploadVersionDialog ? props.addNewModuleVersion : props.handleYes}
            subText={props.showUploadVersionDialog ? 'You can upload maximum 3 versions.' : 'You may change this setting at any time.'}
            text={props.showUploadVersionDialog ? 'Are you sure you want to delete oldest version?' :
               'Are you sure you want to make this version currently active?'} />

         <Modal show={props.show} fullscreen={true} onHide={props.onHide}>
            <Modal.Body className="invite-owner">
               <div className='experience-container'>
                  <h1 className="title header-color">Add New Version</h1>
                  {loading.percent === 0 ?
                     <>
                        <FileUpload
                           type='zip'
                           text={Text.uploadZip}
                           uploadFile={props.uploadFile}
                           fileSize="1024"
                           subText={Text.uploadZipSubText} />
                     </> :
                     <div className="upload-model">
                        <LinearProgressBar
                           type='zip'
                           uploadedFileName={file}
                           fileName={file && file.substring(37, file.length)}
                           cancelUpload={cancelUpload}
                           removeFile={removeFile}
                           abortFileUpload={props.abortFileUpload}
                           handleFileError={props.handleFileError}
                           resumeFileUpload={props.resumeFileUpload}
                           loading={loading} />
                     </div>
                  }
                  <BackButton
                     handleBackButton={handleBackButton}
                     classes={(loading.percent < 100 || props.disable) ? 'disabled' : 'content-bgrd'}
                     buttonName={props.disable ? <div className="loading-btn-processing"><span className="loading">Processing</span></div> : 'Upload New version'}
                     handleOnClick={addExperienceVersion} />
                  {error && <div className="error" >{error}</div>}

               </div>
            </Modal.Body>
         </Modal>
      </div >
   );
}

export default VersionList;