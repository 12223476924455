import React from "react";
import { Outlet } from "react-router-dom";

const FullLayout = (props) => {
    return (<>

        <div className={"content-wrapper vh-100"}>
            <div className='content'>
                <Outlet />
            </div>
        </div>
    </>);
}

export default FullLayout;