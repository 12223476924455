export function bytesToSize(bytes) {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
  if (bytes === 0) return 'n/a'
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10)
  if (i === 0) return `${bytes} ${sizes[i]})`
  const number = Math.floor((bytes / (1024 ** i)).toFixed(1))
  return `${number} ${sizes[i]}`
}

export function toBytes(size, type) {
  const types = ["B", "KB", "MB", "GB", "TB"];
  const key = types.indexOf(type.toUpperCase())
  if (typeof key !== "boolean") {
    return size * 1024 ** key;
  }
  return size;
}

//e.g dateTimeFormat to // February 14, 2020 at 00:00:00
export function convertDateFormat(date) {
  date = new Date(date);
  const longEnUSFormatter = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric', minute: 'numeric', second: 'numeric',
  });
  return longEnUSFormatter.format(date);
}