import React, { useEffect, useRef } from "react";

const InputBox = ({ type = "text", label, value, errorText = "", onChange, ...props }) => {
    const inputEl = useRef(null);
    const [error, setError] = React.useState(Boolean(props.error));
    const [errorClass, setErrorClass] = React.useState("");
    const [errorMsg, setErrorMsg] = React.useState(errorText);


    useEffect(() => {
        if (error) {
            setErrorClass("error");
        } else {
            setErrorClass("");
        }
    }, [error]);

    useEffect(() => {
        if (props.error) {
            setError(true);
            setErrorMsg(errorText);
        } else {
            setError(false);
            setErrorMsg("");
        }

    }, [props.error, errorText]);

    const handleErrors = (e) => {
        console.log("handleErrors", e.target.validity);
        if (e.target.validity.valid) {
            setError(false);
            setErrorMsg("");
            setErrorClass("");
            inputEl.current.setCustomValidity("");
        } else {
            setError(true);
            // get error message from validity object
            if (e.target.type === "text" && e.target.validationMessage.trim() === "") {
                setErrorMsg("Please fill out this field.");
            } else if (e.target.type === "email" && e.target.validationMessage.trim() === "") {
                setErrorMsg("Please enter valid email");
            } else {
                setErrorMsg("Please fill out this field.");
            }

            setErrorClass("error");
            inputEl.current.setCustomValidity(" ");
        }
    };

    const resetError = () => {
        setError(false);
        setErrorMsg("");
        setErrorClass("");
        inputEl.current.setCustomValidity("");
    };

    return (
        <div className="form-group" >
            <input
                {...props}
                type={type}
                className={"form-control " + errorClass + " " + props.className}
                placeholder={label}
                value={value}
                autoComplete="new-password"
                onChange={(e) => { onChange(e); resetError() }}
                onInvalid={handleErrors}
                ref={inputEl}
            />
            {
                error && <div className="error-message">{errorMsg}</div>
            }
        </div>
    );
};

export default InputBox;