import React, { useEffect } from "react";
import { Buffer } from 'buffer';
import PasswordInput from "../Inputs/PasswordInput";
import CustomButton from "../Inputs/Button";
import { useNavigate, useParams } from "react-router-dom";
import { API_ENDPOINT } from "../literals";
import { courPut } from "../../service/courAPI";


const ResetPassword = ({ setUser }) => {
    const navigate = useNavigate();
    const [userId, setUserId] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [rePassword, setRePassword] = React.useState("");
    const [error, setError] = React.useState(false);
    const [errorText, setErrorText] = React.useState("");
    const params = useParams();

    useEffect(() => {
        // read token from url
        const token = params.token;
        let data = Buffer.from(token, 'base64').toString('ascii');
        let userData = JSON.parse(data);
        setUserId(userData._id);
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        setError(false);
        setErrorText("");
        if (password !== rePassword) {
            setError(true);
            setErrorText("Password does not match. Please try again.");
            return;
        }
        const data = {
            _id: userId,
            password: password
        }

        courPut(API_ENDPOINT.UpdateUserPassword(userId), data)
            .then((res) => {

                if (res.data) {
                    setUser(res.data);
                    navigate("/");
                }
            })
            .catch((err) => {
                console.log(err);
            })

    };

    return (
        <div className="reset-page">
            <div className="invite-owner">
                <h1 className="title header-color">Create a New Password</h1>
                <form onSubmit={(e) => handleSubmit(e)}>
                    <div className="upload-subheading subText-color mb-5">Create a new password to use when login with your registered email.</div>

                    <PasswordInput
                        label="Enter New Password"
                        value={rePassword}
                        onChange={(e) => {
                            setRePassword(e.target.value); setError(false);
                            setErrorText("");
                        }}
                        required
                    />

                    <PasswordInput
                        label="Confirm New Password"
                        value={password}
                        onChange={(e) => {
                            setPassword(e.target.value); setError(false);
                            setErrorText("");
                        }}
                        required
                        error={error}
                        errorText={errorText}
                    />

                    <div className="d-flex w-100">
                        <CustomButton type="submit" className="btn btn-primary btn-block ms-auto">Create</CustomButton>
                    </div>
                </form>
            </div>
        </div>

    );
};

export default ResetPassword;
