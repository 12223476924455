import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import Header from "./Header";

const HeaderLayout = (props) => {
    return (
        props.isAuthenticate ? <>
            <Header />
            <div className={'content-wrapper'}>
                <div className='content'>
                    <Outlet />
                </div>
            </div>
        </>
            :
            <Navigate to="/login" />);
}

export default HeaderLayout;