import React  from 'react';
// forwardRef again here!
// Dropdown needs access to the DOM of the Menu to measure it
const CustomDropdownMenu = React.forwardRef(
    ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {

        return (
            <div
                ref={ref}
                style={style}
                className={className}
                aria-labelledby={labeledBy}
            >
                <ul className="list-style">
                    {React.Children.toArray(children).filter(
                        (child) =>
                            child.props.children
                    )}
                </ul>
            </div>
        );
    },
);

export default CustomDropdownMenu;