import InputBox from "../Inputs/InputBox";
import TextArea from "../Inputs/TextArea";
import FileUpload from "../Inputs/FileUpload";


export const EditCourse = ({ newExperience, handleNewExperience, newExperienceError, ...props }) => {
   return (
      <div className="edit-experience-container">
         <div className="edit-expe-label mb-3">
            Course Name
         </div>
         <InputBox
            id='expName'
            label="Experience Name"
            name="name"
            type="text"
            value={newExperience.name}
            onChange={(e) => handleNewExperience(e)}
            error={newExperienceError.name}
            errorText={newExperienceError.name}
            className='edit-exp-name'
            required />
         <div className="edit-expe-label mb-3">
            Course Description
         </div>
         <TextArea
            id='expDescription'
            label="Experience Description"
            name="description"
            rows="4"
            className='edit-description'
            value={newExperience.description}
            onChange={(e) => handleNewExperience(e)}
            error={newExperienceError.description}
            errorText={newExperienceError.description}
            required />

         <div className=" mb-3">
            <div className="edit-expe-label">
               Upload Image
               <div className="editText">Upload an image to represent your course.</div>
            </div>
            <div className="edit-image-exp-container ">
               <img className='thumbnail-img' alt="course thumbnail" src={props.thumbnailUrl} />
               <div>
                  <FileUpload
                     type='image'
                     uploadFile={props.uploadFile}
                     aspect={5 / 4} />
               </div>
            </div>
         </div>
      </div>
   )
}