import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const LtiConfiguration = () => {
    const navigate = useNavigate();
    const getLtik = () => {
        const searchParams = new URLSearchParams(window.location.search)
        const ltik = searchParams.get('ltik');
        const response_type = searchParams.get('response_type');
        //  if (!ltik) throw new Error('Missing lti key.')

        if (ltik.indexOf("api.coursera.org/api/lti/auth/token") > -1) {
            let link = ltik + "?" + response_type;
            console.log("link", window.location.search);
            window.open(window.location.search.split('?ltik=')[1], '_self');
        }
        return ltik
    }
    useEffect(() => {
        const getInfo = () => {
            try {
                let fetchRes = fetch('/lti/info', { credentials: 'include', headers: { Authorization: 'Bearer ' + getLtik() } });
                fetchRes.then(res =>
                    res.json()).then(launchInfo => {
                        if (launchInfo && launchInfo.context && launchInfo.context.custom && launchInfo.context.custom.custom_channel_redirect) {
                            const redirectURL = launchInfo.context.custom.custom_channel_redirect;
                            if (redirectURL.includes('?')) {
                                window.open(redirectURL + "&userId=" + launchInfo.userId + "&ltiKey=" + getLtik(), "_self")
                            }
                            else {
                                window.open(redirectURL + "?userId=" + launchInfo.userId + "&ltiKey=" + getLtik(), "_self")
                            }
                            // const searchParams = new URLSearchParams(window.location.search)
                            // const code = searchParams.get('code');
                            // navigate('/launch/' + code + "?username=" + launchInfo.name + "&useremail=" + launchInfo.email,);
                        }
                    })

            } catch (err) {
                console.log(err)
                // errorPrompt('Failed trying to retrieve custom parameters! ' + err)
            }
        }
        getInfo()
    }, [])

    return <div>
    </div>
}

export default LtiConfiguration