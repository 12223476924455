import axios from 'axios';
/**
 * courAPI is an acronyms of Axios API.
 * This util provide a varius options to make API Calls to external services as well as to backend server.
 */
export const courRequest = (url, { method, headers, args }) => {

  const localUser = JSON.parse(localStorage.getItem('user'));
  //axios.defaults.headers.common['Authorization'] = "Bearer " + localUser.token;
  let header = null;
  if (localUser?.token) {
    header = {
      headers: { Authorization: "bearer " + localUser?.token }
    }
  }

  const logout = () => {
    if (window.location.href.indexOf('launch/') === -1) {
      window.location.href = "/";
      localStorage.removeItem('user');
      localStorage.clear();
    }
    //setUser(null);
  }
  return new Promise((resolve, reject) => {
    // eslint-disable-next-line default-case
    switch (method) {
      case
        'post':
        axios.post(url, args, header).then(resolve).catch(error => {
          if (error.response.status === 500) { logout(); } reject(error);
        });
        break;
      case 'get':
        axios.get(url, header, args).then(resolve).catch(error => {
          if (error.response.status === 500) { logout(); } reject(error);
        });
        break;
      case 'delete':
        axios.delete(url, header, args).then(resolve).catch(error => {
          if (error.response.status === 500) { logout(); } reject(error);
        });
        break;
      case 'put':
        axios.put(url, args, header).then(resolve).catch(error => {
          if (error.response.status === 500) { logout(); } reject(error);
        });
        break;
    }
  });
};

export const courPost = (url, args) => {
  return courRequest(url, {
    method: 'post',
    args
  });
};

export const courLRSPost = (url, args) => {
  return courRequest(url, {
    method: 'lrsPost',
    args
  });
};

export const courGet = (url, args) => {
  return courRequest(url, {
    method: 'get',
    args
  });
};

export const courDelete = (url, args) => {
  return courRequest(url, {
    method: 'delete',
    args
  });
};

export const courPut = (url, args) => {
  return courRequest(url, {
    method: 'put',
    args
  });
};
