import InputBox from "../Inputs/InputBox";
import { Text } from "../Constant/string";
import FileUpload from "../Inputs/FileUpload";
import LinearProgressBar from "../Inputs/LinearProgressBar";
import Select, { components } from "react-select";

export const AddModule = ({ file = '', fileLoading = { "percent": 0, "total": 0, "loaded": 0 }, ...props }) => {
   const { Option } = components;
   const customOption = props => {
      return (
         <>
            <Option {...props} data-tip={props.label} />
         </>
      );
   };

   const options = () => {
      let optionList = [{
         value: "VR", tabValue: "VR", label: "VR"

      },
      {
         value: "AR", tabValue: "AR", label: "AR"
      }]
      return optionList;
   }

   return (
      <>
         <h1 className="title header-color">{Text.addModule}</h1>
         {fileLoading.percent === 0 ?
            <FileUpload
               type='zip'
               text={Text.uploadZip}
               uploadFile={props.uploadFile}
               fileSize="1024"
               subText={Text.uploadZipSubText} /> :
            <LinearProgressBar type='zip'
               uploadedFileName={file}
               fileName={file && file.substring(37, file.length)}
               cancelUpload={props.cancelUpload}
               abortFileUpload={props.abortFileUpload}
               removeFile={props.removeFile}
               loading={fileLoading}
               handleFileError={props.handleFileError}
               resumeFileUpload={props.resumeFileUpload} />
         }
         <div className="module-details">
            <InputBox
               id='module'
               label={Text.nameExperience}
               name="name"
               maxlength="100"
               value={props.module.name}
               onChange={(e) => props.handleModuleDetails(e)}
               error={props.moduleError.name}
               errorText={props.moduleError.name}
               required />
         </div>

         <div className="module-mode-select">
            <Select
               required
               components={{
                  Option: customOption
               }}
               className="module-dropDown"
               placeholder="Type of experience"
               classNamePrefix="select"
               name="mode"
               onChange={(e) => props.handleModuleSelect(e)}
               options={options()}
               styles={{
                  control: (provided, state) => ({
                     ...provided,
                     minHeight: '62.58px',
                     height: '62.58px',
                     width: "100%",
                     boxShadow: state.isFocused ? null : null,
                     border: (props.applyValidation && (props.module?.mode == "" || props.module?.mode == undefined)) ? "1px solid #BE2222" : ""
                  }),
                  valueContainer: (provided, state) => ({
                     ...provided,
                     height: '32px',
                     padding: '0 6px'
                  }),
                  input: (provided, state) => ({
                     ...provided,
                     margin: '0px',
                  }),
                  indicatorSeparator: state => ({
                     display: 'none',
                  }),
                  indicatorsContainer: (provided, state) => ({
                     ...provided,
                     height: '62.58px',
                  }),
               }}
               isSearchable={false}
               value={
                  options().filter(option =>
                     option.value === props.module.mode)
               }
            />
            {props.applyValidation && (props.module?.mode == "" || props.module?.mode == undefined) && <div className="error-message" style={{ marginLeft: "15px" }}>Device type is required</div>}
         </div>

      </>
   )
}