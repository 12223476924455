import React, { useContext, useEffect, useState } from "react";
import './login.css';
import PasswordInput from "../Inputs/PasswordInput";
import CustomButton from "../Inputs/Button";
import Checkbox from "../Inputs/Checkbox";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import UserContext from "../../UserContext";
import { API_ENDPOINT } from "../literals";
import { courGet, courPost } from "../../service/courAPI";
import { Constant } from "../Constant/constant";
import arrow from '../../images/Arrow.svg'
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";

const Login = ({ setUser }) => {
    const userContext = useContext(UserContext);
    const navigate = useNavigate();
    const [username, setUsername] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [showForgotPasswordModel, setForgotPasswordModel] = React.useState(false);
    const [email, setEmail] = React.useState("");
    const [emailValidation, setEmailValidation] = React.useState("");
    const [invalidUserEmail, setInvalidUserEmail] = React.useState(false);
    const [emailError, setEmailError] = React.useState(false);
    const [rememberMe, setRememberMe] = React.useState(false);

    useEffect(() => {
        let userData = JSON.parse(localStorage.getItem('user'));
        if (userData && userData.rememberMe) {
            if (userData.role === Constant.ROLE.admin) {
                navigate("/clients");
            } else {
                navigate("/dashboard");
            }
        } else {
            localStorage.clear();
            userContext.setThemeColor({ primary: "#415464", secondary: "#415464", btnHover: '#768390' });
        }
    }, []);

    const [onSubmit, setOnSubmit] = useState(false);
    const handleSubmit = (e) => {
        e.preventDefault();
        setOnSubmit(true);
        setInvalidUserEmail(false);
        let userCheck = false;

        if (username === "") {
            userCheck = true;
        }
        else {
            userCheck = false;
        }

        if (
            !new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(username)
        ) {
            userCheck = true;
            setInvalidUserEmail(true);
        }
        else {
            userCheck = false;
            setInvalidUserEmail(false);
        }

        if (userCheck || password === "") {
            return;
        }
        setOnSubmit(false);
        courPost(API_ENDPOINT.Login, { email: username, password })
            .then((res) => {
                var user = res.data;
                user.rememberMe = rememberMe;
                localStorage.setItem("user", JSON.stringify(user));
                setUser(user);

                if ((user.role === Constant.ROLE.partner || user.role === Constant.ROLE.organization) && user.primary !== "" && user.secondary !== "") {
                    userContext.setThemeColor({ primary: user.primary, secondary: user.secondary, btnHover: user.secondary });
                }
                userContext.checkAuth();
                if (user.role === Constant.ROLE.admin) {
                    navigate("/clients");
                } else {
                    if (user.organization) {
                        localStorage.setItem("selectedClient", JSON.stringify(user.organization));
                        courGet(API_ENDPOINT.GetOrganizationDetails(user.organization))
                            .then((response) => {
                                userContext.setSelectedClientName(response.data.name);
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                        userContext.setSelectedClient(user.organization);

                    }
                    navigate("/dashboard");
                }
            })
            .catch((err) => {
                setEmailValidation("error");
            });
    };

    const sendInstruction = (e) => {
        e.preventDefault();
        let emailCheck = false;

        if (email === "") {
            setEmailError(true);
            emailCheck = true;
        }
        else {
            setEmailError(false);
            emailCheck = false;
        }

        if (
            !new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(email)
        ) {
            setEmailError(true);
            emailCheck = true;
        }
        else {
            setEmailError(false);
            emailCheck = false;
        }

        if (emailCheck) {
            return;
        }
        courPost(API_ENDPOINT.ForgotPassword, { email: email })
            .then((res) => {
                if (res.data.message) {
                    userContext.createToast({ title: "User does not exists", message: "User does not exists.", variant: "danger" });
                } else {
                    userContext.createToast({ title: "Email sent successfully", message: "Email sent successfully.", variant: "Success" });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    useEffect(() => {
    }, [showForgotPasswordModel])

    return (<>

        <div className="login-wrapper row">
            <div className="left-side col-6 justify-content-between" >
                <div />
                <div className="c-wrapp">
                    <div className="welcome" >Welcome to</div>
                    <h4 className="brand-name">SweetRush<br /> XR Platform</h4>
                </div>

                <div className="brand-logo" />

            </div>
            <div className="right-side col-6 ">
                <div />
                <div className="c-wrapp">
                    <h1 className="login-text">Log In</h1>
                    <form onSubmit={(e) => handleSubmit(e)}>
                        <input
                            label="Email"
                            type="text"
                            placeholder="Email"
                            className={`${(onSubmit && username === "") || (onSubmit && username !== "" && invalidUserEmail) ? 'error form-control mt-3' : 'form-control mt-3'}`}
                            value={username}
                            onChange={(e) => { setUsername(e.target.value); setEmailValidation(""); setOnSubmit(false); }}
                        />
                        {onSubmit && username === "" && <div className="error-message" style={{ "marginBottom": "10px" }}>Please fill out this field.</div>}
                        {onSubmit && username !== "" && invalidUserEmail && <div className="error-message" style={{ "marginBottom": "10px" }}>Email not recognized. Please try again.</div>}

                        <PasswordInput
                            label="Password"
                            value={password}
                            onChange={(e) => { setPassword(e.target.value); setEmailValidation("") }}
                            className={`${onSubmit && password === "" ? 'error form-control mt-3' : 'form-control mt-3'}`}
                        />
                        {onSubmit && password === "" && <div className="error-message" style={{ "marginBottom": "10px" }}>Please fill out this field.</div>}
                        {emailValidation === "error" && <div className="error-mail">Incorrect username or password</div>}
                        <div className="remeber-password-wrapper">
                            <Checkbox
                                type="checkbox"
                                label="Remember me"
                                value={rememberMe}
                                onChange={e => { setRememberMe(e.target.checked); }}
                            />

                            <a className="forgot-password" onClick={() => { setForgotPasswordModel(true); setEmail(""); setEmailValidation("") }}>Forgot password?</a>
                        </div>

                        <CustomButton type="submit" className="btn btn-primary btn-block w-100">Login</CustomButton>
                    </form>
                </div>
                {/* <a href="#" className="dont-have-account">Don’t have an account?</a> */}
            </div>
        </div>

        <Modal
            show={showForgotPasswordModel}
            fullscreen={true}
            animation={false}
        >
            <Modal.Body className="invite-owner">

                <h1 className="title header-color">Forgot Your Password?</h1>
                <form onSubmit={(e) => sendInstruction(e)}>
                    <div className="upload-subheading subText-color mb-5">Enter your registered email and we will send you the steps to reset your password.</div>
                    <input
                        type="text"
                        placeholder="Email"
                        className={`${emailError === true ? 'error form-control mt-3' : 'form-control mt-3'}`}
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                        label="Email"
                    />
                    {emailError && email === "" && <div className="error-message" style={{ "marginBottom": "10px" }}>Please fill out this field.</div>}
                    {email !== "" && emailError && <div className="error-message" style={{ "marginBottom": "10px" }}>Please enter valid email.</div>}


                    <div className="button-wrapper">
                        <CustomButton variant="link-secondary" className="w-auto text-normal back-btn" onClick={() => setForgotPasswordModel(false)}>
                            <HiOutlineArrowNarrowLeft className="back-arrow me-2" /><span className="cancelbtn">Cancel</span>
                        </CustomButton>
                        <CustomButton type="submit" className="w-auto px-5" >Send Reset Link</CustomButton>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    </>
    );
};

export default Login;

