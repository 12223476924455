import React, { useState, useEffect, useContext } from "react";
import "./LtiCourseConfigurations.css";
import UserContext from "../../UserContext";
import { courGet, courPut } from "../../service/courAPI";
import { API_ENDPOINT } from "../literals";
import { Constant } from "../Constant/constant";
import InputBox from "../Inputs/InputBox";
import CustomButton from "../Inputs/Button";

const LtiCourseConfigurations = (props) => {
    const userContext = useContext(UserContext);
    const [courseDetails, setCourseDetails] = useState([]);
    const [platformDetails, setPlatformDetails] = useState([]);
    const [organizationDetails, setOrganizationDetails] = useState([]);
    useEffect(() => {
        if (userContext.role === Constant.ROLE.admin) {
            userContext.setUserMenu([
                {
                    label: 'Course Analytics',
                    to: 'analytics/' + userContext.selectedCourse,
                },
                {
                    label: 'Course Details',
                    to: '/course/' + userContext.selectedCourse,
                },
                {
                    label: 'LTI Configuration',
                    to: '/course/lti/' + userContext.selectedCourse,

                },
                {
                    label: 'Export',
                    to: '/export/' + userContext.selectedCourse,
                }
            ]);
            userContext.setBackUrl([
                {
                    label: "All Organizations",
                    url: '/clients'
                },
                {
                    label: userContext.selectedClientName,
                    url: '/client/' + userContext.selectedClient + '/courses'
                },
                {
                    label: 'Course',
                    url: '/analytics/' + userContext.selectedCourse
                }
            ]);
        }


        if (userContext.selectedClient) {
            getCourseById();
            getPlatformByCourseId();
            getOrgDetails();
        }

    }, [userContext.selectedClient]);

    const handleSubmit = (e) => {
        e.preventDefault();
        let data = {
            clientId: platformDetails.clientId,
            authEndpoint: platformDetails.authEndpoint,
            accesstokenEndpoint: platformDetails.accesstokenEndpoint,
            organizationId: userContext.selectedClient,
        }

        if (organizationDetails?.platformName && organizationDetails?.platformUrl && organizationDetails?.platformKey)
            data = {
                ...data,
                platformName: organizationDetails?.platformName,
                platformUrl: organizationDetails?.platformUrl,
                authConfig: {
                    method: "JWK_SET",
                    key: organizationDetails?.platformKey
                }

            }


        courPut(API_ENDPOINT.UpdatePlatformByCourseId(userContext.selectedCourse), data)
            .then((res) => {
                getPlatformByCourseId();
                userContext.createToast({ title: "Course successfully updated", message: "Course successfully updated.", variant: "Success" });
            })
            .catch((err) => {
                console.log(err);
            });
    }


    const getCourseById = () => {
        courGet(API_ENDPOINT.GetCourseById(userContext.selectedCourse))
            .then((res) => {
                getPresignedURL(res.data.thumbnail, res.data.name);
                setCourseDetails(res.data);
            }).catch((error) => {
                // console.log(error);
            })
    }

    const getPresignedURL = (fileName, courseName) => {
        courGet(API_ENDPOINT.getPreSignedGetUrl(fileName))
            .then((res) => {
                userContext.setThumbnail({ path: res.data, name: courseName })
            }).catch((error) => {
                console.log(error);
            })
    }

    const getPlatformByCourseId = () => {
        courGet(API_ENDPOINT.GetPlatformByCourseId(userContext.selectedCourse))
            .then((res) => {
                setPlatformDetails(res.data);
            }).catch((error) => {
                // console.log(error);
            })
    }

    const getOrgDetails = () => {
        userContext.selectedClient &&
            courGet(API_ENDPOINT.GetOrganizationDetails(userContext.selectedClient))
                .then((res) => {
                    setOrganizationDetails(res.data);

                })
                .catch((err) => {
                    console.log(err);
                });
    }
    return <>
        <div className="dashboard">
            <div className="d-flex justify-content-between">
                <h1 className='title'>{courseDetails.name}</h1>

            </div>
            <div className="member-list course-description mb-5">
                Update your LTI details here.
            </div>
            <form onSubmit={e => handleSubmit(e)}>
                <div className="lti-container">

                    <div className="profile-container">
                        <div className="org-profile-header org-profile-header-width">
                            Client Id
                        </div>
                        <InputBox
                            value={platformDetails.clientId}
                            onChange={(e) => setPlatformDetails({ ...platformDetails, clientId: e.target.value })}
                            maxlength="250"
                            className="lti-input-box"
                            required />
                    </div>
                    <div className="profile-container">
                        <div className="org-profile-header org-profile-header-width">
                            Platform OIDC Authentication URL
                        </div>
                        <InputBox
                            value={platformDetails.authEndpoint}
                            onChange={(e) => setPlatformDetails({ ...platformDetails, authEndpoint: e.target.value })}
                            maxlength="250"
                            className="lti-input-box"
                            required />
                    </div>
                    <div className="profile-container">
                        <div className="org-profile-header org-profile-header-width">
                            Platform OAuth2 Bearer Token Retrieval URL
                        </div>
                        <InputBox
                            value={platformDetails.accesstokenEndpoint}
                            onChange={(e) => setPlatformDetails({ ...platformDetails, accesstokenEndpoint: e.target.value })}
                            maxlength="250"
                            className="lti-input-box"
                            required />
                    </div>
                    <div className="button-wrapper">
                        <CustomButton
                            type="submit"
                            className={'w-auto px-5'}
                        >Update</CustomButton>
                    </div>
                </div>
            </form>
        </div>
    </>
}

export default LtiCourseConfigurations
