
import React from "react";
import { Outlet } from "react-router-dom";

const LaunchLayout = (props) => {
    return (<>

        <div className={"content-wrapper vh-100"}>
            <Outlet />
        </div>
    </>);
}

export default LaunchLayout;