import { useContext, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import deleteIcon from '../../images/deleteIcon.svg';
import { courDelete, courGet, courPut } from "../../service/courAPI";
import UserContext from '../../UserContext';
import CustomButton from '../Inputs/Button';
import { API_ENDPOINT } from '../literals';
import avatar from '../../images/icon_avatar.png';
import { IoIosWarning } from 'react-icons/io';
import { HiOutlineArrowNarrowLeft } from 'react-icons/hi';

const DeleteUser = ({ user, refreshMembers, courseList }) => {
    const [showDelete, setShowDelete] = useState(false);
    const userContext = useContext(UserContext);
    const [profileImage, setProfileImage] = useState("");

    const handleDelete = () => {
        courDelete(API_ENDPOINT.DeleteUser(user._id))
            .then((res) => {
                setShowDelete(false);
                refreshMembers();
                userContext.createToast({ title: "User deleted", message: "User deleted", variant: "success" });
            })
            .catch((err) => {
                console.log(err);
            })
    }

    useEffect(() => {
        if (user.picture && user.picture !== "") {
            if (user.picture) {
                courGet(API_ENDPOINT.getPreSignedGetUrl(user.picture))
                    .then((res) => {
                        setProfileImage(res.data);
                    }).catch((error) => {
                        console.log(error);
                    })
            }
        } else {
            setProfileImage(avatar);
        }
    }, []);

    return <>
        <i class="fa fa-minus-circle remove-user-icon" aria-hidden="true" onClick={() => { setShowDelete(true) }} ></i>
        <Modal
            show={showDelete}
            fullscreen={true}
            animation={false}
            centered
            size="lg"
        >
            <Modal.Body className="invite-owner">
                <form >
                    <div className="delete-modal-picture mb-5">
                        <div className="delete-logo-container">
                            <img className='thumbnail-img' src={profileImage} />
                            <div className=" w-fit-content">
                                <div className='user-edit-profile-name mb-3'>{user.firstName + " " + user.lastName}</div>

                                <div className='user-edit-profile-email'>{user.email}</div></div>
                        </div>
                    </div>
                    {user.role == "orgOwner" && <>
                        <div className="delete-heading subText-color mb-4 text-start d-flex"> <img src={deleteIcon} className="me-2" />Delete Organization Admin</div>
                        <div className="delete-modal-header-2 subText-color mb-4">Are you sure you want to delete this organization admin?</div>
                        <div className="version-item warning-bg warning-delete">
                            <div>
                                <div className="warning-div">
                                    <IoIosWarning className="warning-icon" />
                                    <span className="delete-subText-warning">Warning</span>
                                </div>
                                <span className="warningText"><b>This action cannot be undone.</b> To restore access for this organization admin, you will need to send them a new invitation to the platform.</span>
                            </div>
                        </div>

                    </>}
                    {user.role == "superAdmin" && <>
                        <div className="delete-heading subText-color mb-4 text-start d-flex"> <img src={deleteIcon} className="me-2" />Delete Platform Admin</div>
                        <div className="delete-modal-header-2 subText-color mb-4">Are you sure you want to delete this platform admin?</div>
                        <div className="version-item warning-bg warning-delete">
                            <div>
                                <div className="warning-div">
                                    <IoIosWarning className="warning-icon" />
                                    <span className="delete-subText-warning">Warning</span>
                                </div>
                                <span className="warningText">A new invitation will be required for this platform admin to regain access to the platform.</span>
                            </div>
                        </div>

                    </>}
                    {user.role == "partner" && <>
                        <div className="delete-heading subText-color mb-4 text-start d-flex"> <img src={deleteIcon} className="me-2" />Delete Partner</div>
                        <div className="delete-modal-header-2 subText-color mb-4">Are you sure you want to delete this partner?</div>
                        <div className="version-item warning-bg warning-delete">
                            <div>
                                <div className="warning-div">
                                    <IoIosWarning className="warning-icon" />
                                    <span className="delete-subText-warning">Warning</span>
                                </div>
                                <span className="warningText">This partner will no longer be able to access the following courses:  {courseList && courseList.length > 0 && courseList.map((course) => {
                                    return <div className='delete-modal-course-list subText-color'>{course.name}</div>
                                })
                                }<b>This action cannot be undone.</b> To restore access for this partner, you will need to send them a new invitation to the platform.</span>

                            </div>
                        </div>

                    </>
                    }
                    <div className="button-wrapper mt-5">
                        <CustomButton variant="link-secondary" className="w-auto text-normal back-btn" onClick={() => { setShowDelete(false); }}>
                            <HiOutlineArrowNarrowLeft className="back-arrow me-2" /><span className="cancelbtn">Cancel</span>
                        </CustomButton>
                        <CustomButton className="w-auto px-5" onClick={handleDelete}>Yes, Delete</CustomButton>
                    </div>
                </form>
            </Modal.Body>
        </Modal></>
}


export default DeleteUser;